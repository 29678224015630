import compareRows from './compareRows';
import { Row } from './types';

const hidden: Partial<Record<Row['type'], Set<string>>> = {
  groupHeader: new Set([
    '1000_1999',
    '2000_2099',
    '2100_2999',
    '7000_7999',
    '8800_8999',
  ]),
  groupSum: new Set(['7000_7999', '8800_8999', '8900_8999']),
};

export const isNotHidden = (row: Row): boolean => {
  return !hidden[row.type]?.has(row.id);
};

/**
 * Temporary function, during development we want to notice
 * duplicate ids better.
 *
 * TODO: Remove this before we go live in production.
 */
const validate = (rows: Row[]) => {
  const getIds = (subRows: Row[]): string[] => {
    return subRows.flatMap((row) => {
      if (row.type === 'group') {
        return [row.id].concat(getIds(row.rows));
      }
      return [];
    });
  };

  const ids = getIds(rows);
  const valid = ids.length === new Set(ids).size;
  if (!valid) {
    const unique = new Set(ids);

    console.log(
      ids.reduce((dup: string[], id) => {
        if (unique.has(id)) {
          return [id, ...dup];
        }
        unique.add(id);
        return dup;
      }, [])
    );
  }
  return valid;
};

const removeHiddenRows = (rows: Row[]) => {
  const result: Row[] = [];

  rows.forEach((row) => {
    if (isNotHidden(row)) {
      if (row.type === 'group') {
        result.push({
          ...row,
          rows: removeHiddenRows(row.rows),
        });
      } else {
        result.push(row);
      }
    }
  });

  return result;
};

/**
 * Merges lists of rows using compareRows to keep the sorting.
 *
 * In the case that two rows have the same ordering, the first row will be kept and
 * the other removed.
 *
 * @param all a set of rows by id, the rows must be ordered using `compareRows`.
 * @returns A merged list of rows.
 */
const mergeRows = (all: Record<string, Row[]>): Row[] => {
  const mergeRowsInternal = (a: Row[], b: Row[]) => {
    const merged: Row[] = [];
    let aIndex = 0;
    let bIndex = 0;
    const aSorted = Array.from(a).sort(compareRows);
    const bSorted = Array.from(b).sort(compareRows);

    while (aIndex < aSorted.length && bIndex < bSorted.length) {
      const aRow = aSorted[aIndex];
      const bRow = bSorted[bIndex];

      const order = compareRows(aRow, bRow);

      if (order <= 0) {
        if (order === 0) {
          if (isNotHidden(aRow)) {
            if (aRow.type === 'group' && bRow.type === 'group') {
              merged.push({
                type: 'group',
                className: aRow.className,
                id: aRow.id,
                rows: mergeRowsInternal(aRow.rows, bRow.rows),
              });
            } else {
              merged.push(aRow);
            }
          }
          aIndex += 1;
          bIndex += 1;
        } else {
          if (isNotHidden(aRow)) {
            merged.push(aRow);
          }
          aIndex += 1;
        }
      } else {
        if (isNotHidden(bRow)) {
          merged.push(bRow);
        }
        bIndex += 1;
      }
    }
    const result = merged
      .concat(removeHiddenRows(aSorted.slice(aIndex)))
      .concat(removeHiddenRows(bSorted.slice(bIndex)));

    if (!validate(result)) {
      console.log(JSON.stringify({ a, b, result }, null, 2));
      throw new Error('NewReconciliation, duplicates found');
    }

    return result;
  };

  const merged = Object.values(all).reduce(mergeRowsInternal);

  return merged;
};

export default mergeRows;

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useApiSdk, asResultClass } from 'api-sdk';
import { addGlobalErrorMessage } from 'redux/actions';
import config from '_shared/services/config';
import LoadingPlaceholder from '_shared/components/LoadingPlaceholder';
import CheckoutPage from '_payment/components/pages/Checkout';
import { usePaymentStatus } from '_payment/payment-status/usePaymentStatus';
import {
  SubscriptionPackages,
  SubscriptionPackagesQuantity,
} from '_payment/types';
import { useIntl } from 'react-intl';
import useHistory from '_shared/hooks/useHistory';

/**
 * Paid users and Fnox users are redirected away from this route
 */
const Container = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const sdk = useApiSdk();
  const { data } = usePaymentStatus();
  const { formatMessage } = useIntl();

  const [subscriptions, setSubscriptions] =
    useState<SubscriptionPackages | null>(null);

  React.useEffect(() => {
    const fetchSubscriptionPackages = async () => {
      const result = await asResultClass(sdk.listOrganisationPackages());

      if (result.ok) {
        setSubscriptions(result.val);
      } else {
        dispatch(addGlobalErrorMessage('error'));
        history.push('/');
      }
    };

    fetchSubscriptionPackages();
  }, []);

  const handleCheckout = async (
    selectedSubscriptions: SubscriptionPackagesQuantity
  ) => {
    const packages: {
      packageId: number;
      quantity: number;
    }[] = [];

    if (selectedSubscriptions.small.quantity > 0) {
      packages.push({
        packageId: selectedSubscriptions.small.id,
        quantity: selectedSubscriptions.small.quantity,
      });
    }

    if (selectedSubscriptions.big.quantity > 0) {
      packages.push({
        packageId: selectedSubscriptions.big.id,
        quantity: selectedSubscriptions.big.quantity,
      });
    }

    const result = await asResultClass(
      sdk.createCheckoutSession({
        requestBody: {
          successUrl: `${window.location.origin}/payment/success`,
          cancelUrl: `${window.location.origin}/payment/cancel`,
          packages,
        },
      })
    );

    if (result.ok) {
      window.location.href = result.val.url;
    } else {
      dispatch(addGlobalErrorMessage('error'));
    }
  };

  if (data.hasAccess || config.whiteLabel === 'fortnox') {
    history.push('/');
  }

  if (subscriptions === null) {
    return <LoadingPlaceholder />;
  }

  if (!subscriptions.big || !subscriptions.small) {
    return (
      <>
        {formatMessage({
          id: 'dashboard.members.invite.error.title',
        })}
      </>
    );
  }

  return (
    <CheckoutPage
      subscriptions={{
        big: subscriptions.big,
        small: subscriptions.small,
      }}
      handleCheckout={handleCheckout}
    />
  );
};

export default Container;

import React from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import { useRouteMatch } from 'react-router-dom';

import { useSelector } from 'redux/reducers';
import CustomerButton from '_shared/components/AppHeader/parts/CustomerSubMenu/CustomerButton';
import Button from '_shared/components/Buttons/Button';
import { ClientsRouteParams } from 'routes/types';
import { formatFinancialYear } from '@agoy/common';
import useHistory from '_shared/hooks/useHistory';

const Container = styled.div`
  height: 64px;
  background: #eeeeee;
  display: grid;
  align-items: center;
  grid-template-columns: 370px 1fr 250px;
  grid-column-gap: 80px;
  padding-left: 30px;
`;

const FinancialYearText = styled(Typography)`
  font-weight: 500;
  font-size: 1.25rem;
`;

type TopHeaderProps = {
  clientId: string;
  onClickScroll: () => void;
};

const TopHeader = ({ clientId, onClickScroll }: TopHeaderProps) => {
  const route = useRouteMatch<ClientsRouteParams>();
  const { formatMessage } = useIntl();
  const history = useHistory();

  const { financialYear } = route.params;

  const { name, logo } = useSelector(
    (state) => state.customers[clientId] || {}
  );

  const handleClickClient = () => {
    history.push(`/clients/${clientId}`);
  };

  return (
    <Container>
      <CustomerButton
        logoUrl={logo}
        name={name}
        handleClick={handleClickClient}
      />
      <Button
        label={formatMessage({
          id: 'reconciliation.topHeader.scroll',
        }).toUpperCase()}
        variant="outlined"
        size="medium"
        onClick={onClickScroll}
      />
      <FinancialYearText color="textPrimary">
        {financialYear && formatFinancialYear(financialYear)}
      </FinancialYearText>
    </Container>
  );
};

export default TopHeader;

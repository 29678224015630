import React, { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState, useSelector } from 'redux/reducers';
import {
  Observable,
  ReplaySubject,
  map,
  filter,
  distinctUntilChanged,
  Subscription,
} from 'rxjs';
import { isDefined, isUndefined } from 'utils/filter';

export interface DataSource<T> {
  get(clientId: string, financialYear: string): Observable<T>;
  set(clientId: string, financialYear: string, value: T): void;
}

export abstract class ReduxDataSource<T, U> implements DataSource<T> {
  state: Observable<U>;

  name: string;

  missingDataSubscriptions: Map<string, Subscription> = new Map();

  constructor(state: Observable<U>, name: string) {
    this.state = state;
    this.name = name;
  }

  close(): void {
    [...this.missingDataSubscriptions.values()].forEach((sub) => {
      sub.unsubscribe();
    });
  }

  abstract mapStateToData(
    clientId: string,
    period: string
  ): (state: U) => T | undefined;

  abstract onMissingData(clientId: string, period: string): void;

  get(clientId: string, period: string): Observable<T> {
    const rawObs = this.state.pipe(
      map(this.mapStateToData(clientId, period)),
      distinctUntilChanged((a, b) => isEqual(a, b))
    );

    // we should only create one subscription per client and period
    if (!this.missingDataSubscriptions.has(`${clientId}#${period}`)) {
      const missingDataSubscription = rawObs
        .pipe(distinctUntilChanged(), filter(isUndefined))
        .subscribe(() => this.onMissingData(clientId, period));
      this.missingDataSubscriptions.set(
        `${clientId}#${period}`,
        missingDataSubscription
      );
    }

    const obs = rawObs.pipe(filter(isDefined), distinctUntilChanged());

    return obs;
  }

  abstract set(clientId: string, period: string, value: T): void;
}

type ProviderProps = {
  children: JSX.Element;
};

export function createProvider<T, U>(
  context: React.Context<DataSource<T>>,
  selectState: (state: RootState) => U,
  createSource: (
    subject: Observable<U>,
    dispatch: Dispatch<any>,
    readOnly: boolean
  ) => ReduxDataSource<T, U>
): (props: ProviderProps) => JSX.Element {
  return ({ children }: ProviderProps) => {
    const dispatch = useDispatch();
    const [subject] = useState(() => new ReplaySubject<U>(1));
    const supportUser = useSelector((state) => state.user.supportUser);
    const [source] = useState(() =>
      createSource(subject, dispatch, supportUser)
    );
    const state = useSelector(selectState);

    useEffect(() => {
      subject.next(state);
    }, [state, subject]);

    useEffect(() => {
      return () => {
        subject.complete();
      };
    }, [subject]);

    useEffect(() => {
      return () => {
        source.close();
      };
    }, [source]);

    return <context.Provider value={source}>{children}</context.Provider>;
  };
}

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'redux/reducers';
import { ClientsRouteParams } from 'routes/types';
import { isFinancialYear } from '@agoy/common';
import { setNextYear } from '_clients/redux/customer-view/actions';
import LoadingPlaceholder from '_shared/components/LoadingPlaceholder';
import useHistory from '_shared/hooks/useHistory';

interface ChildrenOnly {
  children: JSX.Element;
  error: string | null;
}

/**
 * LoadClient
 *
 * The LoadClient component is responsible for watching the URL
 * and when needed update the redux state with the next client to
 * load.
 *
 */
const LoadClient = ({ children, error }: ChildrenOnly): JSX.Element | null => {
  const {
    params: { clientId, financialYear },
  } = useRouteMatch<ClientsRouteParams>();
  const {
    currentCustomer: currentClientId,
    currentFinancialYear,
    next,
  } = useSelector((state) => state.customerView);
  const dispatch = useDispatch();
  const history = useHistory();

  // Update next-state when url is changed
  useEffect(() => {
    if (
      clientId !== currentClientId ||
      financialYear !== currentFinancialYear
    ) {
      if (isFinancialYear(financialYear)) {
        dispatch(setNextYear(clientId, financialYear));
      } else {
        history.push(`/clients/${clientId}`);
      }
    }
  }, [
    history,
    clientId,
    currentClientId,
    financialYear,
    currentFinancialYear,
    dispatch,
  ]);

  if (error) {
    return null;
  }

  if (!currentClientId || (!currentFinancialYear && next.year)) {
    // Loading first customer/year, nothing to overlay
    return <LoadingPlaceholder />;
  }

  if (currentClientId !== clientId || next.year) {
    // Changing customer or year, use an overlay with loader
    return (
      <>
        {children}
        <LoadingPlaceholder overlay />
      </>
    );
  }

  // All loaded and fine
  return children;
};

export default LoadClient;

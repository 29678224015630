import React, { forwardRef } from 'react';
import { Link as ReactLink, LinkProps } from 'react-router-dom';
import { resolvePath } from '../../utils/routes';
import useFid from '../hooks/useFid';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Link = forwardRef(({ to, ...rest }: LinkProps, ref) => {
  const fid = useFid();
  return (
    <ReactLink to={fid ? resolvePath(fid, to.toString()) : to} {...rest} />
  );
});

export default Link;

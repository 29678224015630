import React, { useCallback, useState } from 'react';
import { FormHelperText, Typography } from '@material-ui/core';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { addGlobalErrorMessage, addGlobalMessage } from 'redux/actions';
import { ClientCompanyType } from '_clients/types/types';
import {
  SimplifiedDatePicker,
  SimplifiedTextfield,
} from '_shared/components/Inputs';
import CurrencyField from '_shared/components/Inputs/CurrencyField';
import GenericSimplefied from '_shared/components/Inputs/GenericSimplefied';
import { createConnection } from '_client-connections/redux/persons/actions';
import { getClient } from '_clients/redux/customers/actions';
import { getPerson } from '_person/redux/persons/actions';
import { Alert } from '@material-ui/lab';
import { isAfter, isBefore } from 'date-fns';
import useSharesInCompany from '_client-connections/hooks/useSharesInCompany';
import { parse } from '@agoy/dates';
import Button from '_shared/components/Buttons/Button';
import useHistory from '_shared/hooks/useHistory';
import { validateShares } from './ShareTransactionsTable';
import ConnectionsTabWrapper, {
  ConnectionTabProps,
} from './ConnectionsTabWrapper';

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: ${({ theme }) => theme.spacing(4)}px;
  gap: ${({ theme }) => theme.spacing(1)}px;
`;

const Info = styled(Alert)`
  width: 450px;
`;

const InputWrapper = styled.div`
  flex-direction: column;
  flex: 0 0 270px;

  &.has-error fieldset {
    border: 1px solid red;
  }
`;

export const SharesNumberField = React.forwardRef(
  (
    {
      label,
      value,
      setValue,
      decimals = 0,
      disabled = false,
      required,
      error,
      helperText,
    }: {
      label: string;
      value: number;
      setValue: (value: number) => void;
      decimals?: number;
      disabled?: boolean;
      required?: boolean;
      error?: boolean;
      helperText?: string;
    },
    ref
  ) => {
    return (
      <GenericSimplefied width={450} label={label} editing required={required}>
        <InputWrapper className={error ? 'has-error' : ''}>
          <CurrencyField
            InputProps={{
              variant: 'outlined',
              size: 'small',
              fullWidth: true,
              placeholder: label,
              disabled,
              required,
            }}
            ref={ref}
            displayDecimals={decimals}
            editingDecimals={decimals}
            value={value}
            onValueChange={(value) => {
              setValue(value || 0);
            }}
          />

          <FormHelperText error={error}>{helperText}</FormHelperText>
        </InputWrapper>
      </GenericSimplefied>
    );
  }
);

type Props = ConnectionTabProps & {
  companyToConnect: ClientCompanyType;
};

const ConnectCompanyToClient = ({
  client,
  path,
  companyToConnect,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const text = (id: string, values?: Record<string, string>) =>
    intl.formatMessage(
      {
        id: `connections.addConnection.specifyShares.${id}`,
      },
      values
    );

  const [isSaving, setIsSaving] = useState(false);

  const [shareChange, setShareChange] = useState(0);
  const [valuePerShare, setValuePerShare] = useState(0);
  const [transactionDate, setTransactionDate] = useState('');

  const clientId = client.id || '';
  const { id, stockTotals = [] } = companyToConnect;
  const sharesInCurrentCompany = useSharesInCompany(id, clientId);

  const shareTotal = transactionDate
    ? stockTotals.find(
        (total) =>
          transactionDate === total.date ||
          isAfter(parse(transactionDate), parse(total.date))
      )?.shareTotal || 0
    : stockTotals[0].shareTotal;

  const handleOnSubmit = async () => {
    try {
      setIsSaving(true);
      const stockTransaction =
        shareChange || transactionDate
          ? { shareChange, valuePerShare, transactionDate }
          : undefined;

      await dispatch(
        createConnection(clientId, companyToConnect.id, stockTransaction)
      );
      await (path === 'clients'
        ? dispatch(getClient(clientId))
        : dispatch(getPerson(clientId)));

      const message = intl.formatMessage({
        id: 'connections.addConnection.success',
      });
      dispatch(addGlobalMessage('success', message));

      history.push(`/${path}/${clientId}/connections`);
    } catch (error) {
      dispatch(addGlobalErrorMessage('error'));
    } finally {
      setIsSaving(false);
    }
  };

  const validateDate = useCallback(() => {
    if (!!shareChange && !transactionDate) {
      return intl.formatMessage({
        id: 'connections.validation.addConnection.dateShouldNotBeEmpty',
      });
    }

    if (
      !!transactionDate &&
      !!stockTotals[0] &&
      isBefore(
        new Date(transactionDate),
        new Date(stockTotals[stockTotals.length - 1].date)
      )
    ) {
      return intl.formatMessage({
        id: 'connections.validation.addConnection.dateShouldBeAfterInitial',
      });
    }

    return '';
  }, [shareChange, transactionDate, stockTotals, intl]);

  const dateValidationMessage = validateDate();
  const sharesValidationMessage = validateShares(
    intl.formatMessage,
    shareChange,
    [{ transactionDate, shareChange, valuePerShare }],
    stockTotals,
    sharesInCurrentCompany
  );

  return (
    <ConnectionsTabWrapper
      client={client}
      title={text('title')}
      description={
        <>
          <Typography>{text('description.1')}</Typography>
          <ul style={{ marginRight: 'auto' }}>
            <li>
              <Typography>{text('description.2')}</Typography>
            </li>
            <li>
              <Typography>
                {text('description.3', {
                  name: client.name,
                })}
              </Typography>
            </li>
          </ul>
          <Typography>{text('note')}</Typography>
        </>
      }
    >
      <SimplifiedTextfield
        value={companyToConnect.orgNumber}
        label={intl.formatMessage({ id: 'client.orgNumber' })}
        labelPlacement="left"
        editing
        disabled
        width={450}
      />
      <SimplifiedTextfield
        value={shareTotal}
        label={text('fields.totalShares')}
        labelPlacement="left"
        editing
        disabled
        width={450}
      />

      <Typography variant="h3">{text('ownersShares')}</Typography>
      <SharesNumberField
        label={text('fields.shares')}
        value={shareChange}
        setValue={(value) => {
          setShareChange(value);
        }}
        disabled={isSaving}
        error={!!sharesValidationMessage}
        helperText={sharesValidationMessage}
      />
      <SharesNumberField
        label={text('fields.acquisitionPerShare')}
        value={valuePerShare}
        setValue={(value) => {
          setValuePerShare(value);
        }}
        decimals={2}
        disabled={isSaving}
      />
      {!!shareChange && !valuePerShare && (
        <Info severity="info">
          {intl.formatMessage({ id: 'connections.optionalSharesWarning' })}
        </Info>
      )}
      <SimplifiedDatePicker
        required={!!shareChange}
        width={450}
        value={transactionDate}
        editing
        label={text('fields.appliesFrom')}
        placeholder={text('fields.appliesFrom')}
        onChange={(dateValue) => {
          setTransactionDate(dateValue || '');
        }}
        disabled={isSaving}
        error={!!dateValidationMessage}
        helperText={dateValidationMessage}
        shouldDisableDate={(day) =>
          !!day && isAfter(parse(stockTotals[stockTotals.length - 1].date), day)
        }
      />

      <ButtonsContainer>
        <Button
          label={intl.formatMessage({ id: 'submit' })}
          loading={isSaving}
          disabled={!!dateValidationMessage}
          onClick={handleOnSubmit}
        />

        <Button
          label={intl.formatMessage({ id: 'cancel' })}
          variant="text"
          disabled={isSaving}
          onClick={() => history.push(`/${path}/${clientId}/connections`)}
        />
      </ButtonsContainer>
    </ConnectionsTabWrapper>
  );
};

export default ConnectCompanyToClient;

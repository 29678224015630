import React, { useCallback } from 'react';
import { useSelector } from 'redux/reducers';
import CustomerButton from './CustomerSubMenu/CustomerButton';
import SubMenuToolBar from './SubMenuToolBar';
import UploadSIEButton from './UploadSIEButton';
import useHistory from '../../../hooks/useHistory';

const EmptyClientSubMenu = () => {
  const customerId = useSelector((state) => state.customerView.currentCustomer);
  const customer = useSelector((state) =>
    customerId ? state.customers[customerId] : undefined
  );
  const history = useHistory();

  const handleClick = useCallback(() => {
    history.push(`/clients/${customerId}/`);
  }, [history, customerId]);

  return (
    <SubMenuToolBar>
      <SubMenuToolBar.Grouper>
        {customer && (
          <>
            <CustomerButton
              logoUrl={customer.logo}
              name={customer.name}
              handleClick={handleClick}
            />
            <SubMenuToolBar.Divider />
            <UploadSIEButton />
          </>
        )}
      </SubMenuToolBar.Grouper>
    </SubMenuToolBar>
  );
};

export default EmptyClientSubMenu;

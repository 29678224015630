import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Card } from '@material-ui/core';
import styled from '@emotion/styled';

import { addGlobalErrorMessage } from '_messages/redux/actions';
import { useSelector } from 'redux/reducers';
import { updateChecklistTemplate } from '_organization/redux/actions';
import { ChecklistTemplate } from '_shared/redux/checklist/types';
import Typography from '_shared/components/Typography/Typography';
import { useIntl } from 'react-intl';
import useHistory from '_shared/hooks/useHistory';
import ChecklistForm from './Components/ChecklistForm/ChecklistForm';

const StyledCard = styled(Card)`
  padding: 16px 16px;
  min-width: 1000px;
  margin-bottom: ${({ theme }) => theme.spacing(9)}px;
`;

interface RouteParams {
  checklistId: string;
}

const EditChecklist = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { checklistId } = useParams<RouteParams>();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [selectedChecklist, setSelectedChecklist] =
    useState<ChecklistTemplate>();

  const checklists = useSelector((state) => state.organisation.checklists);

  useEffect(() => {
    if (checklistId) {
      const checklistById = checklists.find(
        (item) => `${item.id}` === checklistId
      );
      if (checklistById) {
        setSelectedChecklist(checklistById);
      }
    }
  }, [checklists, checklistId]);

  const handleChangeChecklist = async (checklist: ChecklistTemplate) => {
    try {
      setLoading(true);
      checklist.groups.map((group) => {
        if (group.order === undefined) {
          group.id = undefined;
        }
        group.questions.map((question) => {
          if (question.order === undefined) {
            question.id = undefined;
          }
          return question;
        });
        return group;
      });
      dispatch(updateChecklistTemplate(checklist));
      setLoading(false);
      history.push(`/organisation/checklists`);
    } catch (e) {
      setLoading(false);
      dispatch(addGlobalErrorMessage('error'));
    }
  };

  const handleCancel = async () => {
    history.push(`/organisation/checklists`);
  };

  return (
    <StyledCard>
      <Typography variant="h2">
        {formatMessage({ id: 'checklist.update.title' })}
      </Typography>
      <ChecklistForm
        checklist={selectedChecklist}
        onChangeChecklist={handleChangeChecklist}
        onCancel={handleCancel}
        loading={loading}
      />
    </StyledCard>
  );
};

export default EditChecklist;

import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Button } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/Error';
import { WithTheme } from '@material-ui/core/styles';
import styled from '@emotion/styled';

import { useSelector } from 'redux/reducers';
import LoadingLogo from '_shared/components/LoadingLogo';
import { addGlobalErrorMessage } from '_messages/redux/actions';
import { useDispatch } from 'react-redux';

interface CustomerButton {
  status: string;
}

const CustomerButton = styled(Button)`
  background-color: ${(props: CustomerButton & WithTheme) =>
    props.status === 'FAILED' ? props.theme.palette.error.main : 'transparent'};
  &:hover {
    cursor: inherit;
    background: inherit;
  }
`;

const SavingStatusButton = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const savingStatus = useSelector((state) => [
    state.accountingView.savingUserDataStatus,
  ]);

  const status = savingStatus.includes('SAVING')
    ? 'SAVING'
    : savingStatus.includes('FAILED')
      ? 'FAILED'
      : 'SAVED';

  useEffect(() => {
    if (status === 'FAILED') {
      dispatch(addGlobalErrorMessage('SavingDidNotWork'));
    }
  }, [status, dispatch]);

  const startIcon =
    status === 'SAVING' ? (
      <LoadingLogo size="small" />
    ) : status === 'FAILED' ? (
      <ErrorIcon />
    ) : (
      <DoneIcon />
    );

  const label = intl.formatMessage({ id: status.toLowerCase() });

  return (
    <CustomerButton
      disabled={status === 'SAVING'}
      status={status}
      startIcon={startIcon}
      disableFocusRipple
      disableRipple
    >
      {label}
    </CustomerButton>
  );
};

export default SavingStatusButton;

/* eslint-disable react/prop-types */
import React from 'react';
import {
  Theme,
  Toolbar as MuiToolbar,
  Divider as MuiDivider,
  Button,
  FormControlLabel,
  Switch,
  Typography,
  TextField,
  InputAdornment,
  FormControl,
} from '@material-ui/core';
import styled from '@emotion/styled';
import {
  AddCircleOutline,
  Business,
  ListAltOutlined,
  Person,
  Search,
} from '@material-ui/icons';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { useSelector } from 'redux/reducers';
import LinkButton from '_shared/components/Buttons/LinkButton';
import {
  setFilterName,
  setSortByType,
} from '_person/redux/personsOverview/actions';
import config from '_shared/services/config';
import useHistory from '_shared/hooks/useHistory';

type StyledProps = {
  theme?: Theme;
};

const Toolbar = styled(MuiToolbar)<StyledProps>`
  color: inherit;
  height: fit-content;
  box-shadow: ${(props) => props.theme?.shadows[2]};
  padding-top: ${(props) => props.theme?.spacing(1)}px;
  padding-bottom: ${(props) => props.theme?.spacing(1)}px;
  height: inherit;
  && > * {
    margin-right: ${(props) => props.theme?.spacing(1)}px;
    margin-left: ${(props) => props.theme?.spacing(1)}px;
  }
`;

const Divider = styled(MuiDivider)<StyledProps>`
  margin-left: ${({ theme }) => theme.spacing(2)}px;
  margin-right: ${({ theme }) => theme.spacing(2)}px;
`;

const RightMenuItems = styled(FormControl)`
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
`;

const PersonIcon = styled(Person)`
  color: ${({ theme }) => theme.palette.grey[200]};
`;

const SearchIcon = {
  startAdornment: (
    <InputAdornment position="start">
      <Search />
    </InputAdornment>
  ),
};

const isFortnoxMode = config.whiteLabelUI === 'fortnox';

const PersonSubMenu = (): JSX.Element => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const { sortType, search } = useSelector((state) => state.personsOverview);
  const shouldSortByClient = sortType === 'BY_CLIENT_NAME';

  const text = (id: string) => intl.formatMessage({ id });

  const handleCreate = () => {
    history.push(`/persons/create`);
  };

  const MenuButton = ({ label, onClick, isAddButton }): JSX.Element => (
    <>
      <Button
        startIcon={isAddButton ? <AddCircleOutline /> : <ListAltOutlined />}
        onClick={onClick}
      >
        {text(`sidemenu.${label}`)}
      </Button>
      <Divider orientation="vertical" flexItem />
    </>
  );

  const toggleSort = () => {
    dispatch(
      sortType === 'BY_CLIENT_NAME'
        ? setSortByType('BY_CLIENT_MANAGER')
        : setSortByType('BY_CLIENT_NAME')
    );
  };

  return (
    <Toolbar>
      {!isFortnoxMode && (
        <MenuButton isAddButton label="add_person" onClick={handleCreate} />
      )}
      <MenuButton
        isAddButton={false}
        label={shouldSortByClient ? 'order.managers' : 'order.clients'}
        onClick={toggleSort}
      />
      <RightMenuItems>
        <LinkButton
          to="/"
          description={text('customersubmenu.organisationMode')}
        >
          <Business />
        </LinkButton>
        <LinkButton
          to="/persons"
          description={text('customersubmenu.personMode')}
          isSelected
        >
          <PersonIcon />
        </LinkButton>
        <TextField
          variant="outlined"
          size="small"
          placeholder={text('dashboard.person.search.placeholder')}
          onChange={(event) => dispatch(setFilterName(event?.target.value))}
          value={search}
          InputProps={SearchIcon}
        />
      </RightMenuItems>
    </Toolbar>
  );
};

export default PersonSubMenu;

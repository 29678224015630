/**
 * Prevents from reaching the defalut case in a switch case.
 */
export class UnreachableCaseError extends Error {
  constructor(val: never) {
    super(`Unreachable case: ${val}`);
  }
}

/**
 * Get value prop from provider
 */
export type GetContextTypeFromProvider<
  T extends ({
    children,
    value,
  }: {
    children: React.ReactNode;
    value: any;
  }) => JSX.Element,
> = Parameters<T>[0]['value'];

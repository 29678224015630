import React, { useState, useRef, useCallback } from 'react';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { parse } from 'date-fns';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { PrintSpecificationsProvider } from '_reconciliation/components/ReconciliationView/PrintSpecifications/PrintSpecificationsContext';

import { parseFormat } from '@agoy/dates';
import { reformatDate, formatDate } from '@agoy/common';
import { setCurrentPeriod } from 'redux/actions';
import { useSelector } from 'redux/reducers';
import { AccountBalance } from '_reconciliation/components/ReconciliationView/types';

import { setSinglePeriod } from '_reconciliation/redux/accounting-view/actions';
import { getGroupStatus } from 'utils/accountGrouper';
import { ReconciliationPeriod } from '@agoy/reconciliation';
import useHistory from '_shared/hooks/useHistory';
import ReconciliationStatus from './ReconciliationStatus';
import PeriodStatusPopover from './PeriodStatusPopover';
import PeriodHeaderMenu from './PeriodHeaderMenu';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 85px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const PeriodStatusRow = styled(Row)<{
  disabled: boolean;
}>`
  cursor: pointer;
  ${({ disabled }) => disabled && `cursor: auto; opacity: 0.8`}
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 5px;
`;

const Header = styled.div<{
  type: string;
  notActive: boolean;
  withinFinancialYear: boolean;
}>`
  display: flex;
  flex-direction: column;
  background: ${({ theme, type, notActive, withinFinancialYear }) => {
    if (notActive && !withinFinancialYear) {
      return theme.palette.grey[600];
    }
    return theme.palette.accountingView.periodHeader[type];
  }};
  height: 100%;
  padding: ${({ theme }) => theme.spacing(1)}px;
  justify-content: space-between;
`;

const Wrapper = styled(Row)`
  justify-content: space-between;
`;

const WhiteText = styled(Typography)`
  color: white;
`;

const TopDate = styled(WhiteText)`
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  max-width: 80px;
  overflow-x: visible;
`;

const BottomDate = styled(WhiteText)`
  font-size: 13px;
  font-weight: 500;
  max-width: 80px;
  overflow-x: visible;
`;

const ColumnTitle = styled(WhiteText)`
  font-size: 12px;
  text-align: right;
`;

const IncomingColumnTitle = styled(ColumnTitle)`
  min-width: 82px;
`;

const OutgoingColumnTitle = styled(ColumnTitle)`
  width: 100%;
`;

export type PeriodHeaderProps = {
  clientId: string;
  period: ReconciliationPeriod;
  notActive?: boolean;
  balances?: AccountBalance;
};

const PeriodHeader = ({
  clientId,
  period,
  notActive = false,
  balances,
}: PeriodHeaderProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const anchorRef = useRef<HTMLDivElement>(null);

  const [isStatusPopoverOpen, setStatusPopoverOpen] = useState(false);

  const { movingAccountsMode } = useSelector((state) => state.accountingView);

  const { periodChangeVisible, periodUBVisible, showSinglePeriod } =
    useSelector((state) => {
      return {
        periodChangeVisible: state.accountingView.periodChangeVisible,
        periodUBVisible: state.accountingView.periodUBVisible,
        showSinglePeriod: state.accountingView.showSinglePeriod,
      };
    });

  const lamps = useSelector((state) => state.customers[period.clientId].lamps);

  const lastPeriod = period.periods[period.periods.length - 1];

  const periodStatus = useSelector((state) => {
    const currentClient = state.accountingView.clients[period.clientId];
    return lastPeriod
      ? currentClient?.periodStatus?.[lastPeriod.id]
      : undefined;
  });

  const groupStatus = lastPeriod
    ? getGroupStatus(
        lamps[reformatDate(lastPeriod.start, 'yyyy-MM-dd', 'yyyyMMdd')] || {}
      )
    : 'unvisited';

  const { start, end, type } = period;

  // For dead periods
  const withinFinancialYear =
    period.type === 'dead' && period.withinFinancialYear;

  const parsedPeriodStart = parse(start, 'yyyy-MM-dd', new Date());
  const parsedPeriodEnd = parse(end, 'yyyy-MM-dd', new Date());

  const showOnlyThis = showSinglePeriod === start;

  const getFinancialYear = useCallback((year) => {
    return `${reformatDate(
      year.start,
      'yyyy-MM-dd',
      'yyyyMMdd'
    )}-${reformatDate(year.end, 'yyyy-MM-dd', 'yyyyMMdd')}`;
  }, []);

  const handleRedirectTaxPeriod = () => {
    const financialYear = getFinancialYear(lastPeriod.financialYear);

    dispatch(setCurrentPeriod(parseFormat(lastPeriod.start, 'yyyyMMdd')));
    history.push(`/clients/${clientId}/${financialYear}/tax/salarytax`);
  };

  const handleClickVisibility = () => {
    const financialYear = period.financialYears[0];
    const formattedYear = getFinancialYear(financialYear);

    history.push(`/clients/${clientId}/reconciliation/${formattedYear}`);
    dispatch(
      setSinglePeriod(
        showOnlyThis ? undefined : period.start,
        showOnlyThis ? undefined : type
      )
    );
  };

  const toggleDialogOpen = () => {
    !movingAccountsMode && setStatusPopoverOpen((value) => !value);
  };

  return (
    <Container>
      {!notActive && (
        <PeriodStatusPopover
          period={period}
          periodStatus={periodStatus}
          clientId={period.clientId}
          anchorElement={anchorRef.current}
          open={isStatusPopoverOpen}
          onClose={toggleDialogOpen}
        />
      )}

      <Header
        type={type}
        notActive={notActive}
        ref={anchorRef}
        withinFinancialYear={withinFinancialYear}
      >
        <Wrapper>
          <PeriodStatusRow
            onClick={toggleDialogOpen}
            disabled={movingAccountsMode}
          >
            {!notActive && (
              <ReconciliationStatus
                status={periodStatus?.status}
                groupStatus={groupStatus}
              />
            )}

            {type === 'financialYear' && (
              <Column>
                <TopDate>{formatDate(parsedPeriodEnd, 'yyyy-MM-dd')}</TopDate>
                <BottomDate>
                  Från {formatDate(parsedPeriodStart, 'yyyy-MM-dd')}
                </BottomDate>
              </Column>
            )}

            {type === 'quarter' && (
              <Column>
                <TopDate>{formatDate(parsedPeriodStart, 'QQQ')}</TopDate>
                <BottomDate>{formatDate(parsedPeriodStart, 'yyyy')}</BottomDate>
              </Column>
            )}

            {(type === 'month' || type === 'dead') && (
              <Column>
                <TopDate>{formatDate(parsedPeriodStart, 'MMMM')}</TopDate>
                <BottomDate>{formatDate(parsedPeriodStart, 'yyyy')}</BottomDate>
              </Column>
            )}

            {type === 'yearEnd' && (
              <Column>
                <TopDate>{formatMessage({ id: 'closingperiod' })}</TopDate>
                <BottomDate>
                  {formatDate(parsedPeriodStart, 'yyyy-MM-dd')}
                  {' – '}
                  {formatDate(parsedPeriodEnd, 'yyyy-MM-dd')}
                </BottomDate>
              </Column>
            )}
          </PeriodStatusRow>
          <Row>
            <PrintSpecificationsProvider
              actualFinancialYear={
                lastPeriod?.financialYear &&
                getFinancialYear(lastPeriod?.financialYear)
              }
              balances={balances}
            >
              <PeriodHeaderMenu
                clientId={clientId}
                showOnlyThis={showOnlyThis}
                allOptionsVisible={periodChangeVisible && periodUBVisible}
                notActive={notActive}
                type={type}
                onRedirectTaxPeriod={handleRedirectTaxPeriod}
                onClickVisibility={handleClickVisibility}
                period={period}
              />
            </PrintSpecificationsProvider>
          </Row>
        </Wrapper>
        <Wrapper>
          {periodChangeVisible && (
            <IncomingColumnTitle>
              {formatMessage({ id: 'psaldo' })}
            </IncomingColumnTitle>
          )}
          {periodUBVisible && (
            <OutgoingColumnTitle>
              {formatMessage({ id: 'reconciliation.periodHeader.ob' })}
            </OutgoingColumnTitle>
          )}
        </Wrapper>
      </Header>
    </Container>
  );
};

export default PeriodHeader;

import React, { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { IconButton, Divider } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { stringValue } from '@agoy/document';
import { FinancialReport } from '@agoy/financial-report-document';
import { Period } from '@agoy/api-sdk-core';
import { CompanyType } from '_clients/types/types';
import useHistory from '_shared/hooks/useHistory';
import { mapPartToModel } from '../../utils';
import StatusSelector from './StatusSelector';
import MinimapMenu from './MinimapMenu';

interface Section {
  part: string;
  title: string;
  isActivePart: boolean;
}
interface RouteParams {
  currentPart: string;
}

const drawerWidth = ({ open }) => (open ? 360 : 56);

const Drawer = styled.div`
  width: ${drawerWidth}px;
  min-width: ${drawerWidth}px;
  transition: all 0.25s ease-in-out;
  transition-property: width;
  & > div > * {
    width: ${drawerWidth}px;
    transition: all 0.25s ease-in-out;
    transition-property: width;
  }
  z-index: ${(props) => props.theme.zIndex.leftMenu};

  display: flex;
`;

const DrawerContainer = styled.div`
  box-shadow: 0 3px 6px ${(props) => props.theme.palette.grey[400]};
  display: flex;
`;

const DrawerContent = styled.div`
  white-space: nowrap;
  background-color: ${(props) => props.theme.palette.common.white};

  display: flex;
  flex-direction: column;
  flex-grow: 1;

  position: sticky;

  overflow-x: hidden;
`;

const DrawerHeader = styled.div<{ open: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  padding-left: ${(props) =>
    props.open ? props.theme.spacing(1) : props.theme.spacing(1 / 2)}px;
  padding-right: ${(props) => props.theme.spacing(1 / 2)}px;
  min-height: ${(props) => props.theme.spacing(7)}px;
`;

const DrawerHeadElements = styled.div`
  padding: ${({ theme }) => theme.spacing(1, 0, 1, 2)};
`;

const ChevronWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
`;

const MenuWrapper = styled.div`
  width: 359px;
  flex: 1;
  overflow-y: auto;
`;

export const MENU_SECTION_CONFIG_ONE_INCOME = [
  'frontpage',
  'table-of-contents',
  'additionalTextOne',
  'RRBRDiagram',
  'RRBRTable',
  'cashFlowStatement',
  'income-statement-period-part1',
  'income-statement-part1',
  'balance-sheet-assets-part1',
  'balance-sheet',
  'additionalTextTwo',
  'documents',
  'create',
];

export const getFullSections = (
  splitIncomeStatement: boolean,
  splitIncomeStatementPeriod: boolean,
  shouldSplitBalanceSheet: boolean,
  companyType: CompanyType,
  periodType: Period['type']
) => {
  let sections = [...MENU_SECTION_CONFIG_ONE_INCOME];

  if (splitIncomeStatement) {
    const index = sections.indexOf('income-statement-part1');
    sections.splice(index + 1, 0, 'income-statement-part2');
  }

  if (splitIncomeStatementPeriod) {
    const index = sections.indexOf('income-statement-period-part1');

    sections.splice(index + 1, 0, 'income-statement-period-part2');
  }
  if (shouldSplitBalanceSheet) {
    const index = sections.indexOf('balance-sheet-assets-part1');

    sections.splice(index + 1, 0, 'balance-sheet-assets-part2');
  }

  if (companyType && companyType === 'individual') {
    sections = sections.filter(
      (section) =>
        section !== 'RRBRDiagram' &&
        section !== 'RRBRTable' &&
        section !== 'cashFlowStatement'
    );
  }

  if (periodType === 'year_end') {
    sections = sections.filter(
      (section) => !section.startsWith('income-statement-period-part')
    );
  }

  return sections;
};

const UNLINKED_MENU_SECTION_CONFIG = ['settings'];

interface MinimapMenuDrawerProps {
  financialReport: FinancialReport;
  companyType: CompanyType;
  shouldSplitIncomeStatement?: boolean;
  shouldSplitIncomeStatementPeriod?: boolean;
  shouldSplitBalanceSheet?: boolean;
  period: Period;
}

const MinimapMenuDrawer = ({
  financialReport,
  companyType,
  shouldSplitIncomeStatement,
  shouldSplitIncomeStatementPeriod,
  shouldSplitBalanceSheet,
  period,
}: MinimapMenuDrawerProps): JSX.Element | null => {
  const { currentPart } = useParams<RouteParams>();
  const history = useHistory();
  const { formatMessage } = useIntl();

  const [open, setOpen] = useState(true);

  const checkIfActivePart = useCallback(
    (part) => {
      switch (part) {
        case 'income-statement-part1': {
          return financialReport.incomeStatementTables.section.table.active;
        }
        case 'income-statement-part2': {
          return financialReport.incomeStatementTables.section.secondTable
            .active;
        }
        case 'income-statement-period-part1': {
          return financialReport.incomeStatementTables.section.periodTable
            .active;
        }
        case 'income-statement-period-part2': {
          return financialReport.incomeStatementTables.section.secondPeriodTable
            .active;
        }
        case 'balance-sheet': {
          return financialReport.balanceSheet.section.equityAndLiabilities
            .active;
        }
        case 'balance-sheet-assets-part1': {
          return financialReport.balanceSheetAssets.section.active;
        }
        case 'balance-sheet-assets-part2': {
          return financialReport.balanceSheetAssets.section.activeSecondTable;
        }
        case 'additionalTextOne': {
          return financialReport.additionalText.section.text1Active;
        }
        case 'additionalTextTwo': {
          return financialReport.additionalText.section.text2Active;
        }
        default: {
          return financialReport[`${mapPartToModel(part)}`]?.section.active;
        }
      }
    },
    [financialReport]
  );

  const menuSections = useCallback(
    (menu): Section[] => {
      return menu.map((section) => {
        let sectionTitle = formatMessage({ id: `financialreport.${section}` });

        switch (section) {
          case 'documents':
            sectionTitle =
              stringValue(financialReport.documents.section.title) ||
              sectionTitle;
            break;
          case 'additionalTextOne':
            sectionTitle =
              stringValue(financialReport.additionalText.section.text1Title) ||
              sectionTitle;
            break;
          case 'additionalTextTwo':
            sectionTitle =
              stringValue(financialReport.additionalText.section.text2Title) ||
              sectionTitle;
            break;
          case 'income-statement-period-part1':
            sectionTitle = formatMessage({
              id: shouldSplitIncomeStatementPeriod
                ? `financialreport.income-statement-period-part1`
                : `financialreport.income-statement-period`,
            });

            break;
          case 'income-statement-part1':
            sectionTitle = formatMessage({
              id: shouldSplitIncomeStatement
                ? `financialreport.income-statement-part1`
                : `financialreport.income-statement`,
            });

            break;
          case 'balance-sheet-assets-part1':
            sectionTitle = formatMessage({
              id: shouldSplitBalanceSheet
                ? `financialreport.balance-sheet-assets-part1`
                : `financialreport.balance-sheet-assets`,
            });

            break;

          default:
            break;
        }

        return {
          part: section,
          title: sectionTitle,
          isActivePart: checkIfActivePart(section),
        };
      });
    },
    [
      financialReport,
      formatMessage,
      checkIfActivePart,
      shouldSplitIncomeStatement,
      shouldSplitBalanceSheet,
      shouldSplitIncomeStatementPeriod,
    ]
  );

  const handleMenuClick = useCallback(
    (part) => {
      history.push(part);
    },
    [history]
  );

  if (!period) {
    return null;
  }

  const menuList = getFullSections(
    !!shouldSplitIncomeStatement,
    !!shouldSplitIncomeStatementPeriod,
    !!shouldSplitBalanceSheet,
    companyType,
    period.type
  );

  return (
    <Drawer open={open}>
      <DrawerContainer>
        <DrawerContent>
          <DrawerHeader open={open}>
            {open && (
              <DrawerHeadElements>
                <StatusSelector />
              </DrawerHeadElements>
            )}
            <ChevronWrapper>
              <IconButton
                size="medium"
                onClick={() => {
                  setOpen(!open);
                }}
              >
                {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </ChevronWrapper>
          </DrawerHeader>
          <Divider />
          <MenuWrapper>
            <MinimapMenu
              sections={menuSections(UNLINKED_MENU_SECTION_CONFIG)}
              currentPart={currentPart}
              handleMenuClick={handleMenuClick}
            />
            <MinimapMenu
              sections={menuSections(menuList)}
              currentPart={currentPart}
              handleMenuClick={handleMenuClick}
            />
          </MenuWrapper>
        </DrawerContent>
      </DrawerContainer>
    </Drawer>
  );
};

export default MinimapMenuDrawer;

import { groupBy, mapValues, orderBy } from 'lodash';
import {
  Program,
  ProgramStatusPeriodState,
  ProgramStatusYearState,
} from '_shared/types';
import {
  SetProgramFinancialYearStatus,
  SetProgramPeriodsStatus,
} from '../actions';

/**
 * Common function for updating the redux state of any
 * program with the program statuses for periods.
 *
 * @param targetProgram A program like 'AN_REPORT
 * @param state a state with the key periodStatus
 * @param action an action of type SET_PROGRAM_PERIODS_STATUS
 * @returns
 */
export const updateProgramStatusPeriodState = <
  T extends { periodStatus?: Record<number, ProgramStatusPeriodState> },
>(
  targetProgram: Program,
  state: T,
  action: SetProgramPeriodsStatus
): T => {
  const { periods, program } = action;
  if (!periods || program !== targetProgram) {
    return state;
  }

  const statusPeriods = periods.map((period) => {
    const { givenName, familyName, ...rest } = period;
    return {
      ...rest,
      createdBy: `${givenName} ${familyName}`,
      period: period.period || '',
    };
  });

  return {
    ...state,
    periodStatus: mapValues(
      groupBy(
        // The latest and current status first
        orderBy(statusPeriods, 'createdAt', 'desc'),
        'periodId'
      ),
      (status) => ({
        history: status,
        status: status[0].status,
      })
    ),
  };
};

/**
 * Common function for updating the redux state of any
 * program with the program statuses for financial years
 * (including year end periods).
 *
 * @param targetProgram A program like 'AN_REPORT'
 * @param state a state with the key periodStatus
 * @param action an action of type SET_PROGRAM_PERIODS_STATUS
 * @returns
 */

export const updateProgramStatusYearState = <
  T extends { yearStatus?: Record<number, ProgramStatusYearState> },
>(
  targetProgram: Program,
  state: T,
  action: SetProgramFinancialYearStatus
): T => {
  const { status, program } = action;
  if (!status || program !== targetProgram) {
    return state;
  }

  const yearStatuses = status.map((period) => {
    const { givenName, familyName, ...rest } = period;
    return {
      ...rest,
      createdBy: `${givenName} ${familyName}`,
      period: period.period || '',
    };
  });

  return {
    ...state,
    yearStatus: mapValues(
      groupBy(
        // The latest and current status first
        orderBy(yearStatuses, 'createdAt', 'desc'),
        'financialYearId'
      ),
      (status) => ({
        history: status,
        status: status[0].status,
      })
    ),
  };
};

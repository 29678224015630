// eslint-disable-next-line import/no-cycle
import { UpdateTaxDeclarationTableCell } from 'redux/actions';
import { Field, FieldUpdate, isError, operations } from '@agoy/document';
import { TaxDeclarationFormData } from '@agoy/tax-declaration-document';
import TaxDeclarationViewState from '../types';

const updateTaxDeclarationFormTableCell = (
  state: TaxDeclarationViewState,
  action: UpdateTaxDeclarationTableCell
): TaxDeclarationViewState => {
  if (Object.keys(state.formData).length === 0) {
    return state;
  }

  const selectedFormId = state.viewFormId;
  if (!selectedFormId) return state;

  const formData = state.formData[selectedFormId];
  if (!formData) {
    return state;
  }

  const { partId, id, value } = action;

  let newCell: Field & FieldUpdate;
  switch (typeof value) {
    case 'string':
      newCell = { type: 'string', value };
      break;
    case 'boolean':
      newCell = { type: 'boolean', value };
      break;
    case 'number':
      newCell = { type: 'number', value };
      break;
    case 'undefined':
      newCell = { type: 'number', value: undefined };
      break;
    default:
      newCell = value;
  }

  const result = operations.updateCellValue(
    formData.definition,
    { document: formData.document, changes: formData.changes },
    `${partId}.${id}`,
    newCell
  );

  if (!result || isError(result)) {
    console.warn(action, result);
    return state;
  }

  return {
    ...state,
    formData: {
      ...state.formData,
      [selectedFormId]: {
        ...formData,
        document: result.document as TaxDeclarationFormData,
        changes: result.changes,
      },
    },
  };
};

export default updateTaxDeclarationFormTableCell;

import React, { useState, useCallback } from 'react';
import styled from '@emotion/styled';
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  ClickAwayListener,
  Tooltip,
} from '@material-ui/core';
import config from '_shared/services/config';
import { useIntl } from 'react-intl';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';
import FileSaver from 'file-saver';
import { firstValueFrom } from 'rxjs';

import { useSelector } from 'redux/reducers';
import { sieFileJustUpdated } from 'redux/actions';
import useClientDataLayer from 'data/client/useClientDataLayer';
import LoadingLogo from '_shared/components/LoadingLogo';
import { addGlobalErrorMessage } from '_messages/redux/actions';
import { ClientCompanyType } from '_clients/types/types';
import { useGenerateSieFile } from 'Api/Client/sie';
import { isUserError } from 'types/Error/types';
import { getBuffer } from 'utils/FileReader/file-reader-util';
import generateCsv from 'utils/generateCSV';
import UploadErrorDialog from './UploadErrorDialog';
import When from '../When/When';

const Popup = styled(List)`
  position: absolute;
  border-radius: 8px;
  width: max-content;
  min-width: 250px;
  left: 35px;
  bottom: 35px;
  padding: 10px 0px;
  background-color: white;
  z-index: 15;
  box-shadow: ${(props) => props.theme.shadows[6]};
`;

const StyledListItemText = styled(ListItemText)`
  padding: 0 5px;
  color: ${({ theme }) => theme.palette.text.primary};
`;

const StyledListItem = styled(ListItem)`
  padding-top: 0;
  padding-bottom: 0;
`;

const StyledDivider = styled(Divider)`
  margin: 10px 20px;
`;

type SideBarPopupProps = {
  client: ClientCompanyType;
  financialYear?: string;
  onClickAway: () => void;
};

const SideBarPopup = ({
  client,
  financialYear,
  onClickAway,
}: SideBarPopupProps) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const generateSieFile = useGenerateSieFile();
  const clientDataLayer = useClientDataLayer(client.id);

  const { name } = useSelector((state) => state.customers[client.id]);

  const [isUploading, setUploading] = useState(false);
  const [isDownloading, setDownloading] = useState(false);
  const [isExporting, setExporting] = useState(false);
  const [uploadError, setUploadError] = useState('');

  const isFortnoxMode = config.whiteLabelUI === 'fortnox';

  const handleSieExport = async () => {
    if (!financialYear) {
      return;
    }

    try {
      setExporting(true);
      const result = await generateSieFile(client.id, financialYear);
      FileSaver.saveAs(
        result,
        `${client.name}_${financialYear}_${format(
          new Date(),
          'yyyyMMddhhmmss'
        )}.se`
      );
    } catch (err) {
      dispatch(
        addGlobalErrorMessage(isUserError(err) ? err.messageId : 'error')
      );
    } finally {
      setExporting(false);
    }
  };

  const handleCloseError = () => {
    setUploadError('');
  };

  const handleSieUpload = useCallback(
    (selectorFiles: FileList | null) => {
      const reader = new FileReader();

      try {
        reader.addEventListener('load', () => {
          setUploading(true);
          setUploadError('');
          const buffer = getBuffer(reader);

          clientDataLayer?.accountingBalances
            .uploadSieFile(buffer)
            .then((result) => {
              if (result.ok) {
                dispatch(sieFileJustUpdated());
              } else {
                setUploadError(result.val.errorCode);
              }
            })
            .catch((err) => {
              console.error(err);
              setUploadError(typeof err === 'string' ? err : 'error');
            })
            .finally(() => {
              setUploading(false);
            });
        });

        if (selectorFiles) {
          reader.readAsArrayBuffer(selectorFiles[0]);
        }
      } catch {
        dispatch(addGlobalErrorMessage('sie.error'));
      }
    },
    [dispatch, clientDataLayer]
  );

  const handleCsvExport = useCallback(async () => {
    if (!financialYear) {
      return;
    }

    setDownloading(true);

    const result = await firstValueFrom(
      clientDataLayer.accountingBalances.getAccountingBalances(financialYear)
    );

    if (result.ok) {
      const accountingBalances = result.val?.accountingBalances;

      if (accountingBalances) {
        const csvBlob = generateCsv(accountingBalances);
        FileSaver.saveAs(csvBlob, `${name}.csv`);
      }
    }

    setDownloading(false);
  }, [financialYear, clientDataLayer.accountingBalances, name]);

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Popup>
        <UploadErrorDialog error={uploadError} onClose={handleCloseError} />

        <Tooltip
          title={
            !financialYear
              ? formatMessage({ id: 'sidebar.popup.export.disabledTooltip' })
              : ''
          }
        >
          <div>
            <StyledListItem
              button
              disabled={isExporting || !financialYear}
              onClick={handleSieExport}
            >
              <StyledListItemText>
                {formatMessage({ id: 'sidebar.popup.export' })}
              </StyledListItemText>
              {isExporting && <LoadingLogo />}
            </StyledListItem>
          </div>
        </Tooltip>

        <Tooltip
          title={
            !financialYear
              ? formatMessage({
                  id: 'sidebar.popup.export.csv.disabledTooltip',
                })
              : ''
          }
        >
          <div>
            <StyledListItem
              button
              onClick={handleCsvExport}
              disabled={isDownloading}
            >
              <StyledListItemText>
                {formatMessage({ id: 'sidebar.popup.download' })}
              </StyledListItemText>
              {isDownloading && <LoadingLogo />}
            </StyledListItem>
          </div>
        </Tooltip>
        <When isTrue={!isFortnoxMode}>
          <StyledDivider />
          <label htmlFor="upload-sie-file">
            <StyledListItem button disabled={isUploading}>
              <StyledListItemText>
                {formatMessage({ id: 'sidebar.popup.import' })}
              </StyledListItemText>
              {isUploading && <LoadingLogo />}
              <input
                id="upload-sie-file"
                data-cy="upload-file"
                type="file"
                onChange={(e) => handleSieUpload(e.target.files)}
                style={{ display: 'none' }}
              />
            </StyledListItem>
          </label>
        </When>
      </Popup>
    </ClickAwayListener>
  );
};

export default SideBarPopup;

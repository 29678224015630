import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { useSelector } from 'redux/reducers';
import { asResultClass, isApiErrorType, useApiSdk } from 'api-sdk';
import {
  FinancialYear,
  Period,
  ReconciliationBalanceAccountRow,
} from '@agoy/api-sdk-core';
import { addGlobalErrorMessage } from 'redux/actions';
import FallbackComponent from '_shared/components/ErrorBoundary';
import { currentClientForNewReconciliation } from '_reconciliation/redux/accounting-view/selectors';
import PrintSpecificationsModal from './PrintSpecificationsModal';
import { AccountBalance, BalanceAndInput } from '../types';

interface PrintSpecificationsContextProps {
  printSpecifications: (financialYear: FinancialYear, period: Period) => void;
  handleToggleModal: () => void;
  actualFinancialYear?: string;
}

const initialContext: PrintSpecificationsContextProps = {
  printSpecifications: () => {
    console.warn('No provider');
  },
  handleToggleModal: () => {},
  actualFinancialYear: '',
};

const PrintSpecificationsContext =
  createContext<PrintSpecificationsContextProps>(initialContext);

type PrintSpecificationsProviderProps = React.PropsWithChildren<{
  balances?: AccountBalance;
  actualFinancialYear: string;
}>;

export const PrintSpecificationsProvider = ({
  children,
  actualFinancialYear = '',
  balances,
}: PrintSpecificationsProviderProps): JSX.Element => {
  const { params } = useRouteMatch<{
    clientId: string;
    financialYear: string;
  }>();
  const dispatch = useDispatch();
  const sdk = useApiSdk();

  const userInputData = useSelector(
    currentClientForNewReconciliation(
      params.clientId,
      (state) =>
        state.years[actualFinancialYear || params.financialYear]?.userInput
    )
  );

  const accountingBalances = useSelector(
    currentClientForNewReconciliation(
      params.clientId,
      (state) =>
        state.years[actualFinancialYear || params.financialYear]
          ?.accountingBalances
    )
  );
  const [financialYear, setFinancialYear] = useState<null | FinancialYear>(
    null
  );
  const [period, setPeriod] = useState<null | Period>(null);
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState<BalanceAndInput[]>([]);

  useEffect(() => {
    if (balances) {
      setRows(
        Object.keys(balances)
          .filter(
            (key) =>
              (balances[key] as ReconciliationBalanceAccountRow).state !==
                'not_started' &&
              (balances[key] as ReconciliationBalanceAccountRow).type ===
                'account'
          )
          .map((key) => balances[key] as ReconciliationBalanceAccountRow)
      );
    }
  }, [balances]);

  const printSpecifications = async (
    newFinancialYear: FinancialYear,
    newPeriod: Period
  ) => {
    try {
      let accounts: number[] = [];
      if (userInputData) {
        accounts = Object.keys(userInputData).map((key) =>
          parseInt(key.replace(/[^0-9]/g, ''), 10)
        );
      }

      const userInputResult =
        accounts.length > 0
          ? await asResultClass(
              sdk.getPeriodUserInput({
                periodId: newPeriod.id,
                accountNumbers: accounts,
                clientid: params.clientId,
              })
            )
          : null;

      if (userInputResult?.err) {
        if (
          !isApiErrorType(userInputResult.val) ||
          !userInputResult.val.handled
        ) {
          dispatch(addGlobalErrorMessage('error'));
        }
        return;
      }

      const userInputsForAccounts = userInputResult?.val ?? { accounts: {} };

      const fetchedInputs = Object.keys(userInputsForAccounts.accounts).map(
        (key) => {
          return {
            ...userInputsForAccounts.accounts[key],
            number: parseInt(key, 10),
          };
        }
      );

      let rowsAndInputs = rows.map((row) => {
        return {
          ...row,
          ...((userInputsForAccounts.accounts[row.number] ?? {}) as Partial<
            (typeof userInputsForAccounts.accounts)[0]
          >),
        };
      });

      if (!rowsAndInputs.length) {
        rowsAndInputs = fetchedInputs as BalanceAndInput[];
      }

      setRows(rowsAndInputs);

      setFinancialYear(newFinancialYear);
      setPeriod(newPeriod);
      setOpen(true);
    } catch (error) {
      console.error(error);
      dispatch(addGlobalErrorMessage('error'));
    }
  };

  const handleToggleModal = useCallback(() => {
    setOpen((currentValue) => !currentValue);
  }, []);

  return (
    <PrintSpecificationsContext.Provider
      value={{ printSpecifications, handleToggleModal, actualFinancialYear }}
    >
      {children}
      {accountingBalances && userInputData && period && financialYear && (
        <Sentry.ErrorBoundary fallback={FallbackComponent}>
          <PrintSpecificationsModal
            actualFinancialYear={actualFinancialYear}
            open={open}
            period={period}
            financialYear={financialYear}
            handleToggleModal={handleToggleModal}
            accountingBalances={accountingBalances}
            userInputData={userInputData}
            balanceAndInput={rows}
          />
        </Sentry.ErrorBoundary>
      )}
    </PrintSpecificationsContext.Provider>
  );
};

export default PrintSpecificationsContext;

import React, { useCallback, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { getClient, sieFileJustUpdated } from 'redux/actions';
import { useSelector } from 'redux/reducers';
import FileUpload from '_shared/components/FileUpload';
import { currentClientYear } from '_reconciliation/redux/accounting-view/selectors';
import { addGlobalErrorMessage } from '_messages/redux/actions';
import { getBuffer } from 'utils/FileReader/file-reader-util';
import useClientDataLayer from 'data/client/useClientDataLayer';
import Button from '_shared/components/Buttons/Button';

// import LockedPeriodErrorDialog from './LockedPeriodErrorDialog';

const getErrorBaseId = (error: string) => {
  switch (error) {
    case 'SIE_IMPORT_WRONG_ORG_NUMBER':
      return 'sie.orgNr';
    case 'OVERLAPPING_FINANCIAL_YEAR':
      return 'sie.overlapping';
    default:
      return 'sie.unknown';
  }
};

const UploadSIEButton = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const [key, setKey] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const { currentCustomer } = useSelector((state) => state.customerView);
  const clientDataLayer = useClientDataLayer(currentCustomer);

  const accountingBalances = useSelector(
    currentClientYear((state) => state.accountingBalances)
  );

  const sieFileUpdated = useSelector(
    (state) => state.customerView.sieFileJustUpdated
  );

  const accountingBalancesUpdatedAt = useSelector(
    currentClientYear((state) => state.accountingBalancesUpdatedAt)
  );

  const handleFileUpload = useCallback(
    (selectorFiles: FileList | null) => {
      const reader = new FileReader();
      try {
        reader.addEventListener('load', () => {
          setLoading(true);
          setError('');
          const buffer = getBuffer(reader);

          clientDataLayer?.accountingBalances
            .uploadSieFile(buffer)
            .then((result) => {
              if (result.ok) {
                dispatch(sieFileJustUpdated());
              } else {
                setError(result.val.errorCode);
              }
            })
            .catch((err) => {
              console.error(err);
              setError(typeof err === 'string' ? err : 'error');
            })
            .finally(() => {
              setLoading(false);
            });
        });

        if (selectorFiles) {
          reader.readAsArrayBuffer(selectorFiles[0]);
        }
      } catch {
        dispatch(addGlobalErrorMessage('sie.error'));
      } finally {
        setKey((k) => k + 1);
      }
    },
    [dispatch, clientDataLayer]
  );

  const handleClose = () => {
    setError('');
  };

  useEffect(() => {
    if (sieFileUpdated && currentCustomer) {
      dispatch(getClient(currentCustomer));
    }
  }, [currentCustomer, dispatch, sieFileUpdated]);

  const errorBaseId = getErrorBaseId(error);

  return (
    <>
      <FileUpload
        key={`upload-${key}`}
        showToolTip={accountingBalances === undefined}
        handleChange={handleFileUpload}
        sieUpdated={accountingBalancesUpdatedAt}
        disabled={loading}
      />

      {error && error !== 'SIE_IMPORT_CHANGE_IN_LOCKED_PERIOD' && (
        <Dialog open onClose={handleClose}>
          <DialogTitle>
            {formatMessage({ id: `${errorBaseId}.error.title` })}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {formatMessage({ id: `${errorBaseId}.error.body` })}
              {errorBaseId === 'sie.orgNr' && (
                <>
                  <br />
                  <strong>
                    {formatMessage({ id: `${errorBaseId}.error.strong` })}
                  </strong>
                </>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              label={formatMessage({ id: 'forgot.back' })}
              onClick={handleClose}
            />
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default UploadSIEButton;

import { parseFormat } from '@agoy/dates';

/**
 * Formats year param to correct format when storing in S3
 *
 * @param year Format YYYYMMDD-YYYYMMDD
 * @returns formatted year to use a document folder name in S3, YYYYMM-YYYYMM
 */
export const formatYearVariable = (year: string): string => {
  if (!year || !year.includes('-')) {
    console.warn('Wrong format on year variable');
    return 'Invalid date';
  }
  const [startDate, endDate] = year.split('-');
  const start = parseFormat(startDate, 'yyyyMM');
  const end = parseFormat(endDate, 'yyyyMM');
  return `${start}-${end}`;
};

export default formatYearVariable;

import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Dialog, IconButton, Typography, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useIntl } from 'react-intl';

import Button from '_shared/components/Buttons/Button';
import { Period } from '@agoy/api-sdk-core';
import { parseFormat } from '@agoy/dates';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    padding: ${({ theme }) => theme.spacing(4)}px;
    width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
`;

const Title = styled(Typography)`
  font-size: 28px;
  text-transform: capitalize;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

const SubTitle = styled(Typography)`
  font-size: ${({ theme }) => theme.spacing(3)}px;
  text-transform: capitalize;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`;

const Text = styled(Typography)`
  padding: 0 ${({ theme }) => theme.spacing(8)}px;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing(1)}px;
  margin-top: ${({ theme }) => theme.spacing(4)}px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: ${({ theme }) => theme.spacing(4)}px;
`;

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    height: 45px;
    width: 350px;
  }
`;

type UnlockReasonModalProps = {
  open: boolean;
  period?: Pick<Period, 'start'>;
  onClose: () => void;
  onSubmit: (reason: string) => void;
};

const UnlockReasonModal = ({
  open,
  period,
  onClose,
  onSubmit,
}: UnlockReasonModalProps) => {
  const { formatMessage } = useIntl();

  const [value, setValue] = useState(
    formatMessage({ id: 'sie.reasonModal.defaultValue' })
  );

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleSubmit = () => {
    onSubmit(value);
  };

  return (
    <StyledDialog fullWidth open={open} onClose={onClose}>
      <CloseButton onClick={onClose}>
        <CloseIcon fontSize="large" />
      </CloseButton>

      <Title>{formatMessage({ id: 'sie.reasonModal.title' })}</Title>

      <SubTitle>
        {formatMessage(
          { id: 'sie.reasonModal.subTitle' },
          { period: parseFormat(period?.start, 'MMM-yy') }
        )}
      </SubTitle>

      <Wrapper>
        <Text>
          {formatMessage({
            id: 'sie.reasonModal.warning',
          })}
        </Text>

        <Text>{formatMessage({ id: 'sie.reasonModal.warning2' })}</Text>
      </Wrapper>

      <Row>
        <StyledTextField
          variant="outlined"
          size="small"
          value={value}
          onChange={handleChange}
        />

        <Button
          label={formatMessage({ id: 'sie.reasonModal.button' })}
          onClick={handleSubmit}
        />
      </Row>
    </StyledDialog>
  );
};

export default UnlockReasonModal;

import React from 'react';
import styled from '@emotion/styled';
import { Dialog, IconButton } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import { useIntl } from 'react-intl';

import Button from '_shared/components/Buttons/Button';
import Typography from '_shared/components/Typography/Typography';
import Alert from '_shared/components/AlertBanners/Alert';
import Link from '_shared/components/Link';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    padding: ${({ theme }) => theme.spacing(4)}px;
    width: 600px;
  }
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ButtonsRow = styled(Row)`
  justify-content: flex-end;
  column-gap: 16px;
  padding-top: 16px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

type AnnualReportErrorProps = {
  clientId: string;
  financialYear: string;
  open: boolean;
  onClose: () => void;
};

const AnnualReportError = ({
  open,
  clientId,
  financialYear,
  onClose,
}: AnnualReportErrorProps) => {
  const { formatMessage } = useIntl();

  return (
    <StyledDialog open={open} onClose={onClose}>
      <CloseButton onClick={onClose}>
        <CloseIcon fontSize="large" />
      </CloseButton>

      <Typography variant="h2" margin="bottom">
        {formatMessage({ id: 'sie.periodLocked.arError.title' })}
      </Typography>

      <Typography>
        {formatMessage({
          id: 'sie.periodLocked.arError.subTitle',
        })}
      </Typography>

      <Alert type="warning">
        <Typography margin="none" variant="h4">
          {formatMessage({
            id: 'sie.periodLocked.arError.warning.title',
          })}
        </Typography>
        <Typography margin="none">
          {formatMessage({
            id: 'sie.periodLocked.arError.warning.message',
          })}
        </Typography>
        <StyledLink
          to={`/clients/${clientId}/${financialYear}/year/confirmAnnualReportMenu`}
        >
          <Row>
            <Typography margin="none" variant="h4">
              {formatMessage({ id: 'sie.periodLocked.arError.warning.button' })}
            </Typography>
            <ChevronRightIcon />
          </Row>
        </StyledLink>
      </Alert>

      <ButtonsRow>
        <Button
          label={formatMessage({ id: 'sie.periodLockedError.cancel' })}
          variant="text"
          onClick={onClose}
        />
        <Button label={formatMessage({ id: 'understand' })} onClick={onClose} />
      </ButtonsRow>
    </StyledDialog>
  );
};

export default AnnualReportError;

import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';

const withMaterialTheme = (theme) => (Component) => (props) => (
  <ThemeProvider theme={theme}>
    <Component {...props} />
  </ThemeProvider>
);

export default withMaterialTheme;

import React, { memo, useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import {
  AppBar as MuiAppBar,
  Toolbar as MuiToolbar,
  Divider,
  // TODO: BUTTONS view design revision needed
  Button as MuiButton,
  Switch,
  FormControlLabel as MuiFormControlLabel,
  Typography,
  FormControl,
  TextField,
  InputAdornment,
  Tooltip,
  ClickAwayListener,
  Badge,
} from '@material-ui/core';
import {
  AddCircleOutline,
  Business,
  ListAltOutlined,
  Person,
  Search,
  MoreHoriz,
  LocalOfferOutlined,
  PersonOutline,
} from '@material-ui/icons';

import { useSelector } from 'redux/reducers';
import {
  toggleLampsExpanded,
  toggleHideCleanClients,
  setSortByType,
  setSortBySearch,
  setActiveTags,
  setFilters,
  setActiveAssignees,
} from 'redux/actions';
import config from '_shared/services/config';
import LinkButton from '_shared/components/Buttons/LinkButton';
import { FilterGroups } from '_shared/components/Filters';
import { Filters } from '_clients/redux/customers-overview/types';
import TagFilter from '_shared/components/TagFilter/TagFilter';
import {
  ClientCustomersAssigneeType,
  ClientCustomerAssigneeType,
  ClientCustomersTagsType,
  ClientCustomerTagType,
} from '_clients/types/types';
import {
  getClosingMonthLabels,
  getClosingPeriodLabels,
  getCompanyTypeLabels,
  getDeclarationPeriodLabels,
} from '_clients/components/utils';
import { useLicenseStatus } from '_payment/licenses/useLicense';
import When from '_shared/components/When/When';
import { Legend } from './Legend';
import useHistory from '../../../hooks/useHistory';

type UniqueElement = ClientCustomerAssigneeType | ClientCustomerTagType;

const SearchField = styled(TextField)`
  min-width: 240px;
  @media screen and (max-width: 1420px) {
    min-width: 160px;
  }
`;

const AppBar = styled(MuiAppBar)`
  border: none;
  background: ${({ theme }) => theme.palette.grey[200]};
  color: inherit;
  height: fit-content;
`;

const Toolbar = styled(MuiToolbar, {
  shouldForwardProp: isPropValid,
})`
  display: flex;
  justify-content: space-between;
  gap: ${(props) => props.theme.spacing(2)}px;
  box-shadow: ${(props) => props.theme.shadows[2]};
  padding-top: ${({ theme }) => theme.spacing(1)}px;
  padding-bottom: ${({ theme }) => theme.spacing(1)}px;
  height: inherit;
`;

const RightMenuItems = styled(FormControl)`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
`;

const ButtonTitle = styled(Typography)`
  display: block;
  margin-left: 8px;
  text-align: left;
  word-wrap: break-word;
  @media screen and (max-width: 1040px) {
    display: none;
  }
`;

const AlternativeButtonTitle = styled(Typography)`
  display: block;
  margin-left: 8px;
  text-align: left;
  word-wrap: break-word;
  @media screen and (max-width: 1550px) {
    display: none;
  }
`;

const Button = styled(MuiButton)`
  margin: 0;
  justify-content: center;
  min-width: 0;
  .MuiButton-startIcon {
    margin: 0;
  }
`;

const OrganisationIcon = styled(Business)`
  color: ${({ theme }) => theme.palette.grey[200]};
`;

const FilterButton = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  min-width: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  min-width: 0;
  margin: 0;
`;

const FilterDropdown = styled.div<{ open?: boolean }>`
  position: absolute;
  z-index: 100;
  left: 0;
  top: 48px;
  width: 380px;
  height: 50vh;
  min-height: 380px;

  transform: scaleY(${({ open }) => (open ? 1 : 0)});
  transform-origin: top center;
  transition: transform 250ms cubic-bezier(0.2, 0, 0, 1);
`;

const SorterGroupingDropdown = styled.div<{ open?: boolean }>`
  position: absolute;
  z-index: 100;
  left: 0;
  top: 48px;
  width: 280px;

  transform: scaleY(${({ open }) => (open ? 1 : 0)});
  transform-origin: top center;
  transition: transform 250ms cubic-bezier(0.2, 0, 0, 1);
`;

const FormControlLabel = styled(MuiFormControlLabel)`
  justify-content: center;
  margin-left: 0 !important;
  margin-right: 0 !important;
`;

const extractUniqueElements = <T extends UniqueElement>(
  elements: T[][],
  property: keyof T
): T[] => {
  return [
    ...elements
      .flat(1)
      .reduce(
        (accumulator, current) => accumulator.set(current?.[property], current),
        new Map()
      )
      .values(),
  ];
};

const readAndFilterItemsFromLocalStorage = (
  storageKey: string,
  uniqueItems: Array<{ id: string }>
) => {
  const storedItemsString = localStorage.getItem(storageKey);

  if (storedItemsString) {
    const storedItems = JSON.parse(storedItemsString);

    // Filter stored items based on their existence in the unique items list
    return storedItems.filter((item: { id: string }) =>
      uniqueItems.some((uniqueItem) => uniqueItem.id === item.id)
    );
  }
  return [];
};

const isFortnoxWhiteLabel = config.whiteLabelUI === 'fortnox';
const CUSTOMER_FILTER_STORAGE_KEY = 'customer-view-filters';
const CUSTOMER_FILTER_TAGS_STORAGE_KEY = 'customer-view-tags-filters';
const CUSTOMER_FILTER_ASSIGNEES_STORAGE_KEY = 'customer-view-assignees-filters';

const CustomersOverviewSubMenu = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();

  const {
    lampsExpanded,
    hideCleanClients,
    sortType: sortedByType,
    search: savedSearch,
    activeClientTags,
    activeClientAssignees,
    filters,
  } = useSelector((state) => state.customersOverview);

  const { license } = useLicenseStatus();

  const customers = useSelector((state) => state.customers);
  const clientsPopulated = useSelector((state) => state.ui.clientsPopulated);

  const [welcome, setWelcome] = useState(true);
  const [search, setSearch] = useState<string | null>(null);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [sorterGroupingOpen, setSorterGroupingOpen] = useState(false);
  const [sorterGroupingAssigneesOpen, setSorterGroupingAssigneeOpen] =
    useState(false);
  const [tags, setTags] = useState<ClientCustomersTagsType>([]);
  const [assignees, setAssignees] = useState<ClientCustomersAssigneeType>([]);

  const text = (id: string) => intl.formatMessage({ id });

  useEffect(() => {
    const storedFiltersJSON = localStorage.getItem(CUSTOMER_FILTER_STORAGE_KEY);

    if (storedFiltersJSON) {
      const storedFilters: Filters = JSON.parse(storedFiltersJSON);
      const newFilters = { ...filters };
      Object.keys(filters).forEach((filterGroup) => {
        if (storedFilters[filterGroup]) {
          newFilters[filterGroup] = storedFilters[filterGroup];
        }
      });

      dispatch(setFilters(newFilters));
    }
  }, [dispatch]);

  useEffect(() => {
    localStorage.setItem(CUSTOMER_FILTER_STORAGE_KEY, JSON.stringify(filters));
  }, [filters]);

  useEffect(() => {
    if (Object.keys(customers).length === 0) {
      return;
    }

    setWelcome(false);

    const allAssignees = Object.values(customers)
      .map((client) => client.assignees)
      .filter(
        (assignee) => assignee !== undefined
      ) as ClientCustomersAssigneeType[];

    const allTags = Object.values(customers)
      .map((client) => client.tags)
      .filter((tag) => tag !== undefined) as ClientCustomersTagsType[];

    const uniqueAssignees: ClientCustomersAssigneeType = extractUniqueElements(
      allAssignees,
      'id'
    );

    setAssignees(
      uniqueAssignees.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
      )
    );

    const uniqueTags: ClientCustomersTagsType = extractUniqueElements(
      allTags,
      'id'
    );

    setTags(
      uniqueTags.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
      )
    );

    const storedTags: ClientCustomersTagsType =
      readAndFilterItemsFromLocalStorage(
        CUSTOMER_FILTER_TAGS_STORAGE_KEY,
        uniqueTags
      );

    dispatch(setActiveTags(storedTags));

    const storedAssignees: ClientCustomersAssigneeType =
      readAndFilterItemsFromLocalStorage(
        CUSTOMER_FILTER_ASSIGNEES_STORAGE_KEY,
        uniqueAssignees
      );

    dispatch(setActiveAssignees(storedAssignees));
  }, [customers, dispatch]);

  useEffect(() => {
    if (!clientsPopulated) {
      return;
    }

    localStorage.setItem(
      CUSTOMER_FILTER_ASSIGNEES_STORAGE_KEY,
      JSON.stringify(activeClientAssignees)
    );

    localStorage.setItem(
      CUSTOMER_FILTER_TAGS_STORAGE_KEY,
      JSON.stringify(activeClientTags)
    );
  }, [activeClientTags, activeClientAssignees, clientsPopulated]);

  useEffect(() => {
    if (search || search === '') {
      dispatch(setSortBySearch(search));
    }
  }, [search, dispatch]);

  const toggleLamps = () => dispatch(toggleLampsExpanded());
  const toggleHideClean = () => dispatch(toggleHideCleanClients());

  const handleSortingByManagersClick = () =>
    dispatch(setSortByType('BY_CLIENT_MANAGER'));
  const handleSortingByNamesClick = () =>
    dispatch(setSortByType('BY_CLIENT_NAME'));

  const handleFilters = (
    filterId: string,
    field: string,
    newValue: boolean
  ) => {
    const filterGroup = filters[filterId];
    const updatedFilterGroup = { ...filterGroup };

    updatedFilterGroup[field] = newValue;

    dispatch(
      setFilters({
        ...filters,
        [filterId]: updatedFilterGroup,
      })
    );
  };

  const handleClientTagsFilter = (item: ClientCustomerTagType) => {
    const isTagAlreadyActive = activeClientTags.find(
      (activeTag) => activeTag.id === item.id
    );
    if (isTagAlreadyActive) {
      const updatedActiveClientTags = [
        ...activeClientTags.filter((activeTag) => activeTag.id !== item.id),
      ];
      dispatch(setActiveTags(updatedActiveClientTags));
      return;
    }
    const updatedActiveClientTags = [...activeClientTags, item];

    dispatch(setActiveTags(updatedActiveClientTags));
  };

  const handleClientAssigneeFilter = (item: ClientCustomerAssigneeType) => {
    const isAssigneeAlreadyActive = activeClientAssignees.find(
      (activeTag) => activeTag.id === item.id
    );
    if (isAssigneeAlreadyActive) {
      const updatedActiveAssigneeTags = [
        ...activeClientAssignees.filter(
          (activeTag) => activeTag.id !== item.id
        ),
      ];
      dispatch(setActiveAssignees(updatedActiveAssigneeTags));
      return;
    }
    const updatedActiveAssigneeTags = [...activeClientAssignees, item];

    dispatch(setActiveAssignees(updatedActiveAssigneeTags));
  };

  const createCustomer = useCallback(() => {
    history.push(`/clients/create`);
  }, [history]);

  const handleSearch = (
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearch(ev.target.value);
  };

  const filterLabels = {
    closingPeriodFilter: getClosingPeriodLabels(intl),
    companyTypeFilter: getCompanyTypeLabels(intl),
    closingMonthFilter: getClosingMonthLabels(intl),
    declarationPeriodFilter: getDeclarationPeriodLabels(intl),
  };

  return (
    <>
      {!welcome && (
        <AppBar position="static">
          <Toolbar>
            {!isFortnoxWhiteLabel && (
              <>
                <Tooltip title={text('sidemenu.add_customer')}>
                  <Button
                    startIcon={<AddCircleOutline />}
                    onClick={createCustomer}
                    data-cy="add-client"
                    id="onboarding-first-customer"
                  >
                    <AlternativeButtonTitle variant="button">
                      {text('sidemenu.add_customer')}
                    </AlternativeButtonTitle>
                  </Button>
                </Tooltip>
                <Divider orientation="vertical" flexItem />
              </>
            )}
            <Tooltip title={text('customerlist.showAllStatuses')}>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    size="small"
                    checked={lampsExpanded}
                    onChange={toggleLamps}
                    id="onboarding-legend"
                  />
                }
                label={
                  <AlternativeButtonTitle variant="button">
                    {text('customerlist.showAllStatuses')}
                  </AlternativeButtonTitle>
                }
              />
            </Tooltip>

            {lampsExpanded && <Legend />}
            <Divider orientation="vertical" flexItem />
            {/* The button that is shown depends whether the clients 
            are ordered by its names or managers */}
            <Tooltip title={text('customerlist.hideCleanClients')}>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    size="small"
                    checked={hideCleanClients}
                    onChange={toggleHideClean}
                    id="onboarding-legend"
                  />
                }
                label={
                  <AlternativeButtonTitle variant="button">
                    {text('customerlist.hideCleanClients')}
                  </AlternativeButtonTitle>
                }
              />
            </Tooltip>
            <Divider orientation="vertical" flexItem />
            {isFortnoxWhiteLabel ? (
              <>
                <ClickAwayListener
                  onClickAway={() => {
                    setSorterGroupingAssigneeOpen(false);
                  }}
                >
                  <div>
                    <FilterButton>
                      <Badge
                        overlap="rectangular"
                        badgeContent={activeClientAssignees.length}
                      >
                        <Tooltip
                          title={text('sidemenu.filters.managers.tooltip')}
                        >
                          <Button
                            startIcon={<PersonOutline />}
                            onClick={() =>
                              setSorterGroupingAssigneeOpen(
                                (oldFiltersOpen) => !oldFiltersOpen
                              )
                            }
                          >
                            <ButtonTitle variant="button">
                              {text('sidemenu.filters.managers')}
                            </ButtonTitle>
                          </Button>
                        </Tooltip>
                        <SorterGroupingDropdown
                          open={sorterGroupingAssigneesOpen}
                        >
                          <TagFilter
                            defaultItemsList={assignees}
                            activeItemsList={activeClientAssignees}
                            onEraseFilters={() => {
                              dispatch(setActiveAssignees([]));
                            }}
                            onSelect={handleClientAssigneeFilter}
                          />
                        </SorterGroupingDropdown>
                      </Badge>
                    </FilterButton>
                  </div>
                </ClickAwayListener>
              </>
            ) : (
              sortedByType === 'BY_CLIENT_NAME' && (
                <Tooltip title={text('sidemenu.order.managers')}>
                  <Button
                    startIcon={<ListAltOutlined />}
                    onClick={handleSortingByManagersClick}
                  >
                    <ButtonTitle variant="button">
                      {text('sidemenu.order.managers')}
                    </ButtonTitle>
                  </Button>
                </Tooltip>
              )
            )}
            {sortedByType === 'BY_CLIENT_MANAGER' && (
              <Tooltip title={text('sidemenu.order.clients')}>
                <Button
                  startIcon={<ListAltOutlined />}
                  onClick={handleSortingByNamesClick}
                >
                  <ButtonTitle variant="button">
                    {text('sidemenu.order.clients')}
                  </ButtonTitle>
                </Button>
              </Tooltip>
            )}
            <Divider orientation="vertical" flexItem />
            {isFortnoxWhiteLabel && (
              <>
                <ClickAwayListener
                  onClickAway={() => {
                    setSorterGroupingOpen(false);
                  }}
                >
                  <div>
                    <FilterButton>
                      <Badge
                        overlap="rectangular"
                        badgeContent={activeClientTags.length}
                      >
                        <Tooltip title={text('sidemenu.sorters.grouping')}>
                          <Button
                            startIcon={<LocalOfferOutlined />}
                            onClick={() =>
                              setSorterGroupingOpen(
                                (oldFiltersOpen) => !oldFiltersOpen
                              )
                            }
                          >
                            <ButtonTitle variant="button">
                              {text('sidemenu.sorters.grouping')}
                            </ButtonTitle>
                          </Button>
                        </Tooltip>
                        <SorterGroupingDropdown open={sorterGroupingOpen}>
                          <TagFilter
                            defaultItemsList={tags}
                            activeItemsList={activeClientTags}
                            onEraseFilters={() => {
                              dispatch(setActiveTags([]));
                            }}
                            onSelect={handleClientTagsFilter}
                          />
                        </SorterGroupingDropdown>
                      </Badge>
                    </FilterButton>
                  </div>
                </ClickAwayListener>
                <Divider orientation="vertical" flexItem />
              </>
            )}
            <ClickAwayListener
              onClickAway={() => {
                setFiltersOpen(false);
              }}
            >
              <FilterButton>
                <Badge
                  overlap="rectangular"
                  badgeContent={
                    Object.values(filters)
                      .flatMap((o) => Object.values(o))
                      .filter(Boolean).length
                  }
                >
                  <Tooltip title={text('sidemenu.filters.other')}>
                    <Button
                      startIcon={<MoreHoriz />}
                      onClick={() =>
                        setFiltersOpen((oldFiltersOpen) => !oldFiltersOpen)
                      }
                    >
                      <ButtonTitle variant="button">
                        {text('sidemenu.filters.other')}
                      </ButtonTitle>
                    </Button>
                  </Tooltip>
                  <FilterDropdown open={filtersOpen}>
                    <FilterGroups
                      optionGroups={Object.keys(filters).map(
                        (filterGroupId) => ({
                          id: filterGroupId,
                          name: text(`sidemenu.filters.${filterGroupId}.label`),
                          options: Object.keys(filters[filterGroupId]).map(
                            (filter) => ({
                              label: filterLabels[filterGroupId][filter],
                              name: filter,
                              selected: filters[filterGroupId][filter],
                            })
                          ),
                        })
                      )}
                      onSelect={(fieldId, field) =>
                        handleFilters(fieldId, field, true)
                      }
                      onDeselect={(fieldId, field) =>
                        handleFilters(fieldId, field, false)
                      }
                      onEraseFilters={() => {
                        const newFilters = {
                          ...filters,
                        };

                        Object.keys(newFilters).forEach((filterGroup) => {
                          Object.keys(newFilters[filterGroup]).forEach(
                            (option) => {
                              newFilters[filterGroup][option] = false;
                            }
                          );
                        });

                        dispatch(setFilters(newFilters));
                      }}
                    />
                  </FilterDropdown>
                </Badge>
              </FilterButton>
            </ClickAwayListener>

            <Divider orientation="vertical" flexItem />
            <RightMenuItems>
              <LinkButton
                to="/"
                isSelected
                description={text('customersubmenu.organisationMode')}
              >
                <OrganisationIcon />
              </LinkButton>
              <When isTrue={license === 'big'}>
                <LinkButton
                  to="/persons"
                  description={text('customersubmenu.personMode')}
                >
                  <Person />
                </LinkButton>
              </When>
              <SearchField
                id="search-customer"
                variant="outlined"
                size="small"
                placeholder={text('dashboard.customers.search.placeHolder')}
                onChange={(ev) => {
                  handleSearch(ev);
                }}
                value={savedSearch || ''}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </RightMenuItems>
          </Toolbar>
        </AppBar>
      )}
    </>
  );
};

export default memo(CustomersOverviewSubMenu);

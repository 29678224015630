import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'redux/reducers';
import { setCurrentCustomer } from '_clients/redux/customer-view/actions';
import { getClient } from '_clients/redux/customers/actions';
import useHistory from '_shared/hooks/useHistory';

const NoFinancialYears = (): React.ReactElement => {
  const {
    params: { clientId },
  } = useRouteMatch<{ clientId: string }>();

  const { currentCustomer: currentClient } = useSelector(
    (state) => state.customerView
  );
  const client = useSelector((state) => state.customers[clientId]);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getClient(clientId));
  }, [clientId, dispatch]);

  useEffect(() => {
    if (client && currentClient !== clientId) {
      dispatch(setCurrentCustomer(clientId, undefined));
    }
  }, [client, clientId, currentClient, dispatch]);

  useEffect(() => {
    if (client?.latestFinancialYear) {
      history.push(
        `/clients/${client.id}/reconciliation/${client?.latestFinancialYear}`
      );
    }
  }, [client, history]);

  return <div />;
};

export default NoFinancialYears;

import React from 'react';
import * as Sentry from '@sentry/react';

const withSentryErrorBoundary =
  (FallbackComponent) => (Component) => (props) => (
    <Sentry.ErrorBoundary fallback={FallbackComponent}>
      <Component {...props} />
    </Sentry.ErrorBoundary>
  );

export default withSentryErrorBoundary;

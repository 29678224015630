import React, { useState } from 'react';
import { Control, FieldValues, Controller } from 'react-hook-form';
import styled from '@emotion/styled';
import { gray } from '@agoy/theme/src/colors';

import SimplifiedSelect from '_shared/components/Inputs/SimplifiedSelect';
import FieldWrapper, { FieldWrapperProps } from './FieldWrapper';
import { validation } from '../utils';
import ClientCardInformationDialog from './ClientCardInformationDialog';

interface ClientCardInformationSelectProps extends FieldWrapperProps {
  listItems: Readonly<
    Array<{
      value: string | number;
      label: string;
    }>
  >;
  name: string;
  placeholder?: string;
  isEditing?: boolean;
  disabled?: boolean;
  control?: Control<FieldValues>;
  valueType?: 'string' | 'number' | 'boolean';
  hasMovedAccounts: boolean;
}

const StyledSimplifiedSelect = styled(SimplifiedSelect)`
  padding: 0 ${(props) => (!props.editing ? props.theme.spacing(2) : 0)}px;
  .MuiOutlinedInput-root {
    border-radius: 8px;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: ${gray.HIGH};
  }
`;

const ClientCardInformationSelect = ({
  name,
  field,
  labelField,
  error,
  isEditing,
  listItems,
  placeholder,
  disabled,
  control,
  source,
  timestamp,
  role,
  user,
  valueType,
  hasMovedAccounts,
}: ClientCardInformationSelectProps) => {
  const [confirmValue, setConfirmValue] = useState('');

  return (
    <FieldWrapper
      source={source}
      timestamp={timestamp}
      role={role}
      user={user}
      field={field}
      labelField={labelField}
      error={error}
      isEditing={isEditing}
    >
      <Controller
        name={name}
        control={control}
        rules={validation[field]}
        render={({ field: { ref, value, onChange, ...rest } }) => {
          const handleChange = (event) => {
            if (name === 'closingPeriod' && hasMovedAccounts) {
              setConfirmValue(event.target.value);
            } // select component doesn't support boolean values, so added this hack
            else if (valueType === 'boolean') {
              onChange(!!event.target.value);
            } else {
              onChange(event.target.value);
            }
          };

          return (
            <>
              <ClientCardInformationDialog
                currentValue={value}
                confirmValue={confirmValue}
                onApprove={() => {
                  onChange(confirmValue);
                  setConfirmValue('');
                }}
                onClose={() => setConfirmValue('')}
              />
              <StyledSimplifiedSelect
                listItems={listItems}
                disabled={disabled}
                placeholder={placeholder}
                editing={isEditing}
                inputRef={ref}
                fullWidth
                value={valueType === 'boolean' ? +value : (value ?? '')}
                onChange={handleChange}
                {...rest}
              />
            </>
          );
        }}
      />
    </FieldWrapper>
  );
};

export default ClientCardInformationSelect;

import { AnnualReportType } from './config/document';
import { getSharesCompanyNotesGeneratedV2K2 } from './config/shares-company/notes/v2/k2/constants';
import { getSharesCompanyNotesGeneratedV2K3 } from './config/shares-company/notes/v2/k3/constants';

export const BALANCE_SHEET_ACCOUNT_ROW_SPLIT_SUFFIX = 'part';

export const requireDatePerSignatureDateBefore = new Date(2021, 11, 30, 23, 59); // month start at 0
export const requireDatePerSignatureDateAfter = new Date(2024, 6, 1, 0, 0);

/*
    period and previous period constants match again balanceSheet.config so
    if you update these make sure to update config as well
*/
export const BALANCE_SHEET_ACCOUNT_ROW_PERIOD = 'year';
export const BALANCE_SHEET_ACCOUNT_ROW_PREVIOUS_PERIOD = 'previousYear';

export const getActiveNotesForSharesCompany = (
  reportType: AnnualReportType,
  documentTypeVersion = '1'
) => {
  if (documentTypeVersion === '2') {
    if (reportType === 'k2') {
      return getSharesCompanyNotesGeneratedV2K2.otherNotes;
    }
    return getSharesCompanyNotesGeneratedV2K3.otherNotes;
  }

  if (reportType === 'k3') {
    return [
      'note1',
      'note2',
      'note33',
      'note34',
      'note35',
      'note36',
      'note37',
      'note38',
      'note39',
      'note67',
      'note68',
      'note80',
      'note84',
      'note86',
      'custom',
    ];
  }

  // k2
  return [
    'note1',
    'note2',
    'note33',
    'note34',
    'note35',
    'note36',
    'note67',
    'note68',
    'custom',
  ];
};

/*
    Certain notes are togglable through active prop on note
    Exceptions to this rule is the first note, which is always active
*/
export const ACTIVE_NOTES_ECONOMIC_ASSOCIATION = [
  'note1',
  'note2',
  'note33',
  'note34',
  'note35',
  'note36',
  'note37',
  'note38',
  'note39',
];

export const ACTIVE_NOTES_INDIVIDUAL_COMPANY = ['note1', 'note56'];

export const ACTIVE_NOTES_LIMITED_COMPANIES = [
  'note1',
  'note2',
  'note33',
  'note34',
  'note35',
  'note36',
  'note37',
  'note38',
  'note39',
];

export const ACTIVE_NOTES_NON_PROFIT_ORG = [
  'note1',
  'note2',
  'note33',
  'note34',
  'note35',
  'note36',
  'note37',
  'note38',
  'note39',
];

export const ACTIVE_NOTES_FOUNDATION_COMPANY = [
  'note1',
  'note2',
  'note33',
  'note34',
  'note35',
  'note36',
  'note37',
  'note38',
  'note39',
];

export const activeCompanyNotes = {
  economic_association: ACTIVE_NOTES_ECONOMIC_ASSOCIATION,
  foundation: ACTIVE_NOTES_FOUNDATION_COMPANY,
  individual: ACTIVE_NOTES_INDIVIDUAL_COMPANY,
  limited_companies: ACTIVE_NOTES_LIMITED_COMPANIES,
  non_profit_association: ACTIVE_NOTES_NON_PROFIT_ORG,
};

export const NOTES_DIGITAL_K2 = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 33, 34, 35, 36, 44, 58, 59, 60, 61, 62, 63, 64, 65, 66,
  67, 68, 69,
];

const K3_ONLY_NOTES = [
  70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88,
  89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 401, 402,
];

export const NOTES_DIGITAL_K2_NOTE = () =>
  NOTES_DIGITAL_K2.map((noteId: number) => `note${noteId}`);

export const NOTES_DIGITAL_K2_NOTES_NOTE = () =>
  NOTES_DIGITAL_K2.map((noteId: number) => `notes.note${noteId}`);

export const K3_NOTE_ID = () =>
  K3_ONLY_NOTES.map((noteId: number) => `note${noteId}`);

export const K3_NOTES_NOTE_ID = () =>
  K3_ONLY_NOTES.map((noteId: number) => `notes.note${noteId}`);

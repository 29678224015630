import React from 'react';
import { ThemeProvider } from '@emotion/react';

const withEmotionTheme = (theme) => (Component) => (props) => (
  <ThemeProvider theme={theme}>
    <Component {...props} />
  </ThemeProvider>
);

export default withEmotionTheme;

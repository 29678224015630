import React, { createContext, useMemo } from 'react';
import config from '_shared/services/config';
import { decode } from 'jsonwebtoken';
import { createUserPool } from 'utils/AuthenticatedClient';
import { setContext } from './contextHolder';
import { AgoyAppClientContextType } from './types';
import useFid from '../../_shared/hooks/useFid';

const AgoyAppClientContext = createContext({} as AgoyAppClientContextType);

// TODO: Remove once new payment is done
const createCognitoContext = () => ({
  baseUrl: config.appEndpoint,
  headers: () => {
    const user = createUserPool().getCurrentUser();
    if (!user) {
      return Promise.resolve({
        'app-version': config.appVersion,
      });
    }
    return new Promise<Record<string, string>>((resolve, reject) => {
      user.getSession((err, session) => {
        if (err) {
          reject(err);
          return;
        }
        const token = session.getIdToken().getJwtToken();

        resolve({
          token,
          'app-version': config.appVersion,
        });
      });
    });
  },
});

/**
 * Extracts the field "custom:userId" from the Fortnox jwt content.
 *
 * @param payload jwt content, from the Fortnox Mode tokens
 * @returns the value of "custom:userId"
 */
const userIdFromJwt = (payload) =>
  payload !== null && typeof payload === 'object'
    ? payload.data['custom:userId']
    : null;

/**
 * Listener for changes in storage, either local or session storage.
 * If the event is for the key "agoyJwt" it check if the user id in the
 * old and new tokens are the same or not.
 *
 * If a change has occurred in the userId the whole state of the window
 * is invalid. It then redirects to /timeout.
 *
 * Since the token is stored on login, the only reason for it to change
 * is that another tabs has opened and logged in as a different user,
 * affecting all open tabs.
 *
 * @param event StorageEvent
 */
const storageEventListener = (event: StorageEvent) => {
  if (event.key === 'agoyJwt') {
    if (event.newValue && event.oldValue) {
      const oldUserId = userIdFromJwt(decode(event.oldValue));
      const newUserId = userIdFromJwt(decode(event.newValue));

      if (oldUserId && newUserId && oldUserId !== newUserId) {
        console.warn('User has changed');
        window.location.href = '/user-changed';
      }
    }
  }
};

export const createFortnoxContext = (fid: string) => {
  return {
    baseUrl: config.appEndpoint,
    headers: async () => {
      return {
        'X-Token': fid,
        'app-version': config.appVersion,
      };
    },
    logout: async () => {
      window.location.reload();
    },
    timeout: async () => {
      window.location.href = '/timeout';
    },
  };
};

// TODO: Remove once new payment is done
export const createJwtContext = () => {
  window.addEventListener('storage', storageEventListener);
  return {
    baseUrl: config.appEndpoint,
    headers: async () => {
      const token = await window.localStorage.getItem('agoyJwt');
      if (!token) {
        throw new Error('No user');
      }
      return {
        'agoy-jwt': token,
        'app-version': config.appVersion,
      };
    },
    // Logout function, used to log the session out if an endpoint returns 401
    logout: async () => {
      await window.localStorage.removeItem('agoyJwt');
      window.location.reload();
    },
    timeout: async () => {
      window.location.href = '/timeout';
    },
  };
};

type AgoyAppClientProviderType = {
  onError: AgoyAppClientContextType['onError'];
  children: React.ReactNode;
};

export const AgoyAppClientProvider = ({
  onError,
  children,
}: AgoyAppClientProviderType) => {
  const fid = useFid();
  const context = useMemo(() => {
    let newContext;
    if (config.isFortnoxCloud && fid) {
      newContext = createFortnoxContext(fid);
    } else {
      newContext =
        config.whiteLabel === 'fortnox'
          ? createJwtContext()
          : createCognitoContext();
    }
    return {
      ...newContext,
      onError,
    };
  }, [onError]);

  setContext(context);

  return (
    <AgoyAppClientContext.Provider value={context}>
      {children}
    </AgoyAppClientContext.Provider>
  );
};

export default AgoyAppClientContext;

import { Typography, TextField as MuiTextField } from '@material-ui/core';
import styled from '@emotion/styled';

export const LoginBox = styled.form`
  width: 55%;
  > * {
    margin: 0.5rem 0;
  }
`;

export const TextField = styled(MuiTextField)`
  width: 100%;
`;

export const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin: ${(props) => props.theme.spacing(2)}px 14px;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  > * {
    margin-left: 1rem;
  }
`;

export const UnderlineLink = styled(Typography)`
  text-decoration: underline;
  cursor: pointer;
`;

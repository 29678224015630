import React from 'react';
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';
import { messages } from '@agoy/common';

const cache = createIntlCache();
export const intl = createIntl(
  {
    locale: 'sv',
    messages: messages.sv,
    onError: (err) => {
      // silents errors when TaxCalculation transalations fall back to defaultMessage
      if (err.code !== 'MISSING_TRANSLATION') {
        console.error(err);
      }
    },
    defaultRichTextElements: {
      b: (chunks) => <b>{chunks}</b>,
      br: () => <br />,
      p: (chunks) => <p>{chunks}</p>,
    },
  },
  cache
);

const withIntl = (Component) => (props) => (
  <RawIntlProvider value={intl}>
    <Component {...props} />
  </RawIntlProvider>
);

export default withIntl;

import React, { useCallback, useEffect, useMemo } from 'react';
import {
  AppBar as MuiAppBar,
  Divider as MuiDivider,
  Toolbar as MuiToolbar,
  Typography,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useSelector } from 'redux/reducers';
import styled from '@emotion/styled';
// @ts-ignore
import { ReactComponent as FinancialReportIcon } from 'assets/program/FinancialReportIcon.svg';

import { formatFinancialYear } from '@agoy/common';
import { fetchProgramStatus } from '_shared/redux/actions';
import { currentPeriods } from '_clients/redux/customer-view/selectors';
import Route from '_shared/components/Route';
import CustomerButton from '../CustomerSubMenu/CustomerButton';
import SavingStatusButton from '../SavingStatusButton';
import PeriodTimeline from '../TimelinePeriodSelector';
import useHistory from '../../../../hooks/useHistory';

const AppBar = styled(MuiAppBar)`
  background: ${({ theme }) => theme.palette.grey[200]};
`;

const Toolbar = styled(MuiToolbar)`
  display: flex;
  justify-content: space-between;
  padding-top: ${({ theme }) => theme.spacing(1)}px;
  padding-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

const Grouper = styled.div`
  display: flex;
  align-items: center;
`;

const Divider = styled(MuiDivider)`
  margin: ${({ theme }) => theme.spacing(1 / 2, 2, 1 / 2, 2)};
`;

export const FinancialReportSubMenu = (): JSX.Element | null => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { currentFinancialYear } = useSelector((state) => state.customerView);
  const clientId = useSelector((state) => state.customerView.currentCustomer);
  const client = useSelector((state) =>
    clientId ? state.customers[clientId] : undefined
  );

  const financialReportStatus = useSelector((state) => {
    return clientId
      ? state.financialReport.clients[clientId]?.periodStatus
      : undefined;
  });

  useEffect(() => {
    if (clientId) {
      dispatch(fetchProgramStatus(clientId, 'FIN_STATEMENT_REPORT'));
    }
  }, [dispatch, clientId]);

  const periods = useSelector(currentPeriods);

  const statusPeriods = useMemo(() => {
    return periods?.map((p) => ({
      ...p,
      status: financialReportStatus?.[p.id]?.status ?? 'NOT_STARTED',
    }));
  }, [financialReportStatus, periods]);

  const handleClick = useCallback(() => {
    history.push(`/clients/${clientId}/`);
  }, [history, clientId]);

  if (!client || !clientId) return null;
  return (
    <AppBar position="sticky" elevation={2}>
      <Toolbar>
        {client && (
          <Route path="/clients/:clientId/:financialYear/:program">
            <Grouper>
              <CustomerButton
                logoUrl={client.logo}
                name={client.name}
                handleClick={handleClick}
              />
              <Divider orientation="vertical" flexItem />
              {statusPeriods && (
                <PeriodTimeline
                  program="FIN_STATEMENT_REPORT"
                  programPeriods={statusPeriods}
                  icon={<FinancialReportIcon />}
                />
              )}
            </Grouper>
            <div style={{ flex: 1 }} />
            <>
              <Typography
                color="textPrimary"
                style={{ fontWeight: 500, fontSize: '1.25rem' }}
              >
                {currentFinancialYear &&
                  formatFinancialYear(currentFinancialYear)}
              </Typography>
              <Divider orientation="vertical" flexItem />
            </>
            <SavingStatusButton />
          </Route>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default FinancialReportSubMenu;

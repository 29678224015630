import React from 'react';
import { css, Global } from '@emotion/react';

const withCssResets = (Component) => (props) => (
  <>
    <Global
      styles={css`
        html {
          height: 100vh;
          overflow: hidden;
        }

        body {
          height: 100vh;
          width: min-content;
          min-width: 100%;
        }

        #root {
          height: 100vh;
          display: flex;
          justify-content: flex-start;
          flex-direction: column;
        }
      `}
    />
    <Component {...props} />
  </>
);

export default withCssResets;

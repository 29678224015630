import React, { useContext, useEffect, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { batch, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { format, parse } from 'date-fns';

import { TimePeriod } from '@agoy/document';
import { addGlobalErrorMessage, addGlobalMessage } from 'redux/actions';
import { useSelector } from 'redux/reducers';
import useClientDataLayer from 'data/client/useClientDataLayer';
import {
  LockedPeriodError,
  SieImportError,
} from 'data/client/accountingBalances/types';
import { addProgramPeriodStatus } from '_reconciliation/redux/accounting-view/actions';
import { Period } from '@agoy/api-sdk-core';

import { ACCOUNT_PERIOD_DATE_FORMAT } from 'types/Accounting';
import { setProgramStatus } from '_shared/components/StatusSelector/StatusSelector';
import { fetchProgramStatus } from '_shared/redux/actions';
import { PeriodStatus } from '_shared/types';

import { NotificationContext } from './NotificationsContext';
import LockedPeriodErrorDialog from './LockedPeriodErrorDialog';
import config from '../config';
import { FORTNOX_ROUTE_PREFIX } from '../../../contants';

const translateSieImportError = (
  formatMessage: IntlShape['formatMessage'],
  error: SieImportError
): string | null => {
  const { errorCode, errorDetails } = error;

  switch (errorCode) {
    case 'SIE_IMPORT_FAILED_DUPLICATE_FINANCIAL_YEAR':
      // This error can happen when two parallel imports are running. And the
      // last to commit fails. Skip the error message in this case.
      return null;
    case 'SIE_IMPORT_ILLEGAL_ACCOUNT_NUMBER':
    case 'SIE_IMPORT_OVERLAPPING_FIN_YEAR':
    case 'SIE_IMPORT_FAILED':
      return formatMessage(
        { id: `error.sie-import.${errorCode}` },
        Array.isArray(errorDetails) ? errorDetails[0] : errorDetails
      );
    case 'SIE_IMPORT_CONTENT_ERROR':
      return formatMessage(
        { id: `error.sie-import.${errorCode}` },
        { errorCount: Array.isArray(errorDetails) && errorDetails.length }
      );
    case 'SIE_IMPORT_CHANGE_IN_LOCKED_PERIOD':
      // Temporary log of the error until a future story shows a proper message

      console.error('Locked period', error.errorDetails);
      return formatMessage({ id: 'error' });

    default:
      console.error(
        'Unmapped sie-import error message',
        errorCode,
        Array.isArray(errorDetails) ? errorDetails[0] : errorDetails
      );
      return formatMessage({ id: 'error' });
  }
};

const translateSieImportWarning = (
  formatMessage: IntlShape['formatMessage'],
  type: string,
  details: Record<string, string | number>
): string => {
  switch (type) {
    case 'DUPLICATE_OBJECTS':
    case 'MISSING_ACCOUNT':
      return formatMessage({ id: `warning.sie-import.${type}` }, details);
    default:
      console.error('Unmapped sie-import warning message', type, details);
      return formatMessage({ id: 'error' });
  }
};

/**
 * HOC to listen for notifications of sie-import.
 *
 * This will be apply to two scenarios.
 *
 * 1. Creating a new client, we haven't selected a financial year then and
 *    so we listen for any financial year and use the routes clientId.
 *
 * 2. Working with a client in a product view, we have show only notications for
 *    the selected financial year.
 *
 * This component handles updating the accounting balances upon a successful import.
 * Any warnings or error are dispatch as global messages.
 *
 * @param Component Wrapped component
 * @returns
 */
const withSieImportListener = (Component) => (props) => {
  const notificationService = useContext(NotificationContext);
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const routePrefix = config.isFortnoxCloud ? FORTNOX_ROUTE_PREFIX : '';
  const match = useRouteMatch<{ clientId: string }>({
    path: `${routePrefix}/clients/:clientId`,
  });
  const clientIdFromRoute = match?.params.clientId;

  const [loading, setLoading] = useState(false);
  const [lockedPeriodErrors, setLockedPeriodErrors] = useState<
    LockedPeriodError['errorDetails'] | null
  >(null);

  const {
    currentFinancialYearId,
    currentFinancialYear,
    currentCustomer: currentClient,
  } = useSelector((state) => state.customerView);

  const user = useSelector((state) => state.user);

  const clientDataLayer = useClientDataLayer(currentClient);

  const clientInfo = useSelector((state) =>
    currentClient ? state.customers[currentClient] : undefined
  );

  useEffect(() => {
    if (clientIdFromRoute && notificationService) {
      const subscription = notificationService.subscribe(
        { clientId: clientIdFromRoute, topic: 'sie-import' },
        (notification) => {
          if (notification.ok) {
            const msg = notification.val;

            // Manage the warnings
            // if we have a currentFinancialYearId match with that for "completed"
            // otherwise always show the warnings
            if (
              msg.topic === 'sie-import' &&
              msg.status === 'completed' &&
              msg.warnings.length > 0 &&
              (currentFinancialYearId === undefined ||
                (msg.financialYearId === currentFinancialYearId &&
                  msg.clientId === currentClient))
            ) {
              batch(() => {
                msg.warnings.forEach((warning) => {
                  dispatch(
                    addGlobalMessage(
                      'warning',
                      undefined,
                      translateSieImportWarning(
                        formatMessage,
                        warning.type,
                        warning.details
                      )
                    )
                  );
                });
              });
            }

            if (
              msg.topic === 'sie-import' &&
              msg.status === 'ignored' &&
              (currentFinancialYearId === undefined ||
                (msg.financialYearId === currentFinancialYearId &&
                  msg.clientId === currentClient))
            ) {
              if (msg.reason === 'other_import_in_progress') {
                dispatch(
                  addGlobalMessage(
                    'info',
                    undefined,
                    'Import av SIE-fil pågår redan'
                  )
                );
              } else {
                dispatch(
                  addGlobalMessage('success', 'sie.import.successful.already')
                );
              }
            }

            // Manage the errors
            if (msg.topic === 'sie-import' && msg.status === 'failed') {
              if (msg.errorCode === 'SIE_IMPORT_CHANGE_IN_LOCKED_PERIOD') {
                setLockedPeriodErrors(msg.errorDetails);
                return;
              }

              const message = translateSieImportError(formatMessage, msg);

              if (message !== null) {
                dispatch(addGlobalMessage('error', undefined, message));
              }
            }
          }
        }
      );
      return () => {
        notificationService.unsubscribe(subscription);
      };
    }
  }, [
    notificationService,
    dispatch,
    formatMessage,
    clientIdFromRoute,
    currentClient,
    currentFinancialYearId,
  ]);

  const handleUnlock = async (period: Period, reason: string) => {
    if (!currentClient || !currentFinancialYear || !clientInfo) {
      return;
    }

    setLoading(true);

    const periodStart = parse(
      period.start,
      ACCOUNT_PERIOD_DATE_FORMAT,
      Date.now()
    );
    const periodEnd = parse(period.end, ACCOUNT_PERIOD_DATE_FORMAT, Date.now());

    const interval = `${format(periodStart, 'yyyyMMdd')}-${format(
      periodEnd,
      'yyyyMMdd'
    )}`;

    const result = await setProgramStatus(
      currentClient,
      'RECONCILIATION',
      'period',
      interval,
      'DONE',
      formatMessage,
      true,
      reason
    );

    if (result.err) {
      if (typeof result.val === 'string') {
        dispatch(addGlobalErrorMessage(undefined, result.val));
      } else {
        dispatch(addGlobalErrorMessage(undefined, 'error'));
      }
    } else {
      const newStatus: PeriodStatus = {
        period: format(periodStart, 'yyyyMMdd'),
        periodId: period.id,
        status: 'DONE',
        previousStatus: null,
        reason,
        createdAt: new Date().toISOString(),
        createdBy: `${user.givenName} ${user.familyName}`,
      };

      dispatch(addProgramPeriodStatus(currentClient, newStatus));
      dispatch(fetchProgramStatus(currentClient, 'RECONCILIATION'));

      const financialYear = clientInfo.rawFinancialYears.find((fy) =>
        fy.periods?.find((p) => p.id === period.id)
      );
      if (financialYear) {
        const retryResult =
          await clientDataLayer?.accountingBalances.retryImport(
            TimePeriod.fromISODates(financialYear).value,
            financialYear.id
          );
        if (retryResult?.err) {
          console.error(retryResult.val);
          dispatch(addGlobalErrorMessage('error'));
        }
      }
    }
    setLockedPeriodErrors(null);
    setLoading(false);
  };

  const handleClose = () => {
    setLockedPeriodErrors(null);
  };

  return (
    <>
      {lockedPeriodErrors && currentClient && (
        <LockedPeriodErrorDialog
          clientId={currentClient}
          lockedPeriodErrors={lockedPeriodErrors}
          onSubmit={handleUnlock}
          onClose={handleClose}
          loading={loading}
        />
      )}

      <Component {...props} />
    </>
  );
};

export default withSieImportListener;

import React from 'react';
import { useSelector } from 'redux/reducers';
import UnpaidPage from '_payment/components/pages/Unpaid';
import useHistory from '_shared/hooks/useHistory';

const Container = () => {
  const history = useHistory();

  const isAdmin =
    useSelector((state) => state.user.roles)?.includes('OrganisationAdmin') ||
    false;

  const handleTriggerPay = async () => {
    history.push(`/payment/checkout`);
  };

  return <UnpaidPage isAdmin={isAdmin} handleTriggerPay={handleTriggerPay} />;
};

export default Container;

import * as t from 'io-ts';

export const UserInputChangedCodec = t.type({
  clientId: t.string,
  topic: t.literal('user-input-changed'),
  information: t.array(
    t.union([
      t.literal('overview'), // Information that affects the reconciliation icon or status has changed
      t.literal('specification'),
      t.literal('periodDocument'),
      t.literal('comment'),
      t.literal('userInput'), // Ignore changes to inventoryView and cashView
      t.literal('actualBalance'),
    ])
  ),
  accounts: t.array(t.number),
  groups: t.array(t.string),
  period: t.type({
    id: t.number,
    start: t.string,
    end: t.string,
  }),
  userId: t.string,
});

export type UserInputChanged = t.TypeOf<typeof UserInputChangedCodec>;

import React, { useState, useEffect, Suspense } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import Skeleton from '@material-ui/lab/Skeleton';
import { useIntl } from 'react-intl';

import { useSelector } from 'redux/reducers';
import {
  addGlobalErrorMessage,
  addGlobalMessage,
  resetClients,
  getClients as getReduxClients,
} from 'redux/actions';
import { asResultClass, useApiSdk } from 'api-sdk';
import ClientsList from '_clients/components/organisms/ClientsList';
import { mapClient } from '_clients/services/mappings';
import ModalEditClient from '_clients/components/organisms/ModalEditClient';
import type { ClientCompanyType } from '_clients/types/types';
import { calculateAuthorisedMembers } from '_organization/components/organisms/helpers';
import useHistory from '_shared/hooks/useHistory';

const Wrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const ClientsContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { formatMessage } = useIntl();
  const {
    getClients,
    getClientsUsers,
    getOrganisationMembers,
    authorizeClientForUser,
    deleteAuthorizedClientsForUser,
    updateClientById,
    deleteClientById,
  } = useApiSdk();

  const [loading, setLoading] = useState({
    clients: false,
    members: false,
    access: false,
  });

  const [clients, setClients] = useState<{
    [key: string]: ClientCompanyType;
  }>({});
  const [members, setMembers] = useState<Member.Members>({});
  const [selectedClientId, setSelectedClientId] = useState<string>('');
  const [selectedClient, setSelectedClient] = useState<ClientCompanyType>();
  const [isEditClientOpen, setIsEditClientOpen] = useState<boolean>(false);

  const isAdmin =
    useSelector((state) => state.user.roles)?.includes('OrganisationAdmin') ||
    false;

  // API
  const fetchClients = async () => {
    setLoading((l) => ({ ...l, clients: true }));

    try {
      const clientsResult = await asResultClass(getClients({}));

      if (clientsResult.ok) {
        setClients(
          clientsResult.val.map(mapClient).reduce((acc, current) => {
            acc[current.id] = current;
            return acc;
          }, {})
        );
      } else {
        throw new Error('Failed to load clients');
      }
    } finally {
      setLoading((l) => ({ ...l, clients: false }));
    }

    // Legacy, needed as other parts still depend on the client Redux state
    dispatch(resetClients());
    dispatch(getReduxClients());
  };

  const fetchMembers = async (clientId: string) => {
    setLoading((l) => ({ ...l, members: true }));

    try {
      const requestMembers = await asResultClass(getOrganisationMembers());

      const requestMembersForClient = clientId
        ? await getClientsUsers({ clientid: clientId })
        : Promise.resolve([]);

      const [responseMembers, responseMembersForClient] = await Promise.all([
        requestMembers,
        requestMembersForClient,
      ]);

      const authorizedMembers = calculateAuthorisedMembers(
        responseMembers.val as Member.MemberType[],
        responseMembersForClient as Member.MemberType[]
      );

      setMembers(authorizedMembers);
    } finally {
      setLoading((l) => ({ ...l, members: false }));
    }
  };

  // EFFECTS
  useEffect(() => {
    fetchClients();
    fetchMembers('');
  }, []);

  useEffect(() => {
    setSelectedClient(clients[selectedClientId]);
  }, [members, selectedClientId, clients]);

  const handleSelectClient = async (clientId: string) => {
    await fetchMembers(clientId);
    setSelectedClientId(clientId);
    setIsEditClientOpen(true);
  };

  const handleToggleMemberAuthorisation = async (member: Member.MemberType) => {
    if (!selectedClient) return;

    const isThisUserAdmin = member.roles?.includes('OrganisationAdmin');
    const memberEmail = member.email;

    if (!isAdmin) {
      dispatch(addGlobalMessage('error', 'Only admins can edit permissions'));
      return;
    }

    if (isThisUserAdmin && member.isAuthorisedEditedValue) {
      dispatch(
        addGlobalMessage(
          'error',
          formatMessage({
            id: 'dashboard.members.edit.cannotRemoveAdmin',
          })
        )
      );
      return;
    }

    try {
      setLoading((l) => ({ ...l, access: true }));

      if (member.isAuthorisedEditedValue) {
        await deleteAuthorizedClientsForUser({
          userId: member.userId,
          clientId: selectedClientId,
        });
      } else {
        await authorizeClientForUser({
          userId: member.userId,
          clientId: selectedClientId,
        });
      }

      await fetchMembers(selectedClient.id);
    } catch (e) {
      const message = formatMessage({
        id: members[memberEmail].isAuthorisedEditedValue
          ? 'dashboard.customers.edit.grantAccessFailed'
          : 'dashboard.customers.edit.removeAccessFailed',
      });
      dispatch(addGlobalMessage('error', message));
    } finally {
      setLoading((l) => ({ ...l, access: false }));
    }
  };

  const handleUpdateClient = async (updatedClient) => {
    if (!selectedClient) return;

    const result = await asResultClass(
      updateClientById({
        clientid: selectedClient.id,
        requestBody: updatedClient,
      })
    );

    if (result.ok) {
      await fetchClients();
    } else {
      throw Error(result.val.message);
    }
  };

  const handleShowFortnoxIntegration = () => {
    if (!selectedClient) return;

    history.push(`/clients/${selectedClient.id}/integrations`);
  };

  const handleDeleteClient = async () => {
    if (!selectedClient) return;

    try {
      const result = await asResultClass(
        deleteClientById({ clientid: selectedClient.id })
      );

      if (result.ok) {
        await fetchClients();
        // Once the client is deleted, display the success message and close both dialog + client modal
        dispatch(addGlobalMessage('success', 'client.deleted.success'));
        setIsEditClientOpen(false);
      } else {
        throw Error(result.val.message);
      }
    } catch (e) {
      dispatch(addGlobalErrorMessage('error', 'removeCusterErrorMessage'));
    }
  };

  return (
    <Wrapper>
      <Suspense
        fallback={
          <Skeleton
            variant="rect"
            animation="wave"
            width="100%"
            height="40px"
            component="div"
          />
        }
      >
        {isEditClientOpen && selectedClient && (
          <ModalEditClient
            isAdmin={isAdmin}
            isUpdatingAccess={loading.access}
            members={members}
            client={selectedClient}
            handleClose={() => setIsEditClientOpen(false)}
            handleToggleMemberAuthorisation={handleToggleMemberAuthorisation}
            handleUpdateClient={handleUpdateClient}
            handleShowFortnoxIntegration={handleShowFortnoxIntegration}
            handleDeleteClient={handleDeleteClient}
          />
        )}
      </Suspense>
      <ClientsList
        isAdmin={isAdmin}
        clients={clients}
        handleSelectClient={handleSelectClient}
        isLoading={loading}
      />
    </Wrapper>
  );
};

export default ClientsContainer;

import { AccountingAccount } from '@agoy/api-sdk-core';
import { AccountingBalances, AccountingPeriod } from 'types/Accounting';
import { LampValue } from '_clients/types/types';
import { UserInput } from '_reconciliation/types';

const accountGroupAttributes = [
  'a15xx',
  'a19xx',
  'a24xx',
  'a1630',
  'rest',
  'moms',
] as const;

export type AccountGroupAttribute = (typeof accountGroupAttributes)[number];

export type AccountGroup = Record<AccountGroupAttribute, LampValue>;

export type AccountGrouperFunction = (
  period: AccountingPeriod,
  accountingBalances: AccountingBalances,
  userInput: UserInput,
  previousAccountGroup?: AccountGroup,
  onMarkAsDone?: (account: AccountingAccount, balance: number) => void
) => AccountGroup;

type GetGroupStatusType = (group: AccountGroup) => LampValue;

/**
 *
 * @param group
 * @returns
 */
export const getGroupStatus: GetGroupStatusType = (group) => {
  const existingStatuses: LampValue[] = Object.values(group).filter(
    (value) => value !== 'doesNotExist'
  );

  if (existingStatuses.includes('wrong')) {
    return 'wrong';
  }

  if (
    existingStatuses.every((status) => status === 'done') &&
    existingStatuses.length > 0
  ) {
    return 'done';
  }

  if (existingStatuses.every((status) => status === 'unvisited')) {
    return 'unvisited';
  }

  return 'started';
};

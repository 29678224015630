import { useCallback, useState } from 'react';
import { asResultClass, getApiSdk } from 'api-sdk';
import { RootState, useSelector } from 'redux/reducers';
import formatYearVariable from 'utils/document-util';
import { getContext } from 'utils/AgoyAppClient/contextHolder';

type Sdk = Awaited<ReturnType<typeof getApiSdk>>;
type ClientFiles = Awaited<ReturnType<Sdk['getClientDocuments']>>;

export const useYearDocuments = (initialCategory?: string) => {
  const sdk = getApiSdk(getContext());

  const { currentCustomer: clientId, currentFinancialYear: selectedYear } =
    useSelector((state: RootState) => state.customerView);

  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState(initialCategory);
  const [uploading, setUploading] = useState(false);
  const [postDocumentError, setPostDocumentError] = useState(false);
  const [documents, setDocuments] = useState<ClientFiles>({
    listDocuments: [],
  });

  const fetchClientDocuments = useCallback(
    async ({
      clientId,
      selectedYear,
    }: {
      clientId: string;
      selectedYear: string;
    }) => {
      setLoading(true);

      const clientDocumentsResult = await asResultClass(
        sdk.getClientDocuments({
          clientid: clientId,
          year: formatYearVariable(selectedYear),
          category,
        })
      );

      setLoading(false);

      if (clientDocumentsResult.ok) {
        setDocuments(clientDocumentsResult.val);
        return clientDocumentsResult.val;
      }

      return { listDocuments: [] };
    },
    []
  );

  const uploadFile = useCallback(
    async (file: File, category?: string) => {
      if (!selectedYear) {
        console.warn('No selected year to upload file for');
        return;
      }

      if (!clientId) {
        console.warn('No client id to upload file for');
        return;
      }

      setUploading(true);

      try {
        const result = await asResultClass(
          sdk.putClientDocument({
            clientid: clientId,
            year: formatYearVariable(selectedYear),
            category: category ?? 'other',
            name: file.name,
            requestBody: file,
            program: 'FIN_STATEMENT',
            section: 'DOCUMENTS',
          })
        );
        if (result.ok) {
          fetchClientDocuments({ clientId, selectedYear });
          setPostDocumentError(false);
        } else {
          setPostDocumentError(true);
        }
      } catch {
        setPostDocumentError(true);
      } finally {
        setUploading(false);
      }
    },
    [selectedYear, clientId, fetchClientDocuments]
  );

  const refetchDocuments = useCallback(async () => {
    if (!clientId) {
      console.warn('No client id to upload file for');
      return;
    }

    setLoading(true);

    await fetchClientDocuments({
      clientId,
      selectedYear: selectedYear!,
    });

    setLoading(false);
  }, [selectedYear, clientId, fetchClientDocuments]);

  const deleteYearDocument = useCallback(
    async (name, category) => {
      const formattedYear = formatYearVariable(selectedYear!);
      const sdk = getApiSdk(getContext());
      const result = await asResultClass(
        sdk.deleteClientDocument({
          clientid: clientId!,
          year: formattedYear,
          category,
          name,
          program: 'FIN_STATEMENT',
          section: 'DOCUMENTS',
        })
      );
    },
    [clientId, selectedYear]
  );

  return {
    uploadFile,
    documents,
    postDocumentError,
    uploading,
    loading,
    fetchClientDocuments,
    refetchDocuments,
    deleteYearDocument,
    setCategory,
    category,
  };
};

export default useYearDocuments;

import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { Typography, Paper as MuiPaper, Tab } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';
import Route from '_shared/components/Route';

import CardTabPanel from '_organization/components/organisms/CardTabPanel';
import { CardTabs } from '_organization/components/organisms/CardTabs';
import config from '_shared/services/config';
import Link from '_shared/components/Link';

const Background = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.palette.secondary.contrastText};
  overflow: auto;
`;

const Paper = styled(MuiPaper)`
  border-top: none;
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
`;

const MainWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin: 2rem 2rem 0.5rem;
`;

const TextWrapper = styled.div`
  display: block;
`;

const StyledInfo = styled(Typography)`
  font-weight: 700;
`;

const StyledTabPanel = styled(CardTabPanel)`
  background-color: ${(props) => props.theme.palette.grey[100]};
  flex: 1;
  min-height: 0;
  overflow: auto;
`;

const StyledCardTabs = styled(CardTabs)`
  .MuiPaper-root {
    border: none;
  }
`;

type TabItem = {
  index: number;
  baseRoute: string;
  href: string;
  labelId: string;
  hideInFortnoxMode?: boolean;
  hideIfPaymentDetailsDisabled?: boolean;
};

const tabs: TabItem[] = [
  {
    index: 0,
    baseRoute: 'general',
    href: '/organisation/general',
    labelId: 'organisation.general_settings',
  },
  {
    index: 1,
    baseRoute: 'members',
    href: '/organisation/members',
    labelId: 'dashboard.members.members',
    hideInFortnoxMode: true,
  },
  {
    index: 2,
    baseRoute: 'clients',
    href: '/organisation/clients',
    labelId: 'dashboard.customers.heading',
    hideInFortnoxMode: true,
  },
  {
    index: 3,
    baseRoute: 'payment-details',
    href: '/organisation/payment-details',
    labelId: 'dashboard.invoicing.subscription',
    hideIfPaymentDetailsDisabled: true,
  },
  {
    index: 4,
    baseRoute: 'licenses',
    href: '/organisation/licenses',
    labelId: 'dashboard.licenses.title',
    hideIfPaymentDetailsDisabled: true,
  },
  {
    index: 5,
    baseRoute: 'checklists',
    href: '/organisation/checklists',
    labelId: 'checklist.list.title',
  },
  {
    index: 6,
    baseRoute: 'settings',
    href: '/organisation/settings/reconciliations',
    labelId: 'organisation.settings.title',
  },
];

type PageProps = {
  isAdmin: boolean;
  isFortnoxMode: boolean;
  GeneralComponent: React.ReactNode;
  SettingsComponent: React.ReactNode;
  MembersComponent: React.ReactNode;
  ClientsComponent: React.ReactNode;
  PaymentDetailsComponent: React.ReactNode;
  LicensesComponent: React.ReactNode;
  ChecklistComponent: React.ReactNode;
};

const Page = ({
  isAdmin,
  isFortnoxMode,
  GeneralComponent,
  SettingsComponent,
  MembersComponent,
  ClientsComponent,
  PaymentDetailsComponent,
  LicensesComponent,
  ChecklistComponent,
}: PageProps) => {
  const { formatMessage } = useIntl();
  const { path } = useRouteMatch();

  const tabIndex = useMemo(() => {
    let index = 0;

    tabs.forEach((tab) => {
      if (path.includes(tab.baseRoute)) {
        index = tab.index;
      }
    });

    return index;
  }, [path]);

  const [tabValue, setTabValue] = React.useState(tabIndex);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const isPaymentDetailsEnabled = isAdmin && config.whiteLabel !== 'fortnox';

  return (
    <Background>
      <MainWrapper>
        <TextWrapper>
          <StyledInfo color="primary" gutterBottom>
            {formatMessage({ id: 'organisation' })}
          </StyledInfo>
        </TextWrapper>
      </MainWrapper>

      <Paper variant="elevation" elevation={0}>
        <StyledCardTabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="secondary"
        >
          {tabs.map((tab) => {
            if (
              (isFortnoxMode && tab.hideInFortnoxMode) ||
              (!isPaymentDetailsEnabled && tab.hideIfPaymentDetailsDisabled)
            )
              return null;

            return (
              <Tab
                key={tab.index}
                label={formatMessage({ id: tab.labelId })}
                component={Link}
                to={tab.href}
                value={tab.index}
              />
            );
          })}
        </StyledCardTabs>

        <StyledTabPanel name="general" value={tabValue} index={0}>
          <Route path="/organisation/general" exact>
            {GeneralComponent}
          </Route>
        </StyledTabPanel>

        {!isFortnoxMode && (
          <StyledTabPanel name="members" value={tabValue} index={1}>
            <Route path="/organisation/members" exact>
              {MembersComponent}
            </Route>
          </StyledTabPanel>
        )}

        {!isFortnoxMode && (
          <StyledTabPanel name="clients" value={tabValue} index={2}>
            <Route path="/organisation/clients" exact>
              {ClientsComponent}
            </Route>
          </StyledTabPanel>
        )}

        {isPaymentDetailsEnabled && (
          <StyledTabPanel name="payment-details" value={tabValue} index={3}>
            <Route path="/organisation/payment-details" exact>
              {PaymentDetailsComponent}
            </Route>
          </StyledTabPanel>
        )}

        {isPaymentDetailsEnabled && (
          <StyledTabPanel name="licenses" value={tabValue} index={4}>
            <Route path="/organisation/licenses" exact>
              {LicensesComponent}
            </Route>
          </StyledTabPanel>
        )}

        <StyledTabPanel name="checklists" value={tabValue} index={5}>
          <Route path="/organisation/checklists">{ChecklistComponent}</Route>
        </StyledTabPanel>

        <StyledTabPanel
          name="settings"
          value={tabValue}
          index={6}
          withoutPadding
        >
          <Route path="/organisation/settings">{SettingsComponent}</Route>
        </StyledTabPanel>
      </Paper>
    </Background>
  );
};

export default Page;

import config from '_shared/services/config';
import { FORTNOX_ROUTE_PREFIX } from '../contants';

/**
 * Apply Fortnox path structure to the path when running in Fortnox Cloud.
 *
 * @param fid
 * @param path
 */
// eslint-disable-next-line import/prefer-default-export
export const resolvePath = (fid: string | undefined, path: string): string => {
  if (config.isFortnoxCloud && fid) {
    return `${FORTNOX_ROUTE_PREFIX.replace(':fid', fid)}${path}`;
  }
  return path;
};

export * from './sie-import';
export * from './ws-notifications';
export * from './fortnox-invoices';
export * from './document-values';
export * from './skatteverket-sync';
export * from './accounting-balances-changed';
export * from './user-input-changed';
export * from './client-lamps-changed';
export * from './program-status-changed';
import { AccountingBalancesChangedCodec } from './accounting-balances-changed';
import { UserInputChangedCodec } from './user-input-changed';
import * as t from 'io-ts';
import { parseMessage } from './common';

export { parseMessage } from './common';

const UpdateClientLampsCodec = t.union([AccountingBalancesChangedCodec, UserInputChangedCodec]);

export const parseUpdateClientLampsMessage = (json: string) => parseMessage(UpdateClientLampsCodec, json);
export type UpdateClientLampsMessage = t.TypeOf<typeof UpdateClientLampsCodec>;
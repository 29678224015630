export const MAX_NUM_DOCUMENTS = 24;

export const DraggableItemTypes = {
  BALANCE_SHEET_ACCOUNT_ROW: 'BALANCE_SHEET_ACCOUNT_ROW',
  HIERARCHICAL_ACCOUNT_ROW: 'HIERARCHICAL_ACCOUNT_ROW',
  MANAGEMENT_REPORT_ROW: 'MANAGEMENT_REPORT_ROW',
  MANAGEMENT_REPORT_COLUMN: 'MANAGEMENT_REPORT_COLUMN',
  CHECKLIST_ITEM: 'CHECKLIST_ITEM',
  CHECKLIST_GROUP: 'CHECKLIST_GROUP',
  RECONCILIATION_ACCOUNT_ROW: 'RECONCILIATION_ACCOUNT_ROW',
  SPECIFICATION_ROW: 'SPECIFICATION_ROW',
};

export const IMAGE_SIZE_LIMIT = 2000000;
export const IMAGE_MIN_WIDTH_LIMIT = 1000;
export const IMAGE_MAX_WIDTH_LIMIT = 1600;
export const IMAGE_MAX_HEIGHT_LIMIT = 1200;

export const editFinYearFortnoxUrl =
  'https://support.fortnox.se/installningar/installningar-rakenskapsar-bf#redigera-eller-radera-ett-rkenskapsr-2';

export const FORTNOX_ROUTE_PREFIX = '/agoy/:fid';

import packageJson from '../../../package.json';

interface AppConfig {
  userPoolId: string;
  clientId: string;
  region: string;
  identityPoolId: string;
  userPoolEndpoint: string;
  stripeAPIKey: string;
  appEndpoint: string;
  fortnoxClientId: string;
  appCommitId: string;
  appVersion: string;
  runningEnvironment: string;
  whiteLabel?: 'fortnox';
  whiteLabelUI?: 'fortnox';
  isFortnoxCloud?: boolean;
  wsEndpoint: string;
  froalaKey: string;
  piwikKey: string;
}

const StorybookConfig = {
  userPoolId: '',
  clientId: '',
  region: '',
  identityPoolId: '',
  userPoolEndpoint: '',
  stripeAPIKey: '',
  appEndpoint: '',
  fortnoxClientId: '',
  appCommitId: '',
  appVersion: '',
  runningEnvironment: '',
  wsEndpoint: '',
  whiteLabelUI: undefined,
  froalaKey: '',
  piwikKey: '',
};

const getConfig = (): AppConfig => {
  const externalConfigEl = document.getElementById('config');

  if (externalConfigEl === null) {
    throw new Error('No config el found');
  }

  return JSON.parse(externalConfigEl.innerText);
};

export const initConfig = (importMetaEnv: ImportMetaEnv): AppConfig => {
  const {
    VITE_READ_JSON_CONFIG,
    STORYBOOK,
    VITE_USER_POOL_ID,
    VITE_CLIENT_ID,
    VITE_REGION,
    VITE_IDENTITY_POOL_ID,
    VITE_USER_POOL_ENDPOINT,
    VITE_STRIPE_API_KEY,
    VITE_APP_ENDPOINT,
    VITE_FORTNOX_CLIENT_ID,
    VITE_COMMIT_ID,
    VITE_RUNNING_ENVIRONMENT,
    VITE_WHITE_LABEL,
    VITE_WHITE_LABEL_UI,
    VITE_WS_ENDPOINT,
    VITE_FROALA_KEY,
    VITE_PIWIK_KEY,
    VITE_FORTNOX_CLOUD_MODE,
  } = importMetaEnv;

  if (STORYBOOK) return StorybookConfig;

  let result: AppConfig = {
    userPoolId: VITE_USER_POOL_ID,
    clientId: VITE_CLIENT_ID,
    region: VITE_REGION,
    identityPoolId: VITE_IDENTITY_POOL_ID,
    userPoolEndpoint: VITE_USER_POOL_ENDPOINT,
    stripeAPIKey: VITE_STRIPE_API_KEY,
    appEndpoint: VITE_APP_ENDPOINT,
    fortnoxClientId: VITE_FORTNOX_CLIENT_ID || 'kvKaTu9TnEM4',
    appCommitId: VITE_COMMIT_ID || 'undefined-id',
    appVersion: packageJson.version,
    runningEnvironment: VITE_RUNNING_ENVIRONMENT || 'develop',
    whiteLabel: VITE_WHITE_LABEL === 'fortnox' ? 'fortnox' : undefined,
    whiteLabelUI:
      VITE_WHITE_LABEL_UI === 'fortnox' || VITE_WHITE_LABEL === 'fortnox'
        ? 'fortnox'
        : undefined,
    isFortnoxCloud: VITE_FORTNOX_CLOUD_MODE === 'true',
    wsEndpoint: VITE_WS_ENDPOINT || '',
    froalaKey:
      VITE_FROALA_KEY ||
      'dKA5cC3A2B1B2H2B6B1D-17d1F1FOOLb2KOPQGe1CWCQVTDWXGcA5A4D4B2E4C2B2E3C1A3==',
    piwikKey: VITE_PIWIK_KEY || '',
  };

  if (VITE_READ_JSON_CONFIG === 'true') {
    result = { ...result, ...getConfig() };
  }

  if (
    result.userPoolId === undefined ||
    result.clientId === undefined ||
    result.region === undefined ||
    result.identityPoolId === undefined ||
    result.userPoolEndpoint === undefined ||
    result.stripeAPIKey === undefined ||
    result.appEndpoint === undefined ||
    packageJson.version === undefined
  ) {
    throw new Error('Config missing');
  }

  return result;
};

export default initConfig(import.meta.env);

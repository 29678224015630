import React, { useCallback, useEffect, useState } from 'react';
import { asResultClass, useApiSdk } from 'api-sdk';

type AnnualReportContextType = {
  version: '1' | '2';
  isLocked: boolean | null;
};

type AnnualReportContextProviderProps = React.PropsWithChildren<{
  clientId: string;
  financialYear: string;
}>;

const AnnualReportContext = React.createContext<AnnualReportContextType>({
  version: '1',
  isLocked: null,
});

export const AnnualReportContextProvider = ({
  clientId,
  financialYear,
  children,
}: AnnualReportContextProviderProps) => {
  const sdk = useApiSdk();

  const [annualReportData, setAnnualReportData] =
    useState<AnnualReportContextType>({
      version: '1',
      isLocked: null,
    });

  const fetchDocument = useCallback(async () => {
    const result = await asResultClass(
      sdk.findAgoyDocument({ clientId, financialYear, type: 'annualReport' })
    );

    if (result.ok && result.val.length === 1) {
      const changesResult = await asResultClass(
        sdk.getAgoyDocumentChanges({
          clientId,
          documentId: result.val[0].id,
        })
      );

      if (!changesResult.ok) {
        return;
      }

      const { version } =
        changesResult.val?.changes?.documentConfiguration || {};

      if (version === '1') {
        return;
      }

      setAnnualReportData({
        version,
        isLocked: changesResult.val.locked,
      });
    }
  }, [clientId, financialYear, sdk]);

  useEffect(() => {
    fetchDocument();
  }, [fetchDocument]);

  return (
    <AnnualReportContext.Provider value={annualReportData}>
      {children}
    </AnnualReportContext.Provider>
  );
};

export default AnnualReportContext;

import { AgoyTableRow, id, or, ref, value } from '@agoy/document';
import { IxbrlCell } from '../../common';
import { Signatures } from '../../common/types/signatures';
import {
  table,
  TableBuilder,
  field,
  ixbrlField,
  ixbrlCell,
} from '../../common/utils/util';
import { mapRecord } from '@agoy/common';
import { AnnualReportType } from '../../..';

const ixbrlElementName = {
  givenName: 'se-gen-base:UnderskriftHandlingTilltalsnamn',
  surName: 'se-gen-base:UnderskriftHandlingEfternamn',
  role: 'se-gen-base:UnderskriftHandlingRoll',
  accountantRole: 'se-gen-base:UnderskriftHandlingTitel',
  date: 'se-gen-base:UndertecknandeDatum',
};

const addNewRowTemplate = (
  rowBuilder: TableBuilder<IxbrlCell>,
  version: '1' | '2'
): TableBuilder<IxbrlCell> => {
  switch (version) {
    case '1':
      return rowBuilder.newRowTemplate(
        ixbrlCell(value('$givenName'), {
          type: 'stringItemType',
          name: 'se-gen-base:UnderskriftHandlingTilltalsnamn',
          contextRef: 'period0',
        }),
        ixbrlCell(value('$surName'), {
          type: 'stringItemType',
          name: 'se-gen-base:UnderskriftHandlingEfternamn',
          contextRef: 'period0',
        }),
        ixbrlCell(value('$role'), {
          type: 'stringItemType',
          name: 'se-gen-base:UnderskriftHandlingRoll',
          contextRef: 'period0',
        }),
        ixbrlCell(value('$accountantRole'), {
          type: 'stringItemType',
          name: 'se-gen-base:UnderskriftHandlingTitel',
          contextRef: 'period0',
        }),
        ixbrlCell(value('$date'), {
          type: 'stringItemType',
          name: 'se-gen-base:UndertecknandeDatum',
          contextRef: 'period0',
        }),
        value('$phoneNumber'),
        value('$email'),
        value('$location')
      );

    case '2':
      return rowBuilder.newRowTemplateGenerator(
        (rowId, parentId): AgoyTableRow<IxbrlCell> => ({
          active: true,
          id: rowId,
          cells: [
            'givenName',
            'surName',
            'role',
            'accountantRole',
            'date',
            'phoneNumber',
            'email',
            'location',
            'personNr',
            'responsibleAuditor',
          ].reduce((cells, key) => {
            const cell = ref(
              or(
                ...['edit', 'ci', 'signing'].map((subRowId) =>
                  id(`${parentId}.${rowId}.${subRowId}.${key}`)
                )
              )
            );
            return {
              ...cells,
              [key]: ixbrlElementName[key]
                ? ixbrlCell(cell, {
                    type: 'stringItemType',
                    name: ixbrlElementName[key],
                    contextRef: 'period0',
                  })
                : cell,
            };
          }, {}),
          rows: [],
          newRowTemplate: (
            subRowId,
            parentRowId,
            params
          ): AgoyTableRow<IxbrlCell> => ({
            id: subRowId,
            active: true,
            cells: mapRecord(params, (cell) => value(cell)),
          }),
        })
      );
    default:
      throw new Error('Invalid version');
  }
};

export const signaturesConfig = (
  version: '1' | '2',
  reportType: AnnualReportType | undefined
): Signatures => ({
  active: undefined,
  section: {
    isDateForAllMembers: value(true),
    active: undefined,
    title: field('Underskrifter av årsredovisning'),
    location:
      reportType === 'k3'
        ? ref(id('settings.clientInformation.city'))
        : ixbrlField(ref(id('settings.clientInformation.city')), {
            type: 'stringItemType',
            name: 'se-gen-base:UndertecknandeArsredovisningOrt',
            contextRef: 'period0',
          }),
    date: ixbrlField(field(''), {
      type: 'dateItemType',
      name: 'se-gen-base:UndertecknandeArsredovisningDatum',
      contextRef: 'period0',
    }),
    showDateInfo: value(version === '2'),
    people: addNewRowTemplate(
      table<IxbrlCell>(
        'section.people',
        'givenName',
        'surName',
        'role',
        'accountantRole',
        'date',
        'phoneNumber',
        'email',
        'location',
        'personNr',
        'responsibleAuditor'
      ),
      version
    ).build(),
    auditReportDate: ixbrlField(field(''), {
      type: 'dateItemType',
      name: 'auditReportDateName',
      contextRef: 'period0',
    }),
    statement: ixbrlField(field(''), {
      type: 'enumerationItemType',
      name: 'se-gen-base:RevisorspateckningRevisionsberattelseAvstyrktResultatrakningenBalansrakningenList',
      contextRef: 'period0',
    }),
    statementPronoun: field(''),
    auditFirm: {
      companyName: ixbrlField(field(''), {
        type: 'stringItemType',
        name: 'se-cd-base:ValtRevisionsbolagsnamn',
        contextRef: 'period0',
      }),
      orgNr: field(''),
    },
  },
});

export const signaturesReferences = (): Record<string, string> => ({});

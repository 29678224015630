import React, {
  useState,
  createContext,
  useEffect,
  useCallback,
  useContext,
} from 'react';

import { asResultClass, useApiSdk } from 'api-sdk';
import { useSelector } from 'redux/reducers';
import { clientYear } from '_reconciliation/redux/accounting-view/selectors';
import PeriodDataContext from '_reconciliation/components/ReconciliationView/HiddenRow/Rows/PeriodDataContext';
import { Period } from '@agoy/api-sdk-core';
import { useDispatch } from 'react-redux';
import { addGlobalErrorMessage } from 'redux/actions';
import { parseFormat } from '@agoy/dates';

export type PeriodDocumentContextType = [
  PeriodDocumentState,
  React.Dispatch<React.SetStateAction<PeriodDocumentState>>,
];

export interface PeriodDocument {
  period: Period;
  name: string;
  lastModified: string;
  url: string;
  uploading?: boolean;
  id: number;
  references?: {
    type: 'A' | 'G';
    key: string;
    periodIds: number[];
  }[];
}

interface PeriodDocumentsProviderProps {
  account: string;
  children: React.ReactNode | React.ReactNodeArray;
}

type DocumentObj = {
  url: string;
  name: string;
  uploading: boolean | undefined;
  id?: string;
};

interface PeriodDocumentState {
  documents: PeriodDocument[];
  loadingDocuments: boolean;
  uploadingDocuments: boolean;
  refetchDocuments: () => Promise<void>;
  account: string | undefined;
  updateQueryCache: (documents: DocumentObj[]) => void;
}

const defaultState: PeriodDocumentState = {
  documents: [],
  loadingDocuments: false,
  refetchDocuments: () => Promise.resolve(),
  uploadingDocuments: false,
  account: undefined,
  updateQueryCache: () => {},
};

export const PeriodDocumentContext = createContext<PeriodDocumentContextType>([
  defaultState,
  () => {},
]);

const PeriodDocumentsProvider = ({
  account,
  children,
}: PeriodDocumentsProviderProps): JSX.Element => {
  const { period, clientId, financialYear, groupedPeriods } =
    useContext(PeriodDataContext);

  const dispatch = useDispatch();

  const { getPeriodDocuments } = useApiSdk();

  const organisationId = useSelector(
    (state) => state.user.organisationId ?? ''
  );

  const [loadingDocuments, setLoadingDocuments] = useState(false);

  const documentsCount = useSelector(
    clientYear(
      clientId,
      financialYear,
      (state) =>
        state.userInputDocuments?.[`account${account}documents`]?.[
          parseFormat(period.start, 'yyyyMM')
        ]
    )
  );

  const year = period.start.substring(0, 4);
  const month = period.start.substring(5, 7);

  const [state, setState] = useState<PeriodDocumentState>({
    ...defaultState,
    account,
  });

  const refetchDocuments = useCallback(async () => {
    setLoadingDocuments(true);
    const result = await asResultClass(
      getPeriodDocuments({
        clientid: clientId,
        account: [account],
        periodId: groupedPeriods.map((p) => p.id),
      }).then((res): PeriodDocument[] =>
        groupedPeriods.flatMap((p) =>
          res
            .filter((doc) =>
              doc.references.find(
                (ref) =>
                  ref.type === 'A' &&
                  ref.key === account &&
                  ref.periodIds.includes(p.id)
              )
            )
            .map((doc) => ({
              ...doc,
              period: p,
            }))
        )
      )
    );

    setLoadingDocuments(false);

    if (result.err) {
      dispatch(addGlobalErrorMessage('error'));
      return;
    }

    setState((currentState) => ({
      ...currentState,
      documents: result.val,
    }));
  }, [groupedPeriods, getPeriodDocuments, clientId, account, dispatch]);

  useEffect(() => {
    refetchDocuments();
  }, [period, refetchDocuments, documentsCount]);

  // Update the context's internal state when props update
  useEffect(() => {
    setState((currentState) => ({
      ...currentState,
      organisationId,
      clientId,
      account,
      financialYear,
      year,
      month,
      periodId: period.id,
    }));
  }, [
    clientId,
    organisationId,
    account,
    financialYear,
    year,
    month,
    period.id,
  ]);

  return (
    <PeriodDocumentContext.Provider
      value={[{ ...state, loadingDocuments, refetchDocuments }, setState]}
    >
      {children}
    </PeriodDocumentContext.Provider>
  );
};

export default PeriodDocumentsProvider;

import React, { useState } from 'react';

type ImageLoaderProps = {
  src: string;
  fallbackSrc?: string;
  className?: string;
};

export const ImageLoader = ({
  src,
  fallbackSrc,
  className,
}: ImageLoaderProps): JSX.Element => {
  const [imageLoadError, setImageLoadError] = useState(false);
  const errorHandler = () => {
    setImageLoadError(true);
  };

  return (
    <img
      className={className}
      onError={errorHandler}
      src={imageLoadError ? (fallbackSrc ?? '') : src}
      alt=""
    />
  );
};

export default ImageLoader;

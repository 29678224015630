import { value } from '@agoy/document';
import { table, field } from '../../common/utils/util';
// For now it uses exactly the same interfaces defined in the common signatures
import { Signatures } from '../../common/types/signatures';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };

export const individualCompanySignatures = {
  type: 'part' as const,
  children: {
    active: boolean,
    section: {
      type: 'part' as const,
      children: {
        isDateForAllMembers: cell,
        active: boolean,
        title: cell,
        location: cell,
        date: cell,
        showDateInfo: cell,
        people: { type: 'table' as const },
        auditReportDate: cell,
        statement: cell,
        statementPronoun: cell,
        auditFirm: {
          type: 'part' as const,
          children: {
            companyName: cell,
            orgNr: cell,
          },
        },
      },
    },
  },
};

export const signaturesConfig = (): Signatures => ({
  active: undefined,
  section: {
    active: undefined,
    isDateForAllMembers: value(true),
    title: field('Underskrifter av årsbokslut'),
    location: field(''),
    date: field(''),
    showDateInfo: value(false),
    people: table(
      'section.people',
      'givenName',
      'surName',
      'role',
      'accountantRole',
      'date'
    )
      .newRowTemplate(
        value('$givenName'),
        value('$surName'),
        value('$role'),
        value('$accountantRole'),
        value('$date')
      )
      .build(),
    auditReportDate: field(''),
    statement: field(''),
    statementPronoun: field(''),
    auditFirm: {
      companyName: field(''),
      orgNr: field(''),
    },
  },
});

export const signaturesReferences = (): Record<string, string> => ({});

import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import styled from '@emotion/styled';
import Route from '_shared/components/Route';

import ReconciliationContent from './ReconciliationContent';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(2)}px
    ${({ theme }) => theme.spacing(4)}px;
  align-self: center;

  @media (max-width: 1600px) {
    margin-left: 340px;
  }
`;

const Settings = () => {
  const { path } = useRouteMatch();

  return (
    <Container>
      <ContentWrapper>
        <Route path={`${path}/reconciliations`} exact>
          <ReconciliationContent />
        </Route>
      </ContentWrapper>
    </Container>
  );
};

export default Settings;

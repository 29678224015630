import { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Subscription } from 'rxjs';
import { TimePeriod } from '@agoy/document';
import { asResultClass, useApiSdk } from 'api-sdk';
import { addGlobalErrorMessage } from 'redux/actions';
import { useSelector } from 'redux/reducers';
import { updateTaxExternalDocumentValues } from '_tax/redux/tax-view/actions';
import ExternalDocumentValuesResolver from '../../_shared/services/document/ExternalDocumentValuesResolver';
import TaxesDataContext from './TaxesDataContext';

const TaxesExternalDocumentValues = () => {
  const sdk = useApiSdk();
  const dispatch = useDispatch();

  const readonly = useSelector((state) => state.user.supportUser);

  const { clientId, financialYear } = useContext(TaxesDataContext);

  useEffect(() => {
    // Variables that need clean up when unmounting or switching financial year
    let resolver: ExternalDocumentValuesResolver | undefined;
    let subscription: Subscription | undefined;
    let cancelled = false;

    const run = async () => {
      const docResult = await asResultClass(
        sdk.findAgoyDocument({
          clientId,
          financialYear,
          type: 'financialStatement',
        })
      );
      if (docResult.err) {
        dispatch(addGlobalErrorMessage('error'));
        return;
      }
      const documentId = docResult.val[0]?.id;
      if (!documentId) {
        console.warn(
          'DocumentId for the current financial statement not found'
        );
        dispatch(addGlobalErrorMessage('error'));
        return;
      }

      if (cancelled) {
        // If cancelled then no more clean up will take place.
        // Breaking here.
        return;
      }

      resolver = new ExternalDocumentValuesResolver(
        sdk,
        clientId,
        documentId,
        TimePeriod.fromFinancialYear(financialYear),
        readonly
      );
      subscription = resolver
        .resolveValues('previousYear', 'financialStatement', -1)
        .subscribe({
          next: (values) =>
            dispatch(
              updateTaxExternalDocumentValues(
                clientId,
                financialYear,
                'previousYear',
                values
              )
            ),
          error: (err) => {
            console.error(err);
            dispatch(addGlobalErrorMessage('error'));
          },
        });
    };

    run();

    return () => {
      // Clean up, set the cancelled flag to stop any
      // ongoing execution.
      cancelled = true;
      subscription?.unsubscribe();
      resolver?.dispose();
    };
  }, [sdk, clientId, financialYear, dispatch, readonly]);

  return null;
};
export default TaxesExternalDocumentValues;

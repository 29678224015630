import { SpecificationColumnContentTypeEnum } from '_clients/types/types';

export const isColumnNumeric = (conentType: string): boolean => {
  return (
    conentType === SpecificationColumnContentTypeEnum.AMOUNT ||
    conentType === SpecificationColumnContentTypeEnum.PERCENTAGE
  );
};

export default isColumnNumeric;

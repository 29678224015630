import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { Paper } from '@material-ui/core';

import { asResultClass, useApiSdk } from 'api-sdk';
import { ClientCompanyType } from '_clients/types/types';
import { resetUnsafeClientData } from '_shared/redux/actions';

import { isSameOrAfter, isSameOrBefore, parseFinancialYear } from '@agoy/dates';
import useHistory from '_shared/hooks/useHistory';
import InformationRow from './InformationRow';
import LampRow from './LampRow';
import { PeriodStatusesType } from './LampRow/utils';

const Container = styled(Paper)`
  display: flex;
  flex-direction: column;
  row-gap: ${(props) => props.theme.spacing(2)}px;
  padding: ${(props) => props.theme.spacing(2)}px;
  margin: 0 ${(props) => props.theme.spacing(3)}px;
  border-bottom: 0;

  :first-of-type {
    margin-top: ${(props) => props.theme.spacing(3)}px;
  }
`;

interface ClientCardProps {
  client: ClientCompanyType;
  periods: { date: string; hasData: boolean }[];
  programStatuses: { [key: string]: PeriodStatusesType };
  expanded: boolean;
  onToggleExpanded: () => void;
}

const ClientCard = ({
  client,
  periods,
  programStatuses,
  expanded,
  onToggleExpanded,
}: ClientCardProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const sdk = useApiSdk();

  const {
    closingMonth,
    closingPeriod,
    lamps,
    financialYears,
    id: clientId,
    latestFinancialYear,
  } = client;

  const getFinancialYear = useCallback(() => {
    const currentFinancialYear = financialYears.find((year) => {
      const { start, end } = parseFinancialYear(year);
      return (
        isSameOrBefore(start, new Date()) && isSameOrAfter(end, new Date())
      );
    });

    return currentFinancialYear || latestFinancialYear;
  }, [financialYears, latestFinancialYear]);

  const openMoreCallback = useCallback(() => {
    history.push(`/clients/${clientId}`);
  }, [clientId, history]);

  const visitCustomer = useCallback(() => {
    const financialYear = getFinancialYear();

    // send event to update invoices when select client
    asResultClass(sdk.sendSqsCustomerInvoicesEvent({ clientid: clientId }));
    asResultClass(sdk.sendSqsSupplierInvoicesEvent({ clientid: clientId }));

    dispatch(resetUnsafeClientData());

    if (financialYear) {
      history.push(`/clients/${clientId}/reconciliation/${financialYear}`);
    } else {
      history.push(`/clients/${clientId}/empty`);
    }
  }, [getFinancialYear, sdk, clientId, dispatch, history]);

  return (
    <Container elevation={2} onClick={visitCustomer}>
      <InformationRow
        customer={client}
        expandedCallback={onToggleExpanded}
        openMoreCallback={openMoreCallback}
        expanded={expanded}
      />

      <LampRow
        expanded={expanded}
        lamps={lamps}
        programStatuses={programStatuses}
        periods={periods}
        closingMonth={closingMonth}
        closingPeriod={closingPeriod}
        financialYears={financialYears}
      />
    </Container>
  );
};

export default memo(ClientCard);

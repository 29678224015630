import React, { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Button from '_shared/components/Buttons/Button';
import { useDispatch } from 'react-redux';
import { useSelector } from 'redux/reducers';
import { setMovingAccountsMode } from 'redux/actions';
import { useIntl } from 'react-intl';
import { Prompt } from 'react-router-dom';
import useHistory from '_shared/hooks/useHistory';
import MoveAccountsContext from './RowContext/MoveAccountsContext';
import SaveDialog from './SaveDialog';
import ResetDialog from './ResetDialog';

const Container = styled.div<{ isOpen: boolean }>`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  width: 100%;
  background: #fff;
  z-index: 4;
  overflow: hidden;
  height: ${({ isOpen }) => (isOpen ? '100px' : '0')};
  bottom: ${({ isOpen }) => (isOpen ? '0' : '-100px')};
  transition: all 0.5s ease 0s;
  box-shadow: 0px -1px 3px 0px rgba(209, 209, 209, 1);
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)}px;
`;

const MovingAccountsToolbar = () => {
  const { formatMessage } = useIntl();

  const {
    localMovedAccounts,
    resetLocalChanges,
    saveModalIsOpen,
    setSaveModalIsOpen,
    saveChanges,
    resetChanges,
  } = useContext(MoveAccountsContext);

  const { movingAccountsMode } = useSelector((state) => state.accountingView);
  const [navigatedLocation, setNavigatedLocation] = useState<Location | null>(
    null
  );
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [resetIsOpen, setResetIsOpen] = useState(false);

  const [isSaving, setIsSaving] = useState(false);
  const [isResetting, setIsResetting] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (confirmedNavigation && navigatedLocation) {
      history.push(navigatedLocation.pathname);
    }
  }, [confirmedNavigation, history, navigatedLocation]);

  useEffect(() => {
    const unListen = history.listen(() => {
      exitMovingMode();
      unListen();
    });
  }, [history]);

  const hasUnsavedMovedAccounts = () =>
    Object.keys(localMovedAccounts).length > 0;

  const handleNavigation = (location, _): boolean => {
    if (!confirmedNavigation && hasUnsavedMovedAccounts()) {
      setSaveModalIsOpen(true);
      setNavigatedLocation(location);
      return false;
    }
    return true;
  };

  const exitMovingMode = () => {
    setSaveModalIsOpen(false);
    dispatch(setMovingAccountsMode(false));
    if (navigatedLocation) {
      setConfirmedNavigation(true);
    }
  };

  const onCancelMovingAccounts = () => {
    if (!saveModalIsOpen && hasUnsavedMovedAccounts()) {
      setSaveModalIsOpen(true);
    } else {
      exitMovingMode();
    }
  };

  const onResetMovingAccounts = async () => {
    try {
      setIsResetting(true);
      await resetChanges();
      setResetIsOpen(false);
      exitMovingMode();
    } finally {
      setIsResetting(false);
    }
  };

  const onSaveChanges = async () => {
    try {
      setIsSaving(true);
      await saveChanges();
      exitMovingMode();
    } finally {
      setIsSaving(false);
    }
  };

  const onModalIgnoreChanges = () => {
    resetLocalChanges();
    exitMovingMode();
  };

  const onModalCancel = () => {
    setSaveModalIsOpen(false);
    setNavigatedLocation(null);
    setConfirmedNavigation(false);
  };

  return (
    <Container isOpen={movingAccountsMode}>
      <Prompt when={hasUnsavedMovedAccounts()} message={handleNavigation} />
      <SaveDialog
        isOpen={saveModalIsOpen}
        isLeavingPage={navigatedLocation != null}
        onClose={onModalCancel}
        onIgnoreChanges={onModalIgnoreChanges}
        onApprove={onSaveChanges}
      />
      <ResetDialog
        isOpen={resetIsOpen}
        onClose={() => setResetIsOpen(false)}
        onReset={onResetMovingAccounts}
      />
      <Button
        label={formatMessage({
          id: 'reconciliation.movedAccount.toolbar.reset',
        })}
        variant="outlined"
        color="danger"
        onClick={() => setResetIsOpen(true)}
        loading={isResetting}
        disabled={isSaving}
      />
      <ButtonWrapper>
        <Button
          label={formatMessage({ id: 'cancel' })}
          variant="text"
          onClick={onCancelMovingAccounts}
          disabled={isSaving || isResetting}
        />
        <Button
          label={formatMessage({
            id: 'reconciliation.movedAccount.toolbar.save',
          })}
          onClick={onSaveChanges}
          loading={isSaving}
          disabled={isResetting}
        />
      </ButtonWrapper>
    </Container>
  );
};

export default MovingAccountsToolbar;

/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Paper, Tab, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Switch, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'redux/reducers';
import { ClientPersonsRouteParams } from 'routes/types';
import CardTabPanel from '_organization/components/organisms/CardTabPanel';
import { CardTabs } from '_organization/components/organisms/CardTabs';
import PersonDetailsView from '_person/components/PersonDetailsView';
import PersonViewHeader from '_person/components/_organisms/PersonViewHeader';
import { getPerson, savePerson } from '_person/redux/persons/actions';
import { PrivatePerson } from '_person/_types/person';
import CreateConnectionToCompanyView from '_client-connections/components/CreateConnectionToCompanyView';
import ConnectionDetailsView from '_client-connections/components/ConnectionDetailsView';
import ConnectionsToCompanyView from '_client-connections/components/ConnectionsToCompanyView';
import LoadingPlaceholder from '_shared/components/LoadingPlaceholder';
import { IconMenuProvider } from '_shared/components/IconMenu';
import Link from '_shared/components/Link';
import Route from '_shared/components/Route';

const PersonView = styled.div`
  display: flex;
  flex: 1;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
`;

const TabsContainer = styled(Paper)`
  border-top: none;
  flex: 1;
  background-color: ${(props) => props.theme.palette.grey[100]};

  .MuiTabs-root {
    background-color: #fff;
  }
`;

const StyledCardTabs = styled(CardTabs)`
  .MuiPaper-root {
    border: none;
  }
`;

const StyledTabPanel = styled(CardTabPanel)`
  flex: 1;
  min-height: 500px;
  padding-left: ${(props) => props.theme.spacing(2)}px;
  background-color: transparent;
`;

const ErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props) => props.theme.spacing(10)}px
    ${(props) => props.theme.spacing(5)}px;
`;

const localRoutesTabIndex = {
  connections: 1,
  create: 1,
  documents: 2,
  'activity-log': 3,
};

const ViewClientPerson: React.FC = () => {
  const {
    path,
    params: { personId, connectionId },
  } = useRouteMatch<ClientPersonsRouteParams>();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const person = useSelector((state) => state.persons?.[personId]);
  const isLoading = useSelector((state) => state.ui.fetchingPersons);

  const tabName = path.substring(path.lastIndexOf('/') + 1);
  const tabValue: number = connectionId ? 1 : localRoutesTabIndex[tabName] || 0;

  useEffect(() => {
    (async () => {
      await dispatch(getPerson(personId));
    })();
  }, [personId, dispatch]);

  const handleOnPersonUpdate = (updatedPerson: PrivatePerson) => {
    if (person.id == null) {
      return;
    }

    dispatch(savePerson(person.id, { ...person, ...updatedPerson }));
  };

  if (isLoading) {
    return <LoadingPlaceholder />;
  }

  if (person == null) {
    return (
      <ErrorWrapper>
        <Typography variant="h5">
          {formatMessage({ id: 'info.page.notFound' })}
        </Typography>
      </ErrorWrapper>
    );
  }

  const PersonTab = ({ label, to }): JSX.Element => (
    <Tab
      label={formatMessage({ id: label })}
      component={Link}
      to={`/persons/${personId}/${to}`}
    />
  );

  const PersonPanel = ({ index, name, url, children }): JSX.Element => (
    <StyledTabPanel name={name} value={tabValue} index={index}>
      <Route path={`/persons/${personId}/${url}`} exact>
        {children}
      </Route>
    </StyledTabPanel>
  );

  const clientWithConnections = {
    ...person,
    name: `${person.firstName} ${person.lastName}`,
  };

  return (
    <PersonView>
      <IconMenuProvider />
      <Container>
        <PersonViewHeader name={`${person?.firstName} ${person?.lastName}`} />
        <TabsContainer variant="elevation">
          <StyledCardTabs
            value={tabValue}
            indicatorColor="secondary"
            textColor="secondary"
          >
            <PersonTab label="information" to="" />
            <PersonTab label="connections.title" to="connections" />
            {/* NOTE: we will activate these tabs soon */}
            {/* <PersonTab label="documents" to="documents" />
          <PersonTab label="activitylog" to="activity-log" /> */}
          </StyledCardTabs>

          <PersonPanel index={0} name="info" url="">
            {person && (
              <PersonDetailsView
                person={person}
                onEdit={handleOnPersonUpdate}
              />
            )}
          </PersonPanel>

          <StyledTabPanel name="connections" value={tabValue} index={1}>
            <Switch>
              <Route path={`/persons/${personId}/connections`} exact>
                <ConnectionsToCompanyView
                  client={clientWithConnections}
                  path="persons"
                />
              </Route>
              <Route path={`/persons/${personId}/connections/create`} exact>
                <CreateConnectionToCompanyView
                  client={clientWithConnections}
                  path="persons"
                />
              </Route>
              <Route
                path={`/persons/${personId}/connections/${connectionId}`}
                exact
              >
                <ConnectionDetailsView
                  path="persons"
                  client={clientWithConnections}
                  connectionId={Number.parseInt(connectionId, 10)}
                />
              </Route>
            </Switch>
          </StyledTabPanel>
        </TabsContainer>
      </Container>
    </PersonView>
  );
};

export default ViewClientPerson;

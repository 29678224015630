import React from 'react';

import CancelPage from '_payment/components/pages/Cancel';
import { usePaymentStatus } from '_payment/payment-status/usePaymentStatus';
import useHistory from '_shared/hooks/useHistory';

const Container = () => {
  const history = useHistory();
  const { reloadPaymentStatus } = usePaymentStatus();

  React.useEffect(() => {
    reloadPaymentStatus();
  }, []);

  const handleSelectRedirect = () => {
    history.push('/payment/checkout');
  };

  return <CancelPage handleSelectRedirect={handleSelectRedirect} />;
};

export default Container;

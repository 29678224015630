/**
 * This file contains the ontological mapping for the activity log's event.
 *
 * An EVENT contains information about WHO did WHAT, WHERE and WHEN, for traceability reasons.
 *
 * WHO: information about the user (userId, userIp)
 * WHEN: timestamp
 * WHERE: contextualized by classifying the events by PROGRAM / SECTION / RESOURCE (following a tree like structure)
 * WHAT: the operation performed over a said resource (CREATE/READ/UPDATE...), with the arguments [{name:'Old name'},{name:'New name'}]
 *
 * The DB stores the event WHERE and WHAT information as strings,
 * while the schema below keeps the tree-like relationship between the contexts.
 *
 * By storing the metadata and context of events, they can be represented or feed into other systems dynamically.
 * The data that ecompases an EVENT is persisted agnostically.
 * Translations and transformation can be applier in other layers.
 *
 * IMPORTANT: changes in the schema will most likely require DB migrations, so do it with care.
 *
 * NOTE: consider adding a schema migration system to steamline the schema changes.
 */

/**
 * Defined which operations are available
 */
export const OperationsArray = [
  'CREATE',
  'READ',
  'UPDATE',
  'DELETE',
  'LOGIN',
  'LOGOUT',
  'FAIL',
  'RESEND',
  'CANCEL',
] as const;

/**
 * ***********************************
 * ******** CHANGE CAREFULLY *********
 * ***********************************
 *
 * Ontology for all the relationship between even context. NOT TO BE USED DIRECTLY.
 *
 * Maps the locations of events to resources and available operations.
 *
 * SHAPE = program -> section -> resource -> operationN: true
 */
export const EventMapSchema = {
  SYSTEM: {
    GENERAL: {
      USER: {
        LOGIN: true,
        LOGOUT: true,
      },
      CLIENT: {
        CREATE: true,
      },
      SETTINGS: {
        UPDATE: true,
      },
    },
    DOCUMENTS: {
      DOCUMENT: {
        CREATE: true,
        DELETE: true,
      },
    },
  },
  RECONCILIATION: {
    HIDDEN_ROW: {
      PERIOD_DOCUMENT: {
        CREATE: true,
        DELETE: true,
        UPDATE: true,
      },
      DOCUMENT: {
        CREATE: true,
        DELETE: true,
        UPDATE: true,
      },
      INPUT_DATA: {
        UPDATE: true,
      },
      ROUTINE: {
        CREATE: true,
      },
      SKATTEVERKET: {
        READ: true,
      },
      SALDO: {
        UPDATE: true,
      },
      CHECKED: {
        UPDATE: true,
      },
      PRELIMINARY_ACTUAL_BALANCE: {
        UPDATE: true,
      },
      PRELIMINARY_ACTUAL_BALANCE_CHECKED: {
        UPDATE: true,
      },
    },
    STATUS_SELECTOR: {
      STATUS: {
        UPDATE: true,
      },
    },
    INVOICE: {
      SUPPLIER: {
        UPDATE: true,
        FAIL: true,
      },
      CUSTOMER: {
        UPDATE: true,
        FAIL: true,
      },
    },
  },
  FIN_STATEMENT: {
    STATUS_SELECTOR: {
      STATUS: {
        UPDATE: true,
      },
    },
    DOCUMENT: {
      LOCKED: {
        UPDATE: true,
      },
    },
    DOCUMENTS: {
      DOCUMENT: {
        CREATE: true,
        DELETE: true,
        UPDATE: true,
      },
    },
  },
  AN_REPORT: {
    STATUS_SELECTOR: {
      STATUS: {
        UPDATE: true,
      },
    },
    DIGITAL_SUBMISSION: {
      SUBMISSION_TO_BLV: {
        CREATE: true,
      },
    },
    DOCUMENT: {
      LOCKED: {
        UPDATE: true,
      },
      LOCKDATE: {
        CREATE: true,
        UPDATE: true,
      },
    },
    DOCUMENTS: {
      DOCUMENT: {
        CREATE: true,
        DELETE: true,
      },
    },
    SIGNING: {
      AR_CLING: {
        CANCEL: true,
        RESEND: true,
        DELETE: true,
      },
      AGM_CLING: {
        CANCEL: true,
        RESEND: true,
        DELETE: true,
      },
    },
  },
  TAX_DECLARATION: {
    STATUS_SELECTOR: {
      STATUS: {
        UPDATE: true,
      },
    },
    FORM: {
      SRU: {
        CREATE: true,
      },
      CHECKED: {
        UPDATE: true,
      },
      INK_ONE: {
        CREATE: true,
      },
      DOCUMENT: {
        CREATE: true,
        DELETE: true,
      },
    },
    DOCUMENT: {
      LOCKED: {
        UPDATE: true,
      },
    },
  },
  FIN_STATEMENT_REPORT: {
    STATUS_SELECTOR: {
      STATUS: {
        UPDATE: true,
      },
    },
    DOCUMENT: {
      LOCKED: {
        UPDATE: true,
      },
    },
    DOCUMENTS: {
      DOCUMENT: {
        CREATE: true,
        DELETE: true,
        UPDATE: true,
      },
    },
    REPORT: {
      DOWNLOAD: {
        CREATE: true,
      },
    },
    SETTINGS: {
      KEY_VALUE_PECENT: {
        UPDATE: true,
      },
      KEY_VALUE_TYPE: {
        UPDATE: true,
      },
      COMPARE_PERIOD: {
        UPDATE: true,
      },
    },
  },
} as const;

import { AgoyDocumentStructure, DocumentDataService } from '@agoy/document';
import { asResultClass, isApiErrorType, useApiSdk } from 'api-sdk';
import { useCallback, useState } from 'react';
import useObservable from 'utils/useObservable';

export type LockState = 'locked' | 'unlocked' | 'pending' | 'error';

/**
 * Custom hook to do the document locking.
 *
 * The lock state can have five values:
 * - undefined, the state is still unknown.
 * - 'locked', the document is locked.
 * - 'unlocked', the document is not locked.
 * - 'pending', a request is being made to change the lock state
 * - 'error', a request to change the lock failed.
 *
 * @param service View service that controls the locking
 * @returns A tuple with the current lock state and a function to change the locking.
 */
function useDocumentLocking<T extends AgoyDocumentStructure>(
  service: DocumentDataService<T> | undefined
): [
  LockState | undefined,
  (locked: boolean, reason?: string) => Promise<void>,
] {
  const locked = useObservable(service?.locked);
  const [state, setState] = useState<'ok' | 'pending' | 'error'>('ok');
  const sdk = useApiSdk();

  const setLocked = useCallback(
    async (value: boolean, reason?: string) => {
      if (service) {
        setState('pending');
        if (value === false) {
          /* We need to find the correct document as the id could be any document, and the cling document id will be the same as the annualReport, 
          but we use this function to lock any kind of document.
          */
          const result = await asResultClass(
            sdk.cancelClingSigning({
              clientid: service.clientId,
              documentId: service.documentId,
            })
          );
        }
        try {
          const result = await asResultClass(
            sdk.patchAgoyDocument({
              clientId: service.clientId,
              documentId: service.documentId,
              requestBody: {
                locked: value,
              },
              reason,
            })
          );
          if (result.err) {
            if (!isApiErrorType(result.val) || result.val.handled) {
              setState('error');
            }
            return;
          }
          service.updateLocked(value);

          if (result.ok) {
            setState('ok');
          }
        } catch (error) {
          setState('error');
        }
      }
    },
    [sdk, service]
  );

  let lockState: LockState | undefined;
  switch (state) {
    case 'pending':
    case 'error':
      lockState = state;
      break;
    default:
      if (locked === undefined) {
        lockState = undefined;
      } else {
        lockState = locked ? 'locked' : 'unlocked';
      }
  }
  return [lockState, setLocked];
}

export default useDocumentLocking;

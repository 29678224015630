import React, { useCallback, useMemo } from 'react';
import {
  AppBar as MuiAppBar,
  Toolbar as MuiToolbar,
  Divider as MuiDivider,
  Typography,
} from '@material-ui/core';
import { useSelector } from 'redux/reducers';
import styled from '@emotion/styled';
import { formatFinancialYear } from '@agoy/common';
import { currentPeriods } from '_clients/redux/customer-view/selectors';
// @ts-ignore
import { ReactComponent as AnnualReportIcon } from 'assets/program/AnnualReportIcon.svg';
import CustomerButton from '../CustomerButton';
import { TimelinePeriodSelector as LinePeriodSelector } from '../../TimelinePeriodSelector';
import SavingStatusButton from '../../SavingStatusButton';
import useHistory from '../../../../../hooks/useHistory';

const AppBar = styled(MuiAppBar)`
  border: none;
  background: ${(props) => props.theme.palette.grey[200]};
`;

const Toolbar = styled(MuiToolbar)`
  display: flex;
  justify-content: space-between;
  padding-top: ${(props) => props.theme.spacing(1)}px;
  padding-bottom: ${(props) => props.theme.spacing(1)}px;
  && > * {
    margin-right: ${(props) => props.theme.spacing(1)}px;
    margin-left: ${(props) => props.theme.spacing(1)}px;
  }
`;

const ClientButton = styled(CustomerButton)`
  margin-left: 0 !important;
`;

const Divider = styled(MuiDivider)`
  margin: ${({ theme }) => theme.spacing(1 / 2, 2, 1 / 2, 2)};
`;

const EndWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  align-items: center;
`;

const Grouper = styled.div`
  display: flex;
  align-items: center;
`;

export const AnnualReportSubMenu = () => {
  const { currentFinancialYear } = useSelector((state) => state.customerView);
  const customerId = useSelector((state) => state.customerView.currentCustomer);
  const customer = useSelector((state) =>
    customerId ? state.customers[customerId] : undefined
  );

  const annualReportPeriodStatuses = useSelector((state) => {
    return customerId
      ? state.annualReport.clients[customerId].periodStatus
      : undefined;
  });

  const periods = useSelector(currentPeriods);

  const statusPeriods = useMemo(() => {
    return periods?.map((p) => ({
      ...p,
      status: annualReportPeriodStatuses?.[p.id]?.status ?? 'NOT_STARTED',
    }));
  }, [periods, annualReportPeriodStatuses]);

  const history = useHistory();
  const handleClick = useCallback(() => {
    history.push(`/clients/${customerId}/`);
  }, [history, customerId]);

  return (
    <AppBar position="sticky" elevation={3}>
      <Toolbar>
        {customer && (
          <>
            <Grouper>
              <ClientButton
                logoUrl={customer.logo}
                name={customer.name}
                handleClick={handleClick}
              />
              <Divider orientation="vertical" flexItem />
              {statusPeriods && (
                <LinePeriodSelector
                  program="AN_REPORT"
                  programPeriods={statusPeriods}
                  icon={<AnnualReportIcon />}
                  individualCompany={customer.type === 'individual'}
                />
              )}
            </Grouper>

            <EndWrapper>
              <>
                <Typography
                  color="textPrimary"
                  style={{ fontWeight: 500, fontSize: '1.25rem' }}
                >
                  {currentFinancialYear &&
                    formatFinancialYear(currentFinancialYear)}
                </Typography>
                <Divider orientation="vertical" flexItem />
              </>
              <SavingStatusButton />
            </EndWrapper>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default AnnualReportSubMenu;

import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { useDragDropManager } from 'react-dnd';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import { ReactComponent as DragIcon } from 'assets/drag.svg';

import {
  SpecificationCellValueType,
  SpecificationCellType,
  SpecificationColumnContentType,
  SpecificationColumnType,
  SpecificationRowType,
  SpecificationType,
} from '_clients/types/types';
import { useApiSdk } from 'api-sdk';
import { addGlobalErrorMessage, addGlobalMessage } from 'redux/actions';
import LoadingLogo from '_shared/components/LoadingLogo';
import Button from '_shared/components/Buttons/Button';
import Typography from '_shared/components/Typography/Typography';
import Drawer from '_shared/components/Drawer';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MenuItem, Popover } from '@material-ui/core';
import { PrintOutlined, CachedOutlined } from '@material-ui/icons';
import { DraggableItemTypes } from 'contants';
import isColumnNumeric from '_reconciliation/util/specifications';

import ColumnSelector from './ColumnSelector/ColumnSelector';
import PeriodDataContext from '../PeriodDataContext';
import DraggableTable from './DraggableTable';
import ResyncSpecificationDialog from './ResyncSpecificationDialog';

interface SpecificationTableProps {
  accountNumber: string;
}

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 8px;
  overflow: hidden;
  padding-right: ${({ theme }) => theme.spacing(2)}px;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const HoverContainer = styled.div<{ visible: boolean }>`
  display: flex;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  min-width: 24px;
  position: relative;
`;

const Hover = styled.div<{ position: number }>`
  position: absolute;
  background-color: ${({ theme }) => theme.palette.grey[100]};
  height: 42px;
  width: 24px;
  top: ${({ position }) => position + 1}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
`;

const TableWrapper = styled.div`
  overflow: scroll;
  border-radius: 10px;
  border: 1px solid #ccc;
  max-width: fit-content;
  max-height: 500px;
  position: relative;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 16px;
  padding-left: 28px;
`;

const StyledDeleteIcon = styled(DeleteIcon)`
  height: 20px;
  padding: 2px;
`;

const RotatingIcon = styled(ExpandMoreIcon)`
  transition: transform 0.3s;

  &.open {
    transform: rotate(180deg);
  }

  &.closed {
    transform: rotate('0deg');
  }
`;

const MirroredCachedOutlined = styled(CachedOutlined)`
  transform: scaleX(-1);
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing(1)}px;
`;

const StyledPopover = styled(Popover)`
  .MuiPopover-paper {
    border-radius: ${(props) => props.theme.spacing(1)}px;

    .MuiMenuItem-root {
      text-wrap: wrap;
      font-size: 14px;
    }
  }
`;

const PopoverWrapper = styled.div`
  max-width: 216px;
  padding: 8px 0;
`;

const SpecificationTable = ({ accountNumber }: SpecificationTableProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const sdk = useApiSdk();

  const [columns, setColumns] = useState<SpecificationColumnType[]>([]);
  const [rows, setRows] = useState<SpecificationRowType[]>([]);
  const [specification, setSpecification] = useState<SpecificationType>({});
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [hoverRowOffset, setHoverRowOffset] = useState<number | undefined>();
  const [isDragging, setIsDragging] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorElOtherTasksButton, setAnchorElOtherTasksButton] =
    useState<null | HTMLElement>(null);
  const [isResyncDialogOpen, setIsResyncDialogOpen] = useState(false);

  const { clientId, period } = useContext(PeriodDataContext);
  const { sortColumnId } = specification;

  const timerRef = useRef<NodeJS.Timer>();
  const contentRef = useRef<HTMLDivElement | null>(null);

  const dragDropManager = useDragDropManager();
  const monitor = dragDropManager.getMonitor();

  const toggleDrawer = () => {
    setDrawerOpen((currentValue) => !currentValue);
  };

  const initSpecification = useCallback(async () => {
    try {
      const response = await sdk.addSpecification({
        clientid: clientId,
        periodId: period.id,
        accountNumber: Number(accountNumber),
      });

      if (response) {
        setSpecification(response);
      }
    } catch (error) {
      dispatch(addGlobalErrorMessage('error'));
    }
  }, [accountNumber, clientId, dispatch, period.id, sdk]);

  useEffect(() => {
    const unsubscribe = monitor.subscribeToStateChange(() => {
      setIsDragging(monitor.isDragging());
    });

    return () => {
      unsubscribe();
    };
  }, [monitor]);

  const getSpecifications = useCallback(async () => {
    try {
      const response = await sdk.getSpecifications({
        clientid: clientId,
        periodId: period.id,
        accountNumbers: [Number(accountNumber)],
      });

      const data = response.accounts[accountNumber];

      if (Object.keys(response.accounts).length === 0) {
        await initSpecification();
        getSpecifications();
      } else {
        setColumns(data.columns);
        setRows(data.rows);
        setSpecification(data.specification);
      }
    } catch (error) {
      dispatch(addGlobalErrorMessage('error'));
    }
  }, [clientId, period, accountNumber, sdk, initSpecification, dispatch]);

  const updateSpecification = useCallback(
    async (
      columnId: number | null,
      sortOrder: 'ASC' | 'DESC' | null,
      reset = false
    ) => {
      if (!specification.id) {
        return;
      }
      try {
        const data = await sdk.updateSpecification({
          clientid: clientId,
          specificationId: specification.id,
          requestBody: {
            sortColumnId: reset ? null : columnId,
            sortOrder: reset ? null : sortOrder,
          },
        });
        setSpecification(data);
      } catch (error) {
        dispatch(addGlobalErrorMessage('error'));
      }
    },
    [clientId, dispatch, sdk, specification]
  );

  const resetSortAfterChange = async () => {
    if (sortColumnId) {
      updateSpecification(null, null, true);
    }
  };

  useEffect(() => {
    getSpecifications();
  }, [getSpecifications]);

  const addRow = useCallback(async () => {
    if (!specification.id) {
      return;
    }

    resetSortAfterChange();

    try {
      const newRow = await sdk.addSpecificationRow({
        clientid: clientId,
        specificationId: specification.id,
      });

      setRows((prevRows) => [...prevRows, { ...newRow, cells: [] }]);
    } catch (error) {
      dispatch(addGlobalErrorMessage('error'));
    }
  }, [clientId, dispatch, sdk, specification.id, resetSortAfterChange]);

  const getUpdatedCells = (
    cells: SpecificationCellType[],
    updatedCell: {
      rowId: number;
      columnId: number;
      value: SpecificationCellValueType;
    }
  ) => {
    const updatedCells = [...cells];
    const index = cells.findIndex((c) => c.columnId === updatedCell.columnId);

    if (index === -1) {
      updatedCells.push(updatedCell);
    } else {
      updatedCells[index] = updatedCell;
    }

    return updatedCells;
  };

  const openResyncDialog = () => {
    setIsResyncDialogOpen(true);
    setAnchorElOtherTasksButton(null);
  };

  const updateCell = useCallback(
    async (
      value: SpecificationCellValueType,
      row: SpecificationRowType,
      columnId: number
    ) => {
      const specificationId = specification.id;

      if (!specificationId) {
        return;
      }

      if (columnId === sortColumnId) {
        resetSortAfterChange();
      }

      const column = columns.find((c) => c.id === columnId);

      if (!column) {
        return;
      }

      const formattedValue = (inputValue: string | number | null) => {
        let returnValue = inputValue;
        if (isColumnNumeric(column.contentType) && inputValue !== null) {
          returnValue = Number(inputValue);
          if (Number.isNaN(returnValue)) {
            return inputValue;
          }
        }
        return returnValue;
      };

      const data = {
        clientid: clientId,
        specificationId,
        rowId: row.id,
        requestBody: {
          columnId,
          value: formattedValue(value),
        },
      };

      try {
        const cellExists = !!row.cells.find((c) => c.columnId === columnId);

        const cellData = cellExists
          ? await sdk.updateSpecificationCell(data)
          : await sdk.addSpecificationCell(data);

        setRows((prevRows) =>
          prevRows.map((prevRow) =>
            prevRow.id === row.id
              ? {
                  ...prevRow,
                  cells: getUpdatedCells(row.cells, cellData),
                }
              : prevRow
          )
        );
      } catch (error) {
        dispatch(addGlobalErrorMessage('error'));
      }
    },
    [
      specification.id,
      sortColumnId,
      columns,
      clientId,
      resetSortAfterChange,
      sdk,
      dispatch,
    ]
  );

  const deleteRows = useCallback(
    async (rowsIds: number[]) => {
      if (!specification.id) {
        return;
      }
      try {
        await sdk.deleteRows({
          clientid: clientId,
          specificationId: specification.id,
          rowIds: rowsIds,
        });
        setSelectedRows((currentValue) =>
          currentValue.filter((id) => !rowsIds.includes(id))
        );
        setRows((currentValue) =>
          currentValue.filter((row) => !rowsIds.includes(row.id))
        );

        dispatch(
          addGlobalMessage(
            'info',
            rowsIds.length > 1
              ? 'hidden.specification.multipleRowsRemoved'
              : 'hidden.specification.rowRemoved'
          )
        );
      } catch (error) {
        dispatch(addGlobalErrorMessage('error'));
      }
    },
    [clientId, dispatch, sdk, specification.id]
  );

  const sortByColumn = async (
    columnId: number,
    sortOrder: 'ASC' | 'DESC' | null,
    reset = false
  ) => {
    await updateSpecification(columnId, sortOrder, reset);
    await getSpecifications();
  };

  const moveColumn = useCallback(
    async (currentIndex: number, moveToIndex: number) => {
      if (!specification.id) {
        return;
      }
      // Will get implemented when backend is ready
      console.log('moveColumn', currentIndex, moveToIndex);
    },
    [specification.id]
  );

  const moveRow = useCallback(
    async (currentIndex: number, moveToIndex: number) => {
      if (!specification.id) {
        return;
      }

      let updatedRows = [...rows];
      const currentRows = [...rows];
      const movedRow = updatedRows[currentIndex];
      const calculatedIndex =
        moveToIndex - currentIndex > 0 ? moveToIndex - 1 : moveToIndex;

      resetSortAfterChange();

      updatedRows.splice(currentIndex, 1);
      updatedRows.splice(calculatedIndex, 0, movedRow);

      updatedRows = updatedRows.map((row, index) => ({
        ...row,
        order: index + 1,
      }));

      try {
        setRows(updatedRows);

        await sdk.updateSpecificationRowOrder({
          clientid: clientId,
          specificationId: specification.id,
          rowId: movedRow.id,
          requestBody: {
            order: calculatedIndex + 1,
          },
        });
      } catch (error) {
        setRows(currentRows);
        dispatch(addGlobalErrorMessage('error'));
      }
    },
    [clientId, dispatch, rows, sdk, specification.id, resetSortAfterChange]
  );

  const resyncSpecification = useCallback(async () => {
    if (!specification.id) {
      return;
    }

    try {
      await sdk.resyncSpecification({
        clientid: clientId,
        specificationId: specification.id,
      });
      dispatch(
        addGlobalMessage('success', 'hidden.specification.resyncSuccess')
      );
      await getSpecifications();
    } catch (error) {
      dispatch(addGlobalErrorMessage('error'));
    }
  }, [clientId, dispatch, getSpecifications, sdk, specification.id]);

  const addUserColumn = useCallback(
    async (name: string, contentType: SpecificationColumnContentType) => {
      if (!specification.id) {
        return;
      }
      try {
        await sdk.addUserSpecificationColumn({
          clientid: clientId,
          requestBody: { name, contentType, periodId: period.id },
        });
        dispatch(
          addGlobalMessage('success', 'hidden.specification.addColumn.success')
        );
      } catch (error) {
        dispatch(addGlobalErrorMessage('error'));
      }
    },
    [clientId, dispatch, period.id, sdk, specification.id]
  );

  const handleToggleColumn = useCallback(
    async (column: SpecificationColumnType, value: boolean) => {
      if (!specification.id) {
        return;
      }

      try {
        if (!value) {
          await sdk.deleteSpecificationSelectedColumn({
            clientid: clientId,
            specificationId: specification.id,
            selectedColumnId: column.id,
          });

          setColumns((currentValue) =>
            currentValue.filter((c) => c.id !== column.id)
          );
        } else {
          await sdk.addSpecificationSelectedColumn({
            clientid: clientId,
            specificationId: specification.id,
            requestBody: { columnId: column.id },
          });

          setColumns((currentValue) => [...currentValue, column]);
        }
      } catch (error) {
        dispatch(addGlobalErrorMessage('error'));
      }
    },
    [clientId, dispatch, sdk, specification.id]
  );

  const handleChangeColumn = useCallback(async () => {}, []);

  const handleDeleteColumn = useCallback(async () => {}, []);

  const startScrolling = (speed: number, container: HTMLElement) => {
    timerRef.current = setInterval(() => {
      container.scrollBy(0, speed);
    }, 1);
  };

  const cancelScroll = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
  };

  useEffect(() => {
    const unsubscribe = monitor.subscribeToOffsetChange(() => {
      const offset = monitor.getClientOffset();
      const type = monitor.getItemType();

      if (type !== DraggableItemTypes.SPECIFICATION_ROW) {
        return;
      }

      cancelScroll();

      if (!offset || !contentRef.current) {
        return;
      }

      const position = contentRef.current.getBoundingClientRect();
      const calculatedOffset = offset.y - position.y;

      if (calculatedOffset < 65) {
        startScrolling(-5, contentRef.current);
      } else if (position.height - calculatedOffset < 25) {
        startScrolling(5, contentRef.current);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [monitor]);

  if (!specification.id) {
    return (
      <LoadingContainer>
        <LoadingLogo size="medium" />
      </LoadingContainer>
    );
  }

  return (
    <Container>
      <ResyncSpecificationDialog
        open={isResyncDialogOpen}
        onClose={() => setIsResyncDialogOpen(false)}
        onResync={resyncSpecification}
      />
      <Drawer
        open={drawerOpen}
        onClose={toggleDrawer}
        width="300px"
        headerTitle={formatMessage({ id: 'hidden.specification.editTable' })}
      >
        <ColumnSelector
          selectedColumns={columns}
          rows={rows}
          onToggleColumn={handleToggleColumn}
          onChangeColumn={handleChangeColumn}
          onDeleteColumn={handleDeleteColumn}
          onClose={toggleDrawer}
          onAddUserColumn={addUserColumn}
        />
      </Drawer>

      <ActionButtons>
        {selectedRows.length ? (
          <>
            {selectedRows.length === rows.length ? (
              <Typography margin="none">
                {formatMessage({ id: 'table.draggable.allSelected' })}
              </Typography>
            ) : (
              <Typography margin="none">
                {selectedRows.length}{' '}
                {formatMessage({
                  id:
                    selectedRows.length > 1
                      ? 'table.draggable.selectedPlural'
                      : 'table.draggable.selected',
                })}
              </Typography>
            )}

            <Button
              size="small"
              variant="outlined"
              color="danger"
              label={formatMessage({ id: 'table.draggable.deleteRows' })}
              startIcon={<StyledDeleteIcon />}
              onClick={() => deleteRows(selectedRows)}
            />
          </>
        ) : (
          <>
            <Button
              size="small"
              variant="outlined"
              label={formatMessage({
                id: 'hidden.specification.button.otherTasks',
              })}
              onClick={(event) =>
                setAnchorElOtherTasksButton(event.currentTarget)
              }
              endIcon={
                <RotatingIcon
                  className={anchorElOtherTasksButton ? 'open' : 'closed'}
                />
              }
            />
            <StyledPopover
              open={!!anchorElOtherTasksButton}
              anchorEl={anchorElOtherTasksButton}
              onClose={() => setAnchorElOtherTasksButton(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <PopoverWrapper>
                <MenuItem onClick={() => setAnchorElOtherTasksButton(null)}>
                  <IconWrapper>
                    <PrintOutlined />
                  </IconWrapper>
                  {formatMessage({
                    id: 'hidden.specification.button.otherTasks.print',
                  })}
                </MenuItem>
                <MenuItem onClick={openResyncDialog}>
                  <IconWrapper>
                    <MirroredCachedOutlined />
                  </IconWrapper>
                  {formatMessage({
                    id: 'hidden.specification.button.otherTasks.resync',
                  })}
                </MenuItem>
              </PopoverWrapper>
            </StyledPopover>
          </>
        )}
      </ActionButtons>
      <TableContainer>
        <HoverContainer visible={!!hoverRowOffset && !isDragging}>
          <Hover position={hoverRowOffset || 0}>
            <DragIcon />
          </Hover>
        </HoverContainer>

        <TableWrapper ref={contentRef}>
          <DraggableTable
            columns={columns}
            rows={rows}
            selectedRows={selectedRows}
            sortColumnId={specification.sortColumnId}
            onUpdateCell={updateCell}
            onMoveRow={moveRow}
            onMoveColumn={moveColumn}
            onSortColumn={sortByColumn}
            onNewRow={addRow}
            onSelectRow={setSelectedRows}
            onOpenDrawer={toggleDrawer}
            onDeleteRows={deleteRows}
            onHover={setHoverRowOffset}
          />
        </TableWrapper>
      </TableContainer>
    </Container>
  );
};

export default SpecificationTable;

import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${(props) => props.theme.palette.info.main};
`;

const EmptyColumn = styled.div`
  width: 100px;
`;

type ColumnProps = {
  longCol?: boolean;
  children: React.ReactNode;
};

const HeaderColumn = ({ children, longCol }: ColumnProps): JSX.Element => (
  <div style={{ flex: longCol ? 3 : 1 }}>
    <Typography variant="body1" color="textSecondary">
      {children}
    </Typography>
  </div>
);

type Props = {
  showActionButton: boolean;
};

const TableHeader = (props: Props): JSX.Element => {
  const { showActionButton } = props;
  const { formatMessage } = useIntl();

  const label = (id: string) => {
    return formatMessage({ id: `hidden.skatteverket.${id}` });
  };

  return (
    <Row>
      {showActionButton && <EmptyColumn>&nbsp;</EmptyColumn>}
      <HeaderColumn>{label('date')}</HeaderColumn>
      <HeaderColumn longCol>{label('specification')}</HeaderColumn>
      <HeaderColumn>{label('amount')}</HeaderColumn>
      <HeaderColumn>{label('saldo')}</HeaderColumn>
    </Row>
  );
};

export default TableHeader;

import React, { ChangeEvent, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import Add from '@material-ui/icons/Add';

import {
  SpecificationColumnType,
  SpecificationRowType,
} from '_clients/types/types';
import Typography from '_shared/components/Typography/Typography';
import Checkbox from '_shared/components/Controls/Checkbox';
import { Input } from '_shared/components/Inputs/v2/Input';

import { debounce } from 'lodash';
import DraggableHeader from './DraggableHeader';
import RowOptions from './RowOptions';
import DraggableRow from './DraggableRow';
import ColumnOptions from './ColumnOptions';
import AddNewRow from './AddNewRow';

const StyledTable = styled.table`
  // we can't use border: collapse; because of sticky columns :(
  // so have to add borders manually
  border-spacing: 0;
  table-layout: fixed;
  width: max-content;

  th,
  td {
    text-align: start;
    border-right: 1px solid #ccc;
    padding: 8px;
    height: 40px;
    background-color: white;
  }

  td {
    border-top: 1px solid #ccc;
  }

  tr {
    th:first-of-type,
    td:first-of-type {
      width: 28px;
      position: sticky;
      left: 0;
      text-align: center;
      z-index: 2;
    }

    th:nth-of-type(2),
    td:nth-of-type(2) {
      position: sticky;
      left: 28px;
      z-index: 2;
    }

    th:nth-last-of-type(2),
    td:nth-last-of-type(2) {
      border-right: none;
    }

    td:last-of-type,
    th:last-of-type {
      position: sticky;
      right: 0;
      border-left: 1px solid #ccc;
      border-right: none;
    }
  }
`;

const StickyHeader = styled.thead`
  position: sticky;
  top: 0;
  background: #fff;
`;

const Button = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const AddIcon = styled(Add)`
  width: 20px;
  height: 20px;
`;

const ColumnHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(0.5)}px;
`;

type DraggableTableProps = {
  columns: SpecificationColumnType[];
  rows: SpecificationRowType[];
  selectedRows: number[];
  sortColumnId: number | null | undefined;
  onUpdateCell: (
    value: string,
    row: SpecificationRowType,
    columnId: number
  ) => void;
  onNewRow: () => void;
  onSelectRow: (rowIds: number[]) => void;
  onMoveRow: (fromIndex: number, toIndex: number) => void;
  onSortColumn: (
    columnId: number,
    sortOrder: 'ASC' | 'DESC' | null,
    reset
  ) => void;
  onMoveColumn: (fromIndex: number, toIndex: number) => void;
  onDeleteRows: (rowId: number[]) => void;
  onHover: (offsetTop?: number) => void;
  onOpenDrawer: () => void;
};

const DraggableTable = ({
  columns,
  rows,
  selectedRows,
  sortColumnId,
  onUpdateCell,
  onNewRow,
  onSelectRow,
  onMoveRow,
  onSortColumn,
  onMoveColumn,
  onDeleteRows,
  onHover,
  onOpenDrawer,
}: DraggableTableProps) => {
  const { formatMessage } = useIntl();

  const tableRef = useRef<HTMLTableElement>(null);

  const setFocus = () => {
    if (tableRef.current) {
      const selectedRow =
        tableRef.current.rows[tableRef.current.rows.length - 3];
      if (selectedRow) {
        const selectedCell = selectedRow.cells[2];
        if (selectedCell) {
          (selectedCell.firstElementChild as HTMLTableCellElement).focus();
        }
      }
    }
  };

  useEffect(() => {
    setFocus();
  }, [rows.length]);

  const isAllRowsSelected =
    rows.length > 0 && selectedRows.length === rows.length;

  const moveRowUp = (rowId: number) => {
    const fromIndex = rows.findIndex((row) => row.id === rowId);
    onMoveRow(fromIndex, fromIndex - 1);
  };

  const moveRowDown = (rowId: number) => {
    const fromIndex = rows.findIndex((row) => row.id === rowId);
    onMoveRow(fromIndex, fromIndex + 1);
  };

  const moveColumnLeft = (columnId: number) => {
    const fromIndex = columns.findIndex((column) => column.id === columnId);
    onMoveColumn(fromIndex, fromIndex - 1);
  };

  const moveColumnRight = (columnId: number) => {
    const fromIndex = columns.findIndex((column) => column.id === columnId);
    onMoveColumn(fromIndex, fromIndex + 1);
  };

  const handleCellUpdate = debounce(
    (
      e: ChangeEvent<HTMLInputElement>,
      row: SpecificationRowType,
      cellId: number
    ) => {
      e.preventDefault();
      onUpdateCell(e.target.value, row, cellId);
    },
    600
  );

  const handleSelectAll = () => {
    if (isAllRowsSelected) {
      onSelectRow([]);
    } else {
      onSelectRow(rows.map((row) => row.id));
    }
  };

  const handleSelectRow = (id: number) => {
    if (selectedRows.includes(id)) {
      onSelectRow(selectedRows.filter((rowId) => rowId !== id));
    } else {
      onSelectRow([...selectedRows, id]);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onNewRow();
    }
  };

  return (
    <StyledTable ref={tableRef}>
      <StickyHeader>
        <tr>
          <th>
            <Typography color="placeholder" margin="none">
              #
            </Typography>
          </th>
          <th>
            <Checkbox checked={isAllRowsSelected} onChange={handleSelectAll} />
          </th>
          {columns.map((column, columnIndex) => (
            <DraggableHeader
              key={column.id}
              columnIndex={columnIndex}
              moveColumn={onMoveColumn}
            >
              <ColumnHeaderContent>
                <Typography textStyle="bold" margin="none">
                  {column.name}
                </Typography>
                <ColumnOptions
                  contentType={column.contentType}
                  disableLeft={columnIndex === 0}
                  disableRight={columnIndex === columns.length - 1}
                  showResetButton={column.id === sortColumnId}
                  onMoveLeft={() => moveColumnLeft(column.id)}
                  onMoveRight={() => moveColumnRight(column.id)}
                  onSort={(sortOrder, reset) =>
                    onSortColumn(column.id, sortOrder, reset)
                  }
                />
              </ColumnHeaderContent>
            </DraggableHeader>
          ))}
          {/* Action column at the end */}
          <th>
            <Button onClick={onOpenDrawer}>
              <AddIcon />
            </Button>
          </th>
        </tr>
      </StickyHeader>

      <tbody>
        {rows.map((row, rowIndex) => (
          <DraggableRow
            row={row}
            key={row.id}
            onMoveRow={onMoveRow}
            onHover={onHover}
          >
            <td className="draggable">
              <Typography color="placeholder" margin="none">
                {rowIndex + 1}
              </Typography>
            </td>
            <td>
              <Checkbox
                checked={selectedRows.includes(row.id)}
                onChange={() => handleSelectRow(row.id)}
              />
            </td>
            {columns.map((col) => (
              <td key={`${row.id}-${col.id}`}>
                <Input
                  defaultValue={
                    row.cells.find((c) => c.columnId === col.id)?.value ||
                    undefined
                  }
                  onChange={(e) => handleCellUpdate(e, row, col.id)}
                  onKeyDown={handleKeyDown}
                />
              </td>
            ))}
            {/* Action row cell at the end */}
            <td>
              <RowOptions
                disableDown={rowIndex === rows.length - 1}
                disableUp={rowIndex === 0}
                onMoveDown={() => moveRowDown(row.id)}
                onMoveUp={() => moveRowUp(row.id)}
                onDelete={() => onDeleteRows([row.id])}
              />
            </td>
          </DraggableRow>
        ))}

        {/* Add new row */}
        <AddNewRow onDropRow={(fromIndex) => onMoveRow(fromIndex, rows.length)}>
          <td />
          <td />
          <td>
            <Button onClick={onNewRow}>
              <Typography textStyle="bold" margin="none">
                {formatMessage({ id: 'add.row' })}
              </Typography>
            </Button>
          </td>
          {columns.map((col) => (
            <td key={col.id} />
          ))}
        </AddNewRow>

        {/* Table footer */}
        <tr>
          <td />
          <td />
          {columns.map((col) => (
            <td key={col.id}>{null}</td>
          ))}
          <td />
        </tr>
      </tbody>
    </StyledTable>
  );
};

export default DraggableTable;

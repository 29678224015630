import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Card, Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';

import { useSelector } from 'redux/reducers';
import { CTAButton } from '_shared/components/Buttons';
import Section from '_shared/components/Section';
import { defaultPerson, PrivatePersonWithManager } from '_person/_types/person';
import InfoField, { Validation } from '_person/components/_organisms/InfoField';
import ContactPersonPicker from '_person/components/_organisms/ContactPersonPicker';
import { getOrganisationMembers } from '_organization/redux/actions';
import {
  addGlobalErrorMessage,
  addGlobalMessage,
} from '_messages/redux/actions';
import { createPerson } from '_person/redux/persons/actions';
import useHistory from '_shared/hooks/useHistory';

const Page = styled.div`
  display: flex;
  justify-content: center;
  overflow-y: auto;
`;

const Container = styled.div`
  width: 100%;
  height: fit-content;
  padding: ${(props) => props.theme.spacing(4)}px;
  margin: 0 auto;
  max-width: 1280px;
`;

const StyledCard = styled(Card)`
  padding: ${(props) => props.theme.spacing(2)}px;
`;

const PersonCard = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => (
  <StyledCard>
    <Grid container spacing={2}>
      {children}
    </Grid>
  </StyledCard>
);

const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${(props) => props.theme.spacing(2)}px;
`;

const CreateClientPerson = (): JSX.Element => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const isSaving = useSelector((state) => state.ui.fetchingPersons);
  const currentUser = useSelector((state) => state.user);
  const orgMembers = useSelector((state) => state.organisation.users);
  const organisationMembers = orgMembers.map(({ email }) => ({
    value: email,
    label: email,
  }));

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
    control,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: defaultPerson,
  });

  const handleOnSubmit = async (personData: PrivatePersonWithManager) => {
    const orgMember = orgMembers.find((m) => m.email === personData.manager);
    if (orgMember == null) {
      return;
    }

    try {
      const personId = await dispatch(createPerson(personData, orgMember));
      const message = intl.formatMessage({ id: 'person.form.submit.success' });
      dispatch(addGlobalMessage('success', message));

      history.push(`/persons/${personId}`);
    } catch (error) {
      dispatch(addGlobalErrorMessage('error'));
    }
  };

  useEffect(() => {
    dispatch(getOrganisationMembers(true));
  }, [dispatch]);

  useEffect(() => {
    if (orgMembers.length > 0) {
      // when the list of org members is ready
      // we select the current user who is registering the person
      setValue('manager', currentUser.email);
    }
  }, [orgMembers, currentUser, setValue]);

  return (
    <Page>
      <Container>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <Section title={intl.formatMessage({ id: 'person.create.basic' })}>
            <PersonCard>
              <InfoField
                {...register('personNumber', {
                  required: true,
                  ...Validation.PersonalNumber,
                })}
                fieldName="personNumber"
                fieldError={errors.personNumber}
                placeholder="YYYYMMDDNNNN"
              />
              <ContactPersonPicker
                {...register('manager')}
                getValues={getValues}
                isEditing
                control={control}
                fieldError={errors.manager}
                list={organisationMembers}
              />
              <InfoField
                {...register('firstName', { required: true })}
                fieldName="firstName"
                fieldError={errors.firstName}
              />
              <InfoField
                {...register('lastName', { required: true })}
                fieldName="lastName"
                fieldError={errors.lastName}
              />
            </PersonCard>
          </Section>

          <Section title={intl.formatMessage({ id: 'person.create.address' })}>
            <PersonCard>
              <InfoField
                {...register('taxAddress.street', { required: true })}
                fieldName="taxAddress.street"
                fieldError={errors['taxAddress.street']}
              />
              <InfoField
                {...register('taxAddress.zipCode', { required: true })}
                fieldName="taxAddress.zipCode"
                fieldError={errors['taxAddress.zipCode']}
              />
              <InfoField
                {...register('taxAddress.city', { required: true })}
                fieldName="taxAddress.city"
                fieldError={errors['taxAddress.city']}
              />
              <InfoField
                {...register('taxAddress.country', { required: true })}
                fieldName="taxAddress.country"
                fieldError={errors['taxAddress.country']}
              />
            </PersonCard>
          </Section>

          <Section title={intl.formatMessage({ id: 'person.create.contact' })}>
            <PersonCard>
              <InfoField
                {...register('contactPhoneNumber', Validation.PhoneNumber)}
                fieldName="contactPhoneNumber"
                fieldError={errors.contactPhoneNumber}
              />
              <InfoField
                {...register('contactEmail', Validation.Email)}
                fieldName="contactEmail"
                fieldError={errors.contactEmail}
              />
            </PersonCard>
          </Section>

          <ActionsContainer>
            <CTAButton type="submit" loading={isSaving}>
              {intl.formatMessage({ id: 'person.form.submit.button' })}
            </CTAButton>
          </ActionsContainer>
        </form>
      </Container>
    </Page>
  );
};

export default CreateClientPerson;

import { useContext, useCallback } from 'react';
import { useIntl } from 'react-intl';

import {
  getDocumentExtension,
  getDocumentNameWithoutExtension,
} from '_reconciliation/components/ReconciliationView/HiddenRow/HiddenGroupRow/utils/documentTypes';
import PeriodDataContext from '_reconciliation/components/ReconciliationView/HiddenRow/Rows/PeriodDataContext';
import {
  GroupDocumentContext,
  PeriodDocument,
} from '_reconciliation/components/ReconciliationView/HiddenRow/HiddenGroupRow/Documents/GroupDocumentsContext';
import { Period } from '@agoy/api-sdk-core';
import { MAX_NUM_DOCUMENTS } from 'contants';
import { asResultClass, useApiSdk } from 'api-sdk';
import uniqueName from './uniqueName';

const useGroupDocuments = () => {
  const { formatMessage } = useIntl();
  const sdk = useApiSdk();

  const [state, setState] = useContext(GroupDocumentContext);

  const {
    clientId,
    period,
    previousPeriod,
    previousGroupedPeriods,
    previousPeriodFinancialYear,
  } = useContext(PeriodDataContext);

  const { documents, accounts, group } = state;

  const periodId = period.id;

  const addDocument = useCallback(
    async (fileName: string, file: File) => {
      // Create a unique name for the document. Should probably be done server side
      const currentDocuments = documents.map((document) => document.name);

      const name = getDocumentNameWithoutExtension(fileName);
      const extension = getDocumentExtension(fileName);
      const formattedName = `${name}${extension.toLocaleLowerCase()}`;

      const newDocumentName = uniqueName(
        currentDocuments,
        formattedName
      ).normalize('NFC');

      if (documents.length + 1 > MAX_NUM_DOCUMENTS) return undefined;

      const responseResult = await asResultClass(
        sdk.postPeriodDocument({
          clientid: clientId,
          accountGroup: group,
          periodId,
          name: newDocumentName,
          requestBody: file,
        })
      );

      if (responseResult.err) {
        return undefined;
      }

      setState((value) => ({
        ...value,
        documents: [
          responseResult.map((doc) => ({
            ...doc,
            period,
            references: [],
          })).val,
          ...value.documents.filter((doc) => !doc.uploading),
        ],
        uploadingDocuments: false,
      }));

      return responseResult.val;
    },
    [documents, period, setState, sdk, clientId, group, periodId]
  );

  const deleteDocument = useCallback(
    async (document: PeriodDocument) => {
      if (
        !window.confirm(
          formatMessage({ id: 'hidden.sureYouWantToRemoveDocument' })
        )
      ) {
        return;
      }

      try {
        await sdk.deletePeriodDocument({
          clientid: clientId,
          documentId: document.id,
          periodId: document.period?.id || period.id,
        });

        setState((value) => ({
          ...value,
          documents: value.documents.filter((item) => item.id !== document.id),
        }));
      } catch (e) {
        console.error(e);
      }
    },
    [formatMessage, sdk, clientId, period.id, setState]
  );

  const copyDocument = useCallback(
    async (document: PeriodDocument, newPeriod: Period) => {
      try {
        await sdk.copyPeriodDocument({
          clientid: clientId,
          documentId: document.id,
          name: document.name,
          newAccountGroup: group,
          newPeriodId: newPeriod.id,
        });
      } catch (e) {
        console.error(e);
      }
    },
    [clientId, group, sdk]
  );

  const renameDocument = useCallback(
    async (document: PeriodDocument, newName: string) => {
      try {
        const newDocument = await sdk.renamePeriodDocument({
          clientid: clientId,
          documentId: document.id,
          periodId: period.id,
          newName,
        });
        setState((value) => ({
          ...value,
          documents: value.documents.map((item) => {
            if (item.id === newDocument.id) {
              return { ...item, ...newDocument };
            }
            return item;
          }),
        }));
      } catch (e) {
        console.error(e);
      }
    },
    [clientId, period.id, sdk, setState]
  );

  const copyDocumentsFromPreviousPeriod = useCallback(async () => {
    if (!previousPeriodFinancialYear || !previousGroupedPeriods) {
      return;
    }

    try {
      const results = await sdk.getPeriodDocuments({
        clientid: clientId,
        account: accounts,
        accountGroups: [group],
        periodId: previousGroupedPeriods.map((p) => p.id),
      });

      if (results.length) {
        const newDocuments = await Promise.all(
          results.map((item) => {
            return sdk.copyPeriodDocument({
              clientid: clientId,
              documentId: item.id,
              name: item.name,
              newAccountGroup: group,
              newPeriodId: period.id,
            });
          })
        );
        setState((currentState) => ({
          ...currentState,
          documents: newDocuments.map((item) => ({
            ...item,
            period,
            references: [],
          })),
        }));
      }
    } catch (e) {
      console.error(e);
    }
  }, [
    previousPeriodFinancialYear,
    previousGroupedPeriods,
    sdk,
    clientId,
    accounts,
    group,
    setState,
    period,
  ]);

  const getPreviousPeriodDocumentsCount =
    useCallback(async (): Promise<number> => {
      if (!previousPeriod) {
        return 0;
      }

      try {
        const docs = await sdk.getPeriodDocuments({
          clientid: clientId,
          account: accounts,
          accountGroups: [group],
          periodId: [previousPeriod.id],
        });

        return docs.length;
      } catch (e) {
        console.error(e);
        return 0;
      }
    }, [previousPeriod, sdk, clientId, accounts, group]);

  return {
    ...state,
    addDocument,
    deleteDocument,
    copyDocument,
    renameDocument,
    copyDocumentsFromPreviousPeriod,
    getPreviousPeriodDocumentsCount,
  };
};

export default useGroupDocuments;

import { createContext } from 'react';
import { RootState } from 'redux/reducers';
import { Observable } from 'rxjs';
import { ClientCompanyType } from '_clients/types/types';
import { CustomersState } from '_clients/redux/customers/reducer';
import { createProvider, ReduxDataSource, DataSource } from './ReduxSource';

class ReduxClientSource extends ReduxDataSource<
  ClientCompanyType,
  CustomersState
> {
  constructor(state: Observable<CustomersState>) {
    super(state, 'Client');
  }

  mapStateToData(
    clientId: string
  ): (state: CustomersState) => ClientCompanyType | undefined {
    return (state) => state[clientId];
  }

  onMissingData() {
    console.warn('No handling of missing client information');
  }

  set() {
    console.warn('ReduxClientSource.set is not implemented');
  }
}

export const ClientSourceContext = createContext<DataSource<ClientCompanyType>>(
  {} as unknown as DataSource<ClientCompanyType>
);

const selectState = (rootState: RootState) => rootState.customers;

export const Provider = createProvider(
  ClientSourceContext,
  selectState,
  (subject) => new ReduxClientSource(subject)
);

import React, { useEffect, useState } from 'react';
import {
  Card,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
} from '@material-ui/core';
import { Delete, Visibility, Edit } from '@material-ui/icons';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { addGlobalErrorMessage } from '_messages/redux/actions';
import { deleteChecklistTemplate } from '_organization/redux/actions';
import { useSelector } from 'redux/reducers';
import { ChecklistTemplate } from '_shared/redux/checklist/types';
import Button from '_shared/components/Buttons/Button';
import ConfirmationDialog from '_shared/components/Dialogs/ConfirmationDialog';
import { GetComponentProps } from '_shared/services/type-helpers';
import useHistory from '_shared/hooks/useHistory';

const Container = styled.div`
  flex-direction: column;
`;

const TopCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px;
  min-width: 1000px;
  min-height: 150px;
  margin-bottom: 24px;
`;

const BottomCard = styled(Card)`
  padding-top: 24px;
  min-width: 1000px;
`;

const Title = styled(Typography)`
  font-weight: 500;
  font-size: 32px;
`;

const SubTitle = styled(Typography)`
  font-size: 24px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px 24px 24px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const StyledHeaderRow = styled(TableHead)`
  background-color: #ededed;
`;

const EmptyRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 81px;
`;

const Checklists = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const checklists = useSelector((state) => state.organisation.checklists);

  const [filteredChecklists, setFilteredChecklists] = useState(checklists);

  const [verifyDeleteDialog, setVerifyDeleteDialog] =
    useState<GetComponentProps<typeof ConfirmationDialog> | null>(null);

  useEffect(() => {
    setFilteredChecklists(checklists.filter((checklist) => !checklist.hidden));
  }, [checklists]);

  const handleShowChecklist = (checklist: ChecklistTemplate) => {
    history.push(`/organisation/checklists/details/${checklist.id}`);
  };

  const handleEditChecklist = (checklist: ChecklistTemplate) => {
    history.push(`/organisation/checklists/edit/${checklist.id}`);
  };

  const handleDeleteChecklist = (checklist: ChecklistTemplate) => {
    try {
      if (checklist.id) {
        dispatch(deleteChecklistTemplate(checklist.id));
      }
    } catch (e) {
      dispatch(addGlobalErrorMessage('error'));
    }
  };

  const onConfirmDeleteChecklist = (checklist: ChecklistTemplate) => {
    setVerifyDeleteDialog({
      acceptButtonColor: 'danger',
      acceptButtonText: formatMessage({
        id: 'checklist.list.dialog.removeButton',
      }),
      closeButtonVariant: 'text',
      onClose: () => setVerifyDeleteDialog(null),
      onAccept: () => {
        handleDeleteChecklist(checklist);
        setVerifyDeleteDialog(null);
      },
      reverseButtonOrder: true,
      text: formatMessage({ id: 'checklist.list.dialog.description' }),
      title: formatMessage({ id: 'checklist.list.dialog.removeButton' }),
      showHeaderCloseButton: true,
    });
  };

  return (
    <Container>
      <TopCard>
        <Title>{formatMessage({ id: 'checklist.list.title' })}</Title>
        <Button
          label={formatMessage({ id: 'checklist.list.createChecklist' })}
          onClick={() => history.push(`/organisation/checklists/create`)}
        />
      </TopCard>
      <BottomCard>
        <Row>
          <SubTitle>
            {formatMessage({ id: 'checklist.list.subtitle' })}
          </SubTitle>
        </Row>
        <Table>
          <StyledHeaderRow>
            <TableRow>
              <TableCell>
                {formatMessage({ id: 'checklist.list.table.name' })}
              </TableCell>
              <TableCell>
                {formatMessage({ id: 'checklist.list.table.type' })}
              </TableCell>
              <TableCell>
                {formatMessage({ id: 'checklist.list.table.model' })}
              </TableCell>
              <TableCell />
            </TableRow>
          </StyledHeaderRow>
          <TableBody>
            {filteredChecklists.map((checklist) => (
              <TableRow key={checklist.id}>
                <TableCell>{checklist.name}</TableCell>
                <TableCell>
                  {formatMessage({ id: `checklist.type.${checklist.type}` })}
                </TableCell>
                <TableCell>
                  {formatMessage({
                    id: `checklist.program.${checklist.program}`,
                  })}
                </TableCell>
                <TableCell>
                  <Buttons>
                    <IconButton onClick={() => handleShowChecklist(checklist)}>
                      <Visibility />
                    </IconButton>
                    <IconButton onClick={() => handleEditChecklist(checklist)}>
                      <Edit />
                    </IconButton>
                    <IconButton
                      onClick={() => onConfirmDeleteChecklist(checklist)}
                    >
                      <Delete />
                    </IconButton>
                  </Buttons>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {!checklists.length && (
          <EmptyRow>
            <Typography color="textSecondary">
              {formatMessage({ id: 'checklist.list.empty' })}
            </Typography>
          </EmptyRow>
        )}
      </BottomCard>
      {verifyDeleteDialog && <ConfirmationDialog {...verifyDeleteDialog} />}
    </Container>
  );
};

export default Checklists;

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Menu, MenuItem, Typography, Avatar } from '@material-ui/core';
import styled from '@emotion/styled';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { useSelector } from 'redux/reducers';
import useAuth from '_authentication/hooks/useAuth';
import { userLogout } from '_users/redux/actions';
import useHistory from '../../../hooks/useHistory';
import Link from '../../Link';

const ProfileWrapper = styled.div<{ textColor?: string }>`
  padding: 0;
  display: flex;
  align-items: center;
  color: inherit;
  cursor: pointer;
`;

const StyledAvatar = styled(Avatar)`
  width: ${(props) => props.theme.spacing(4)}px;
  height: ${(props) => props.theme.spacing(4)}px;
  margin-right: ${(props) => props.theme.spacing(1)}px;
`;

type UserMenuProps = {
  disableAvatar?: boolean;
};

const UserMenu = ({ disableAvatar = false }: UserMenuProps) => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const history = useHistory();

  const userEmail = useSelector((state) => state.user.email);
  const picture = useSelector((state) => state.user.picture);
  const givenName = useSelector((state) => state.user.givenName);
  const familyName = useSelector((state) => state.user.familyName);
  const fullName = useSelector((state) => state.user.fullName);
  const organisationId = useSelector((state) => state.user.organisationId);
  const intl = useIntl();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [expanded, setExpanded] = useState(false);

  const getDisplayName = () => {
    if (fullName) {
      return fullName;
    }

    if (givenName && familyName) {
      return `${givenName} ${familyName}`;
    }

    return userEmail;
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setExpanded(true);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setExpanded(false);
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    handleCloseMenu();
    auth.logOutAWS();
    dispatch(userLogout());
    history.push('/login');
  };

  return (
    <>
      {userEmail && (
        <>
          <ProfileWrapper data-cy="user-menu-btn" onClick={handleOpenMenu}>
            {!disableAvatar && (
              <StyledAvatar variant="circular" src={picture} />
            )}
            <Typography variant="h4">{getDisplayName()}</Typography>
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ProfileWrapper>
        </>
      )}

      <Menu
        anchorEl={anchorEl}
        keepMounted
        onClose={handleCloseMenu}
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {organisationId && (
          <MenuItem
            component={Link}
            to="/organisation/general"
            onClick={handleCloseMenu}
          >
            {intl.formatMessage({ id: 'organisation' })}
          </MenuItem>
        )}

        <MenuItem component={Link} to="/profile" onClick={handleCloseMenu}>
          {intl.formatMessage({ id: 'profile' })}
        </MenuItem>

        <MenuItem data-cy="logout-menu-item" onClick={handleLogOut}>
          {intl.formatMessage({ id: 'logout' })}
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;

import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';
import { Typography, Box, Tooltip, Button } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import styled from '@emotion/styled';
import { useSelector } from 'redux/reducers';
import { ccyFormat } from '@agoy/common';
import { TaxCalculationPart, TaxCalculationRow } from '@agoy/tax-document';
import { isTaxDeclarationModule } from 'utils/TaxDeclaration/tax-declaration-util';
import { currentClient } from '_reconciliation/redux/accounting-view/selectors';
import useModule from '_tax/hooks/useModule';
import When from '_shared/components/When/When';
import useHistory from '_shared/hooks/useHistory';
import EditLabel from '../EditLabel';
import EditValue from '../EditValue';
import Row from '../Row';
import DeleteRowButton from '../DeleteRowButton';
import { getEditingRule } from '../editingRules';
import AccountLabel from '../AccountLabel';
import TaxesDataContext from '../../../service/TaxesDataContext';
import ToolTipComponent from './ToolTipComponent';

interface RowStyle {
  label: {
    bold?: boolean;
    firstBold?: boolean;
  };
  value: {
    bold?: boolean;
  };
}

const allBold = {
  label: {
    bold: true,
  },
  value: {
    bold: true,
  },
};

const style: Record<string, Record<string, RowStyle>> = {
  header: {
    resultBeforeFrom3000to8799: allBold,
    resultBeforeTaxes: allBold,
  },
  yearEndPlanning: {
    resultAfterYearEndPlanningBeforeTaxes: allBold,
  },
  taxCalculation: {
    yearsTaxableResult: allBold,
    companyTax: allBold,
    result: allBold,
    taxChanged: allBold,
  },
  nonTaxableIncomes: {
    nonTaxableIncomesSum: allBold,
  },
  nonDeductibleExpenses: {
    nonDeductibleExpensesSum: allBold,
  },
  particularSalaryTax: {
    particularSalaryTaxSum: {
      label: {
        firstBold: true,
      },
      value: {
        bold: true,
      },
    },
    particularSalaryTaxToBook: allBold,
  },
  accrualFunds: {
    accrualFundsSum: allBold,
  },
};

export interface TaxTableRowProps {
  row: TaxCalculationRow;
  editing: boolean;
  part: TaxCalculationPart | string | 'header';
  config: Record<string, string | boolean>;
  hideEmpty: boolean;
  errorLabel?: string;
  selectAccount?: boolean;
  externalHandleSelectAccounts?: (params: {
    part: string;
    rowId: string;
    label: string;
    reference: string;
  }) => void;
  onDelete?: (rowId: string) => void;
  onChangeLabel?: (rowId: string, label: string) => void;
  onChangeValue?: (rowId: string, value: number) => void;
  minValue?: number;
  maxValue?: number;
  note?: string;
  selectForSummary?: boolean;
  valueError?: boolean;
  updatePensionReference?: boolean;
  updateForeignReference?: boolean;
  tableSelectedAccounts?: string[] | [];
}

const Bold = styled(Typography)`
  font-weight: 700;
`;

const LabelNote = styled(Typography)`
  margin-left: ${(props) => props.theme.spacing(1)}px;
  display: inline-block;
`;

const FirstBoldRest = styled(Typography)`
  margin-left: ${(props) => props.theme.spacing(1)}px;
`;

const StyledLinkButton = styled(Button)`
  margin-left: ${(props) => props.theme.spacing(2)}px;
`;

const EditValueContainer = styled.div`
  max-width: 20ch;
`;

const RowWithToolTip = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const FirstBold = ({ children }) => {
  const [firstWord, ...rest] = `${children}`.split(' ');
  return (
    <>
      <Bold>{firstWord}</Bold>
      {rest && (
        <FirstBoldRest color="textSecondary">{rest.join(' ')}</FirstBoldRest>
      )}
    </>
  );
};

const ToAccrualFundsButton = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { financialYear: currentFinancialYear, period: currentPeriod } =
    useContext(TaxesDataContext);
  const firstAccount = useSelector(
    currentClient((state) =>
      state.years[currentFinancialYear]?.accountingBalances?.accounts.find(
        (account) => account.number <= '2130' && account.number >= '2110'
      )
    )
  );
  const { currentCustomer } = useSelector((state) => state.customerView);

  if (!firstAccount || !currentCustomer || !currentPeriod) {
    return null;
  }

  const navigate = () => {
    history.push(
      `/clients/${currentCustomer}/${currentFinancialYear}/tax/accruals`
    );
  };
  return (
    <Tooltip title={formatMessage({ id: 'tax.linkTo.accrualFunds.tooltip' })}>
      <StyledLinkButton
        onClick={navigate}
        size="small"
        variant="contained"
        color="primary"
        endIcon={<ArrowForwardIcon />}
      >
        {formatMessage({ id: 'tax.linkTo.accrualFunds' })}
      </StyledLinkButton>
    </Tooltip>
  );
};

const getLabelComponent = (part: string, id: string | undefined) => {
  const settings = id ? style[part]?.[id]?.label : null;
  if (settings?.firstBold) {
    return FirstBold;
  }
  if (settings?.bold) {
    return Bold;
  }
  return Typography;
};

const Empty = () => null;

const getValueComponent = (part: string, id: string | undefined) => {
  const settings = id ? style[part]?.[id]?.value : null;
  if (settings?.bold) {
    return Bold;
  }
  if (id === 'maxPossibleDepositionToAccrualFundLabel') {
    return Empty;
  }
  return Typography;
};

const isAccountReferenceRegex = new RegExp('account\\(....\\)');

const editValueInputProps = { variant: 'outlined', size: 'small' };

const getValidRange = (
  minValue: number | undefined,
  maxValue: number | undefined,
  editingRule: string | undefined
): [number | undefined, number | undefined] => {
  return [
    minValue !== undefined ? minValue : editingRule === '+' ? 0 : undefined,
    maxValue !== undefined ? maxValue : editingRule === '-' ? 0 : undefined,
  ];
};

const TaxTableRow = ({
  row,
  part,
  editing,
  hideEmpty,
  errorLabel,
  config,
  onDelete,
  onChangeLabel,
  onChangeValue,
  selectAccount,
  externalHandleSelectAccounts,
  minValue,
  maxValue,
  note,
  selectForSummary,
  valueError,
  tableSelectedAccounts,
}: TaxTableRowProps): JSX.Element | null => {
  const { formatMessage, messages } = useIntl();
  const [label, setLabel] = useState<string>('');
  const moduleContext = useModule();
  const { financialYear, service } = useContext(TaxesDataContext);

  const accountInformation = useSelector(
    currentClient((state) =>
      row.label
        ? state.years[financialYear]?.accountingBalances?.accounts.find(
            (acc) => acc.number === row.label
          )
        : undefined
    )
  );
  useEffect(() => {
    if (!row.hidden) {
      let newLabel = '';
      if (row.label !== undefined) {
        newLabel = row.label;
      } else if (row.labelId || row.id) {
        newLabel = formatMessage(
          { id: `tax.${row.labelId || row.id}.label` },
          config
        );
      }
      if (newLabel !== label) {
        setLabel(newLabel);
      }
    }
  }, [row, label, formatMessage, config, setLabel]);

  const handleChangeLabel = useCallback(
    (newLabel: string) => {
      if (onChangeLabel) {
        onChangeLabel(row.id, newLabel);
      }
    },
    [onChangeLabel, row.id]
  );

  const handleDelete = useCallback(() => {
    if (onDelete) {
      onDelete(row.id);
    }
  }, [onDelete, row.id]);

  const handleChangeValue = useCallback(
    (value: number | undefined) => {
      if (onChangeValue) {
        onChangeValue(row.id, value || 0);
      }
    },
    [onChangeValue, row.id]
  );

  const handleSelectAccounts = (account, name, accountId, close) => {
    if (externalHandleSelectAccounts) {
      externalHandleSelectAccounts({
        part,
        rowId: accountId as string,
        label: account,
        reference: `account(${account})`,
      });

      if (close) {
        close();
      }

      return;
    }

    if (account && part !== 'header' && !selectForSummary) {
      service.updateTaxTableRowReference(
        part,
        accountId,
        account,
        `account(${account})`
      );
    }

    if (close) {
      close();
    }
  };

  // Checking if there is a message in sv.json linked to the row.id to avoid the tooltip to show a plain id
  const tooltipLabel =
    messages[`tax.${row.id}.label.tooltip.title`] ||
    messages[`tax.${row.id}.label.tooltip.body`] ? (
      <>
        {messages[`tax.${row.id}.label.tooltip.title`] && (
          <>
            <b>
              {formatMessage({
                id: `tax.${row.id}.label.tooltip.title`,
              })}
            </b>
            <br />
          </>
        )}
        {messages[`tax.${row.id}.label.tooltip.body`] &&
          formatMessage({
            id: `tax.${row.id}.label.tooltip.body`,
          })}
      </>
    ) : (
      ''
    );

  // Similar check as the one for tooltipLabel but for the value column (if no message, render an empty string, i.e no tooltip)
  const tooltipValue =
    messages[`tax.${row.id}.value.tooltip.title`] ||
    messages[`tax.${row.id}.value.tooltip.body`] ? (
      <>
        {messages[`tax.${row.id}.value.tooltip.title`] && (
          <>
            <b>
              {formatMessage({
                id: `tax.${row.id}.value.tooltip.title`,
              })}
            </b>
            <br />
          </>
        )}
        {messages[`tax.${row.id}.value.tooltip.body`] &&
          formatMessage({
            id: `tax.${row.id}.value.tooltip.body`,
          })}
      </>
    ) : (
      ''
    );

  if (
    row.hidden ||
    row.deleted ||
    (hideEmpty &&
      (row.value === undefined || (row.reference && row.value === 0)))
  ) {
    return null;
  }

  if (row.id === 'maxPossibleDepositionToAccrualFundLabel') {
    return (
      <Row>
        <Tooltip title={tooltipLabel}>
          <Box p={1} paddingLeft={1.5}>
            {errorLabel ? (
              <Typography color="error">{errorLabel}</Typography>
            ) : (
              <Typography>{label}</Typography>
            )}
          </Box>
        </Tooltip>
      </Row>
    );
  }
  const Label = getLabelComponent(part, row.id);
  const Value = getValueComponent(part, row.id);
  const rowReferenceIsAccount = row.reference?.match(isAccountReferenceRegex);
  const settings = style[part]?.[row.id]?.label;

  const editingRule = getEditingRule(row.id);
  const [rowMinValue, rowMaxValue] = getValidRange(
    minValue,
    maxValue,
    editingRule
  );

  if (
    row.hidden ||
    row.deleted ||
    ((hideEmpty || row.hiddenWhenEmpty) &&
      (row.value === undefined || (row.reference && row.value === 0)))
  ) {
    return null;
  }

  return (
    <>
      <Row>
        {errorLabel ? (
          <Tooltip title={tooltipLabel}>
            <Box p={1} paddingRight={1.5}>
              <Typography color="error">{errorLabel}</Typography>
            </Box>
          </Tooltip>
        ) : row.reference || !onChangeLabel || !editing ? (
          <Box display="flex" p={1} paddingLeft={1.5}>
            {!settings && rowReferenceIsAccount ? (
              <AccountLabel
                accountNumber={label}
                accountName={accountInformation?.name}
              />
            ) : (
              <RowWithToolTip>
                <Label>
                  {label}
                  {note && <LabelNote color="textSecondary">{note}</LabelNote>}
                </Label>
                <When isTrue={!!tooltipLabel}>
                  <ToolTipComponent title={tooltipLabel} />
                </When>
              </RowWithToolTip>
            )}
          </Box>
        ) : (
          <EditLabel
            tooltip={
              row.id.startsWith('@')
                ? formatMessage({ id: 'edit.newrow.tooltip' })
                : tooltipLabel
            }
            rowId={row.id}
            value={label}
            onChange={handleChangeLabel}
            onAccountSelected={selectAccount ? handleSelectAccounts : undefined}
            tableSelectedAccounts={tableSelectedAccounts}
          />
        )}

        <Box display="flex" justifyContent="flex-end" flexShrink={0}>
          {row.error || valueError ? (
            <Tooltip title={tooltipValue}>
              <Box p={1} paddingRight={1.5}>
                <Value
                  color={
                    row.error === 'missingAccount' ? 'textPrimary' : 'error'
                  }
                >
                  {formatMessage({
                    id: `ref.error.${row.error || 'missingPeriod'}`,
                  })}
                </Value>
              </Box>
            </Tooltip>
          ) : onChangeValue && editing ? (
            <Tooltip
              title={
                tooltipValue ||
                (editingRule === '+'
                  ? formatMessage({ id: 'edit.positive.tooltip' })
                  : editingRule === '-'
                    ? formatMessage({ id: 'edit.negative.tooltip' })
                    : formatMessage({ id: 'edit.amount.tooltip' }))
              }
              placement="bottom"
            >
              <EditValueContainer>
                <EditValue
                  value={row.value}
                  InputProps={editValueInputProps}
                  onValueChange={handleChangeValue}
                  maxValue={rowMaxValue}
                  minValue={rowMinValue}
                />
              </EditValueContainer>
            </Tooltip>
          ) : (
            <Tooltip title={tooltipValue}>
              <Box display="flex" p={1} paddingRight={1.5}>
                <Value>{ccyFormat(row.value)}</Value>
              </Box>
            </Tooltip>
          )}
          {editing && onDelete && <DeleteRowButton onClick={handleDelete} />}
        </Box>
      </Row>
      {row.id === 'chosenDepositionToAccrualFund' &&
        !isTaxDeclarationModule(moduleContext) && (
          <Row>
            <Box />
            <Box>
              <ToAccrualFundsButton />
            </Box>
          </Row>
        )}
    </>
  );
};

export default TaxTableRow;

// eslint-disable-next-line import/no-cycle
import { UpdateTaxDeclarationFormExternalField } from 'redux/actions';
import { Field, isError, operations } from '@agoy/document';
import {
  TaxDeclarationFormData,
  TaxDeclarationFormStructure,
} from '@agoy/tax-declaration-document';
import TaxDeclarationViewState from '../types';

const validateExternalField = (
  formDefintion: TaxDeclarationFormStructure,
  partId: string,
  id: string
) => {
  const part = formDefintion.children[partId];
  if (part && 'children' in part && part.partType === 'form') {
    return part.children.externalFields?.children[id]?.type === 'field';
  }
  return false;
};

const updateTaxDeclarationFormExternalField = (
  state: TaxDeclarationViewState,
  action: UpdateTaxDeclarationFormExternalField
): TaxDeclarationViewState => {
  if (Object.keys(state.formData).length === 0) {
    return state;
  }

  const selectedFormId = state.viewFormId;
  if (!selectedFormId) return state;

  const formData = state.formData[selectedFormId];
  if (!formData) {
    return state;
  }

  const { partId, id, value } = action;

  // validate that external field is part of definition of form
  if (!validateExternalField(formData.definition, partId, id)) return state;

  const part = formData.document[partId];
  if (typeof part !== 'object' || Array.isArray(part)) {
    return state;
  }
  const currentCell: Field | undefined = part.externalFields?.[id];

  if (!currentCell) return state;

  if (currentCell.type === 'string' && typeof value !== 'string') {
    console.error(
      `Cell ${id} has type string but value is of type ${typeof value}`
    );
    return state;
  }
  if (
    currentCell.type === 'number' &&
    typeof value !== 'number' &&
    typeof value !== 'undefined'
  ) {
    console.error(`Field ${id} has type number but value is ${typeof value}`);
    return state;
  }

  if (currentCell.type === 'boolean' && typeof value !== 'boolean') {
    console.error(`Field ${id} has type boolean but value is ${typeof value}`);
    return state;
  }

  if (currentCell.value === value) {
    return state;
  }

  const result = operations.updateField(
    formData.definition,
    formData,
    `${partId}.externalFields.${id}`,
    value
  );
  if (!result) {
    return state;
  }
  if (isError(result)) {
    console.warn(result);
    return state;
  }

  return {
    ...state,
    formData: {
      ...state.formData,
      [selectedFormId]: {
        ...formData,
        document: result.document as TaxDeclarationFormData,
        changes: result.changes,
      },
    },
  };
};

export default updateTaxDeclarationFormExternalField;

import { value, Field, AgoyTable } from '@agoy/document';
import { table, field } from '../utils/util';
import { AnnualReportPart, AnnualReportSection } from '../../document';

export interface SignaturesSection extends AnnualReportSection {
  isDateForAllMembers: Field;
  title: Field;
  location: Field;
  date: Field;
  showDateInfo: Field;
  people: AgoyTable;
  auditReportDate: Field;
  statement: Field;
  statementPronoun: Field;
  auditFirm: {
    companyName: Field;
    orgNr: Field;
  };
}

// Noter
export interface Signatures extends AnnualReportPart {
  section: SignaturesSection;
}

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };

export const signatures = {
  type: 'part' as const,
  children: {
    active: boolean,
    section: {
      type: 'part' as const,
      children: {
        isDateForAllMembers: cell,
        active: boolean,
        title: cell,
        location: cell,
        date: cell,
        showDateInfo: cell,
        people: { type: 'table' as const },
        auditReportDate: cell,
        statement: cell,
        statementPronoun: cell,
        auditFirm: {
          type: 'part' as const,
          children: {
            companyName: cell,
            orgNr: cell,
          },
        },
      },
    },
  },
};

export const signaturesConfig = (): Signatures => ({
  active: undefined,
  section: {
    active: undefined,
    isDateForAllMembers: value(true),
    title: field('Underskrifter av årsredovisning'),
    location: field(''),
    date: field(''),
    showDateInfo: value(false),
    people: table(
      'section.people',
      'givenName',
      'surName',
      'role',
      'accountantRole',
      'date',
      'email',
      'phoneNumber'
    )
      .newRowTemplate(
        value('$givenName'),
        value('$surName'),
        value('$role'),
        value('$accountantRole'),
        value('$date'),
        value('$email'),
        value('$phoneNumber')
      )
      .build(),
    auditReportDate: field(''),
    statement: field(''),
    statementPronoun: field(''),
    auditFirm: {
      companyName: field(''),
      orgNr: field(''),
    },
  },
});

export const signaturesReferences = (): Record<string, string> => ({});

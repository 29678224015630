import React, { useEffect, useRef, useState } from 'react';
import Chip from '_shared/components/Chip';
import {
  Box,
  ClickAwayListener,
  InputLabel as MuiInputLabel,
  Paper as MuiPaper,
  Popper as MuiPopper,
} from '@material-ui/core';
import styled from '@emotion/styled';

import { AddCircleOutline } from '@material-ui/icons';
import Button from '_shared/components/Buttons/Button';
import AutocompleteMultiSelect from '_shared/components/Inputs/AutocompleteMultiSelect';
import { useIntl } from 'react-intl';
import { getClasses } from '@agoy/common/src/utils/getClasses';

const MoreChipWrapper = styled.div`
  display: inline-flex;
`;

const InputLabel = styled(MuiInputLabel)`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.subtitle1.fontSize};
  font-weight: 400;
  line-height: 1.75;
`;

const Paper = styled(MuiPaper)`
  padding: ${(props) => props.theme.spacing(1, 1)};
`;

const Popper = styled(MuiPopper)`
  height: 314px;
  overflow: auto;
  width: 320px;
  z-index: 9999;
`;

const Wrapper = styled.div`
  margin: ${(props) => props.theme.spacing(1, 0)};
  width: 420px;
`;

interface ChecklistClientSelectProps {
  clientList: ClientSelectValue[];
  clientIds: string[];
  handleAddClients: (value: string[]) => void;
  handleRemoveClients: (value: string[]) => void;
  editing?: boolean;
}

export interface ClientSelectValue {
  value: string;
  label: string;
}

const ChecklistClientSelect = ({
  clientList,
  clientIds,
  handleAddClients,
  handleRemoveClients,
  editing = true,
}: ChecklistClientSelectProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const anchorRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  const [clientIdToName, setClientIdToName] = useState({});

  const [clientOptionValues, setClientOptionValues] = useState<string[]>([]);

  const [moreClientIds, setMoreClientIds] = useState<string[]>([]);

  const [previewClientIds, setPreviewClientIds] = useState<string[]>([]);

  useEffect(() => {
    if (clientList && clientIds) {
      setClientIdToName(
        clientList.reduce((result, client) => {
          result[client.value] = client.label;
          return result;
        }, {})
      );
      setClientOptionValues(clientIds);
      setPreviewClientIds(clientIds.slice(0, 10));
      setMoreClientIds(clientIds.slice(10));
    }
  }, [clientIds, clientList]);

  const removeClient = (clientId: string) => {
    const updatedClientIds = clientIds.filter((id) => id !== clientId);
    handleRemoveClients([clientId]);
    setClientOptionValues(updatedClientIds);
  };

  const removeAllClients = () => {
    handleRemoveClients(clientIds);
    setClientOptionValues([]);
    setIsOpen(false);
  };

  return (
    <div>
      <InputLabel>
        {formatMessage({ id: 'checklist.create.client' })}
      </InputLabel>
      {editing && (
        <AutocompleteMultiSelect
          onValueChange={setClientOptionValues}
          value={clientOptionValues}
          options={clientList}
          label={formatMessage({ id: 'checklist.create.client.label' })}
          startIcon={<AddCircleOutline />}
          displayEndIcon={false}
          handleAddRow={handleAddClients}
          placeholder={formatMessage({
            id: 'checklist.create.client.filter.placeholder',
          })}
          autoCompleteStyle={{ left: '0px' }}
          popperPlacement="bottom-start"
        />
      )}
      <Wrapper>
        {Object.keys(clientIdToName).length > 0 &&
          previewClientIds.map((value) => {
            return (
              <Box pb={1} pr={1} display="inline-flex" key={`preview-${value}`}>
                <Chip
                  label={clientIdToName[value]}
                  onDelete={editing ? () => removeClient(value) : undefined}
                />
              </Box>
            );
          })}
        <Box
          display={getClasses({
            block: moreClientIds.length > 0,
            'inline-block': moreClientIds.length === 0,
          })}
        >
          {Object.keys(clientIdToName).length > 0 &&
            moreClientIds.length > 0 && (
              <MoreChipWrapper ref={anchorRef}>
                <Chip
                  label={formatMessage(
                    {
                      id: 'checklist.create.client.showMore',
                    },
                    {
                      amount: moreClientIds.length,
                    }
                  )}
                  onClick={() => setIsOpen(!isOpen)}
                />
              </MoreChipWrapper>
            )}
          {editing && clientIds.length > 0 && clientList.length > 0 && (
            <Button
              label={formatMessage({
                id: 'checklist.create.client.clearAll',
              })}
              variant="text"
              onClick={removeAllClients}
              size="medium"
            />
          )}
        </Box>
      </Wrapper>
      {moreClientIds.length > 0 && (
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <Popper
            open={isOpen}
            anchorEl={anchorRef.current}
            placement="bottom-start"
            transition
            disablePortal
            modifiers={{
              preventOverflow: {
                enabled: true,
                escapeWithReference: true,
                boundariesElement: 'viewport',
              },
            }}
          >
            <Paper>
              {moreClientIds.map((value) => (
                <Box pb={1} key={`more-${value}`}>
                  <Chip
                    label={clientIdToName[value]}
                    onDelete={editing ? () => removeClient(value) : undefined}
                  />
                </Box>
              ))}
            </Paper>
          </Popper>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default ChecklistClientSelect;

import { useRouteMatch } from 'react-router-dom';
import { FortnoxRouteParams } from '../types';
import config from '../services/config';
import { FORTNOX_ROUTE_PREFIX } from '../../contants';

const useFid = () => {
  const routeMatch = useRouteMatch<FortnoxRouteParams>(FORTNOX_ROUTE_PREFIX);
  const fid = routeMatch?.params.fid;
  if (config.isFortnoxCloud && !fid) {
    throw new Error('No fid present in the URL.');
  }
  return fid;
};

export default useFid;

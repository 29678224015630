import React from 'react';
import { useLocation } from 'react-router-dom';

import config from '_shared/services/config';
import { usePaymentStatus } from '_payment/payment-status/usePaymentStatus';
import useHistory from '_shared/hooks/useHistory';

const isPaymentCheckoutPath = (path: string) => {
  const allowedRoutes = [
    '/payment/unpaid',
    '/payment/checkout',
    '/payment/cancel',
    '/payment/success',
    '/create-organisation',
  ];
  return allowedRoutes.some((allowedRoute) => path.includes(allowedRoute));
};

/**
 * Prevents routes from rendering when the user's organisation has not paid for Agoy.
 *
 * Renders /unpaid page if payment not done. Blocks all other pages (appart from /checkout).
 */
const withPaywall = (Component) => (props) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const { data } = usePaymentStatus();

  React.useEffect(() => {
    // Fortnox users are not affected by the paywall
    if (config.whiteLabel === 'fortnox') return;

    // Redirect into payment routes when the user has not paid
    if (!data.hasAccess && !isPaymentCheckoutPath(pathname)) {
      history.replace('/payment/unpaid');
    }

    // Redirect out of payment routes, when the user has already paid
    if (data.hasAccess && isPaymentCheckoutPath(pathname)) {
      history.replace('/');
    }
  }, [pathname, data, history]);

  return <Component {...props} />;
};

export default withPaywall;

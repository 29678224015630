/* eslint-disable import/no-cycle */
import React, { useMemo, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Err, Ok, Result } from 'ts-results';
import { useRouteMatch } from 'react-router-dom';
import LockOpenIcon from '@material-ui/icons/LockOpen';

import ProgramStatus, {
  programStatusMapSchema,
  IntervalTypeKey,
} from '@agoy/program-status';
import { colors as ThemeColors } from '@agoy/theme';
import { PeriodStatus, Program, Status } from '_shared/types';
import {
  PeriodStatusButton,
  StatusHistory,
} from '_shared/components/PeriodStatusButton';
import LockingModal from '_annual-report/components/AnnualReportView/Parts/BolagsverketSubmission/LockingModal';
import { fetchProgramStatus } from '_shared/redux/actions';
import {
  describeInvalidTransitionError,
  describeRequiredChanges,
  isStatusGeneratorError,
} from '_shared/services/ProgramStatus/errorHandling';
import { addGlobalErrorMessage } from '_messages/redux/actions';
import { PeriodStatuses } from '_tax/types';
import { asResultClass, getApiSdk, isApiErrorType } from 'api-sdk';
import { getContext } from 'utils/AgoyAppClient/contextHolder';
import { useSelector } from 'redux/reducers';
import { error as colorError } from 'theme/colors';
import { parseFormat } from '@agoy/dates';

import MissingClientStateDialog from '../MissingClientStartDateDialog';
import ApproveChangesDialog from './ApproveChangesDialog';
import Button from '../Buttons/Button';

const generateOptions = (
  selectedPeriodStatus: PeriodStatus | null,
  disabledStatuses: Status[],
  hiddenStatuses: Status[],
  tooltips: Tooltips
) => {
  return Object.keys(PeriodStatuses)
    .filter((key) => !hiddenStatuses.includes(key as Status))
    .map((key) => {
      return {
        id: key,
        label: PeriodStatuses[key],
        statusColor: ThemeColors[key],
        selected:
          selectedPeriodStatus === null
            ? key === 'NOT_STARTED'
            : key === selectedPeriodStatus.status,
        disabled: disabledStatuses.includes(key as Status),
        tooltip: tooltips[key],
        createdAt:
          selectedPeriodStatus === null
            ? ''
            : parseFormat(selectedPeriodStatus.createdAt, 'yyyy-MM-dd HH:mm'),
        createdBy:
          selectedPeriodStatus === null ? '' : selectedPeriodStatus.createdBy,
      };
    });
};

const generateHistory = (history?: PeriodStatus[]): StatusHistory[] => {
  if (!history) return [];

  return history.map((periodStatus) => {
    return {
      id: `${periodStatus.status}-${periodStatus.createdAt}`,
      label: PeriodStatuses[periodStatus.status],
      statusColor: periodStatus.reason
        ? colorError
        : ThemeColors[periodStatus.status],
      createdAt: parseFormat(periodStatus.createdAt, 'yyyy-MM-dd HH:mm'),
      createdBy: periodStatus.createdBy,
      reason: periodStatus.reason || '',
    };
  });
};

type StatusSelectorProps = {
  program: Program;
  financialYear?: string;
  period?: string | null;
  selectedStatus: PeriodStatus | null;
  disabledStatuses: Status[];
  hiddenStatuses?: Status[];
  onOptionSelected?: (optionId) => void;
  icon?: JSX.Element;
  statusHistory?: PeriodStatus[];
  tooltips?: Tooltips;
};

export type Tooltips = {
  [key in Status]?: string;
};

const createProgramStatus = (
  program: Program,
  intervalType: IntervalTypeKey,
  interval: string,
  status: string
): ReturnType<(typeof ProgramStatus)['createProgramStatus']> => {
  if (programStatusMapSchema[program]?.[intervalType]?.[status]) {
    return ProgramStatus.createProgramStatus({
      program: program as any,
      intervalType: intervalType as any,
      interval,
      status: status as any,
    });
  }
  console.error('Invalid program status', {
    program,
    intervalType,
    interval,
    status,
  });
  throw new Error('Invalid program status');
};

const getDefaultErrorId = (program: string) => {
  switch (program) {
    case 'AN_REPORT':
      return 'annualReport.status.update.error';
    default:
      return 'error';
  }
};

export const setProgramStatus = async (
  clientId: string,
  program: Program,
  intervalType: IntervalTypeKey,
  interval: string,
  status: string,
  formatMessage: IntlShape['formatMessage'],
  approved?: boolean,
  reason?: string
): Promise<
  Result<
    ReturnType<typeof ProgramStatus.createProgramStatus>,
    string | ReturnType<typeof describeRequiredChanges>
  >
> => {
  const data = createProgramStatus(program, intervalType, interval, status);

  const result = await asResultClass(
    getApiSdk(getContext()).postProgramStatuses({
      requestBody: { ...data, clientId, approved, reason } as any, // The data SHOULD be valid as it comes from createProgramStatus
    })
  );
  if (result.ok) {
    return Ok(data);
  }

  if (isApiErrorType(result.val) && isStatusGeneratorError(result.val.body)) {
    const { code } = result.val.body;
    switch (code) {
      case 'INVALID_TRANSITIONS':
        return Err(
          describeInvalidTransitionError(
            result.val.body,
            data,
            formatMessage,
            getDefaultErrorId(program)
          )
        );
      case 'REQUIRE_APPROVAL':
        return Err(
          describeRequiredChanges(result.val.body, data, formatMessage)
        );
      case 'MISSING_CLIENT_START_DATE':
        return Err(code);
      default:
        break;
    }
  }
  return Err(formatMessage({ id: getDefaultErrorId(program) }));
};

const StatusSelector = ({
  program,
  financialYear,
  period,
  selectedStatus = null,
  disabledStatuses = [],
  hiddenStatuses = ['LOCKED' as Status],
  onOptionSelected,
  icon,
  statusHistory,
  tooltips = {},
}: StatusSelectorProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { path } = useRouteMatch();

  const [approve, setApprove] = useState<{
    changes: ReturnType<typeof describeRequiredChanges>;
    status: string;
  } | null>(null);
  const [error, setError] = useState<string | null>(null);

  const { currentCustomer = '', currentYear } = useSelector(
    (state) => state.customerView
  );
  const customer = useSelector((state) => state.customers[currentCustomer]);

  const inPersonView = path.includes('persons');

  if (!financialYear && !period) {
    throw new Error('Must be either financialYear or period');
  }

  const handleOptionSelected = async (optionId: string, approved: boolean) => {
    if (currentCustomer && currentYear && period !== null) {
      // financialYear is usually undefined
      // except when setting status in Skatt module
      const interval = period || financialYear || '';

      const result = await setProgramStatus(
        currentCustomer,
        program,
        financialYear ? 'financialYear' : 'period',
        interval,
        optionId,
        formatMessage,
        approved
      );
      if (result.err) {
        if (typeof result.val === 'string') {
          switch (result.val) {
            case 'MISSING_CLIENT_START_DATE':
              setError(result.val);
              break;
            default:
              dispatch(addGlobalErrorMessage(undefined, result.val));
          }
        } else {
          setApprove({
            changes: result.val,
            status: optionId,
          });
        }
      } else {
        onOptionSelected?.(optionId);
        dispatch(fetchProgramStatus(currentCustomer, program, inPersonView));
      }
    }
  };

  const [lockDialogOpen, setLockDialogOpen] = useState<boolean>(false);
  const periodOptions = useMemo(() => {
    return generateOptions(
      selectedStatus,
      disabledStatuses,
      hiddenStatuses,
      tooltips
    );
  }, [selectedStatus, disabledStatuses, hiddenStatuses, tooltips]);

  const history = useMemo(() => {
    return generateHistory(statusHistory);
  }, [statusHistory]);

  const onApproved = () => {
    if (approve) {
      const { status } = approve;
      setApprove(null);
      handleOptionSelected(status, true);
    }
  };
  return (
    <>
      {selectedStatus?.status !== 'LOCKED' ? (
        <PeriodStatusButton
          options={periodOptions}
          onOptionSelected={(option) => handleOptionSelected(option, false)}
          icon={icon}
          statusHistory={history}
        />
      ) : (
        <Button
          label={formatMessage({ id: 'unlocking.lock' })}
          startIcon={<LockOpenIcon />}
          variant="outlined"
          size="medium"
          onClick={() => setLockDialogOpen(true)}
        />
      )}
      {!inPersonView && lockDialogOpen && program === 'AN_REPORT' && (
        <LockingModal
          isOpen={lockDialogOpen}
          onClose={() => setLockDialogOpen(false)}
          customerName={customer.name}
        />
      )}
      {error === 'MISSING_CLIENT_START_DATE' && (
        <MissingClientStateDialog onClose={() => setError(null)} />
      )}
      {approve && (
        <ApproveChangesDialog
          changes={approve.changes}
          onCancel={() => {
            setApprove(null);
          }}
          onApproved={onApproved}
        />
      )}
    </>
  );
};

export default StatusSelector;

import React, { useEffect, useMemo, useState } from 'react';
import { Box, IconButton, TableCell } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import CommonTable from '_shared/components/CommonTable/Table';
import { AgoyTableRow } from '@agoy/document';
import { ccyFormat } from '@agoy/common';
import ConfirmationDialog from '_shared/components/Dialogs/ConfirmationDialog';
import { GetComponentProps } from '_shared/services/type-helpers';
import { useDispatch } from 'react-redux';
import { useSelector } from 'redux/reducers';
import LoadingLogo from '_shared/components/LoadingLogo';
import { CompanyConnection } from '_client-connections/types';
import {
  deleteConnectionToCompany,
  getClientConnectionsToCompanies,
} from '_client-connections/redux/persons/actions';
import Button from '_shared/components/Buttons/Button';
import useHistory from '_shared/hooks/useHistory';
import ConnectionsTabWrapper, {
  ConnectionTabProps,
} from './ConnectionsTabWrapper';

const Table = styled(CommonTable)`
  .selectable:hover {
    background-color: #f2f4f3;
  }
`;

export const StyledTableCell = styled(TableCell)`
  background-color: inherit;
  text-align: right;
  padding-top: ${({ theme }) => theme.spacing(0.25)}px;
  padding-bottom: ${({ theme }) => theme.spacing(0.25)}px;

  .MuiIconButton-root {
    padding: ${({ theme }) => theme.spacing(1.5)}px;
  }

  &.left {
    text-align: left;
  }

  .caption {
    margin-top: 0.1rem;
    font-size: 0.7rem;
  }
`;

export const getCompanyTypeOptions = (intl): { [key: string]: string } => ({
  shares: intl.formatMessage({ id: 'company.shares' }),
  limited: intl.formatMessage({ id: 'company.limited' }),
  limited_partnership: intl.formatMessage({
    id: 'company.limited.partnership',
  }),
  individual: intl.formatMessage({ id: 'company.individual' }),
  economic_association: intl.formatMessage({
    id: 'company.economic.association',
  }),
  non_profit_association: intl.formatMessage({
    id: 'company.non.profit.association',
  }),
  foundation: intl.formatMessage({ id: 'company.foundation' }),
  other: intl.formatMessage({ id: 'company.other' }),
});

const ConnectionsToCompanyView = ({
  client,
  path,
}: ConnectionTabProps): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const { formatMessage } = intl;

  const isLoading = useSelector((state) => state.ui.updatingConnections);

  const [verifyDeleteConnection, setVerifyDeleteConnection] =
    useState<GetComponentProps<typeof ConfirmationDialog> | null>(null);

  const columns = useMemo(() => {
    const getColumnHeaderLabel = (columnName: string): string =>
      formatMessage({ id: `connections.table.${columnName}` });

    return [
      {
        id: 'orgNameAndOrgNumber',
        label: getColumnHeaderLabel('companyNameAndOrgNumber'),
      },
      { id: 'orgType', label: getColumnHeaderLabel('companyType') },
      { id: 'totalShares', label: getColumnHeaderLabel('totalShares') },
      { id: 'ownedShares', label: getColumnHeaderLabel('shares') },
      { id: 'sharePercentage', label: getColumnHeaderLabel('sharesPercent') },
      { id: 'actions', label: '' },
    ];
  }, [formatMessage]);

  const { id: personId = '', connections = [] } = client;
  const companyTypesLabels = getCompanyTypeOptions(intl);

  const getColumnValue = (connection: CompanyConnection, column) => {
    if (column === 'sharePercentage') {
      return connection.sharePercentage !== undefined
        ? `${ccyFormat(connection.sharePercentage, 2)} %`
        : '';
    }

    if (column === 'totalShares') {
      return connection.stockTotals?.[0]?.shareTotal;
    }

    if (column === 'orgType') {
      return companyTypesLabels[connection.orgType];
    }

    return connection[column] || '';
  };

  const rows: AgoyTableRow[] = useMemo(
    () =>
      connections.map((item) => {
        const cells = {};

        columns.forEach((col) => {
          cells[col.id] = {
            type: 'string',
            value: '',
          };

          cells[col.id].value = getColumnValue(item, col.id);
          cells[col.id].type = ['ownedShares', 'totalShares'].includes(col.id)
            ? 'number'
            : 'string';
        });

        return {
          id: `${item.relationId}`,
          active: true,
          cells,
        };
      }),
    [columns, companyTypesLabels, connections]
  );

  useEffect(() => {
    if (!client?.connections && client.id) {
      dispatch(
        getClientConnectionsToCompanies(
          client.id,
          path === 'clients' ? 'C' : 'P'
        )
      );
    }
  }, [client.connections, client.id, dispatch]);

  const deleteConnection = (connectionId: number) => {
    setVerifyDeleteConnection({
      text: formatMessage(
        { id: 'connections.deleteConfirmation' },
        {
          companyName: connections.find(
            (connection) => connection.relationId === connectionId
          )?.orgName,
        }
      ),
      onAccept: () =>
        dispatch(
          deleteConnectionToCompany(
            personId,
            connectionId,
            path === 'clients' ? 'C' : 'P'
          )
        ),
      onClose: () => setVerifyDeleteConnection(null),
    });
  };

  return (
    <ConnectionsTabWrapper
      client={client}
      title={formatMessage(
        { id: 'connections.shares.title' },
        { name: client.name }
      )}
      description=""
    >
      {isLoading ? (
        <LoadingLogo size="medium" />
      ) : (
        <>
          <Box mt={4}>
            <Button
              label={formatMessage({
                id: 'connections.shares.addConnection',
              })}
              color="primary"
              onClick={() => {
                history.push(`/${path}/${client.id}/connections/create`);
              }}
            />
          </Box>
          <Box mt={2}>
            <Table
              baseId="personsConnections"
              tableId="connectionsToCompanies"
              columns={columns}
              rows={rows}
              onRowSelect={(rowId) => {
                history.push(`/${path}/${client.id}/connections/${rowId}`);
              }}
              renderCell={({ column, row }) => {
                if (column.id === 'orgNameAndOrgNumber') {
                  const relationId = Number.parseInt(row.id);
                  const connection = connections.find(
                    (relation) => relation.relationId === relationId
                  );

                  return (
                    <StyledTableCell
                      data-is-column-dragging={false}
                      key={`${row.id}.${column.id}`}
                      className="left"
                    >
                      <div>{connection?.orgName}</div>
                      <div className="caption">{connection?.orgNumber}</div>
                    </StyledTableCell>
                  );
                }
                if (column.id === 'actions') {
                  return (
                    <StyledTableCell
                      data-is-column-dragging={false}
                      key={`${row.id}.${column.id}`}
                    >
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteConnection(Number.parseInt(row.id, 10));
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </StyledTableCell>
                  );
                }

                return null;
              }}
            />
          </Box>
        </>
      )}

      {verifyDeleteConnection && (
        <ConfirmationDialog {...verifyDeleteConnection} />
      )}
    </ConnectionsTabWrapper>
  );
};

export default ConnectionsToCompanyView;

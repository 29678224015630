export const filterRecord = <T, U extends T>(
  record: Record<string, T>,
  filter: (value: T) => value is U
): Record<string, U> => {
  return Object.entries(record)
    .filter((entry): entry is [string, U] => filter(entry[1]))
    .reduce((result, [key, value]) => {
      result[key] = value;
      return result;
    }, {});
};

export const mapRecord = <T, U>(
  record: Record<string, T>,
  mapper: (value: T) => U
): Record<string, U> => {
  return Object.entries(record).reduce((result, entry) => {
    const [key, value] = entry;

    result[key] = mapper(value);
    return result;
  }, {});
};
